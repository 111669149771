<template>
  <div class="faq-section-n">
    <h4>مشاكل بخصوص الدراسة</h4>
    <div class="faq-items-n">
      <div class="faq-items-sub">
        <div class="faq-item-min" v-for="(item, index) in faqItems_1" :key="index">
          <button @click="toggleAnswer(index)" :class="{ active: activeIndex === index }">
            {{ item.question }}
            <span :class="{ activeSpan: activeIndex === index }">
            <span v-if="activeIndex === index">-</span>
            <span v-else>+</span>
          </span>
          </button>
          <transition name="fade">
            <div v-show="activeIndex === index" :class="['answer', { 'answer-active': activeIndex === index }]">
              {{ item.answer }}
            </div>
          </transition>
        </div>
      </div>
      <div class="faq-items-sub">
        <div class="faq-item-min" v-for="(item, index) in faqItems_2" :key="index + 3">
          <button @click="toggleAnswer(index + 6)" :class="{ active: activeIndex === index + 6 }">
            {{ item.question }}
            <span :class="{ activeSpan: activeIndex === index + 6 }">
            <span v-if="activeIndex === index + 6">-</span>
            <span v-else>+</span>
          </span>
          </button>
          <transition name="fade">
            <div v-show="activeIndex === index + 6" :class="['answer', { 'answer-active': activeIndex === index + 6 }]">
              {{ item.answer }}
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewThreePartOne',
  data() {
    return {
      activeIndex: null,
      faqItems_1: [
        {
          question: 'هل مواعيد الدراسة مناسبة لجدولي الزمني ؟',
          answer: 'تحرص الأكاديمية على تقديم خيارات مرنة ومتعددة للمواعيد تناسب جميع الطلاب قدر الإمكان'
        },
        {
          question: 'لا أحب دراسة اللغة الإنجليزية ؟',
          answer: 'تقوم فلسفة منهج المعايشة على معالجة الملل والصعوبة لمتعلمي اللغات الأجنبية عبر تناغم فريد بين الأندية الترفيهية والأكاديمية والتربوية مما يؤدي لتعلق الطالب باللغة وتعلمها بكل سهولة ويسر ( نادي المحادثة - الصوتيات - المواهب - المناظرات - السينما - القراءة - ماما مونجي - Life Coach - الاجتماعي - الرسم - الكتابة ) '
        },
        {
          question: 'من هم المعلمون، وهل هم معتمدون، وماهي خبراتهم؟',
          answer: 'مجموعة من المتخصصين المؤهلين والمعتمدين في مجال التعليم. يتمتعون بخبرات قوية في تدريس اللغة الإنجليزية، وقد تم اختيارهم بعناية لضمان تقديم أفضل تجربة تعليمية للطلاب. كل معلم ملتزم بمعايير الجودة والكفاءة التي تضعها الأكاديمية، مما يضمن أعلى مستوى من التعليم والدعم لكل طالب.'
        },
      ],
      faqItems_2: [
        {
          question: 'كيف يتم تحفيز الطلاب ؟',
          answer: 'تتبنى مونجلش نظام مكافآت ومسابقاتدورية ( نظام نقاطي - Mongi Points ) وأسلوب تعليمي محفز للطلاب استثنائي وفريد من نوعه مما يعزز من روح التنافس بين الطلاب. يتم تكريم الطلاب الفائزين والمتميزين بشهادة خاصة Star of the Month.'
        },
        {
          question: 'كيف يتم التعامل مع ذوي الفروق الفردية ؟',
          answer: 'لدى مونجلش مجموعة متميزة من الخبراء التربويين المتخصصين في الاستشارات النفسية وتعديل السلوك والاستشارات الأسرية وذلك من خلال إجراء مقابلة مجانية فور الاشتراك في إحدى دورات مونجلش لتحديد سمات الشخصية ومساعدتهم على تحقيق نتائج دراسية أفضل'
        },
        {
          question: 'هل يمكنني التواصل مع المعلم بشكل مباشر ؟',
          answer: 'تحرص الأكاديمية على عقد اجتماع شهري مباشر مع أولياء الأمور  الطلاب ومشرفي الفصول ومديري المراحل التعليمية كما نوفر متابعة يومية لولي الأمر بعد كل حصة لمتابعة مستوى الطالب أول بأول والرد على الاستفسارات والمقترحات.'
        }
      ]
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style src="@/assets/styles/ar/ViewThree.css" scoped></style>
<style scoped>
.faq-section-n h4{
  text-align: start;
  color: black;
  padding-inline:30px;
  font-size:34px;
  margin-bottom: 20px;
}
</style>