
<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
             <p>لقاء حصري ومميز مع د. محمد شلبي، المؤسس والرئيس التنفيذي لمجموعة إيديوليكسا 360 العالمية، وأكاديمية Monglish الدولية.</p>

    <p>تم بث اللقاء المباشر مع الإعلامية نشوى مصطفى في برنامج “بنت البلد” على قناة TEN، حيث ناقش د. شلبي:</p>

    <ul>
        <li>رؤيته القيادية لمستقبل التعليم الإلكتروني باستخدام تقنيات مبتكرة ومنهجية المعايشة الفريدة.</li>
        <li>تفاصيل النجاح الهائل الذي حققته “منهجية المعايشة” في تعليم الأطفال.</li>
        <li>دمج الذكاء الاصطناعي في الاستراتيجية التعليمية لمواكبة التطور التكنولوجي في العالم.</li>
        <li>النظام التعليمي المتكامل في مؤسسة إيديوليكسا 360.</li>
        <li>الأسئلة والمناقشات حول تطوير التعليم ومستقبل المؤسسة.</li>
    </ul>

<iframe width="100%" height="450" src="https://www.youtube.com/embed/HHr4k09bcxU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <p>اللقاء تضمن أيضًا تسليط الضوء على الإنجازات البارزة لمجموعة إيديوليكسا 360 وأكاديمية Monglish الدولية، وكيف تم تطبيق منهجية المعايشة لتحقيق نتائج تعليمية متميزة. وقد تناول د. شلبي أهمية التعليم التفاعلي ودور التكنولوجيا الحديثة في تحسين تجارب التعلم، مما يعزز من استعداد الطلاب لمواجهة تحديات المستقبل.</p>

    <p>كما ناقش د. شلبي كيفية تكامل الذكاء الاصطناعي في البيئة التعليمية لضمان تفاعل أفضل بين الطلاب والمحتوى التعليمي، وتوفير تجربة تعليمية شخصية تلبي احتياجات كل طالب على حدة. وأكد على أهمية الاستمرار في الابتكار والتطوير لتحقيق تعليم مستدام وشامل.</p>

    <p>كان اللقاء فرصة قيمة للإجابة على تساؤلات المشاهدين حول مستقبل التعليم ورؤية المؤسسة في تقديم حلول تعليمية مبتكرة، تسهم في إعداد جيل جديد من المتعلمين يمتلكون المهارات والمعرفة اللازمة للنجاح في عالم سريع التغير.</p>

    <p>تم التطرق أيضًا إلى المشاريع المستقبلية التي تسعى مجموعة إيديوليكسا 360 وأكاديمية Monglish الدولية إلى تنفيذها، حيث أشار د. شلبي إلى خطط التوسع في مجالات جديدة وتقديم برامج تعليمية متقدمة تعتمد على أحدث التقنيات والتوجهات العالمية في التعليم.</p>

    <p>ومن بين الموضوعات التي نوقشت أيضًا، كان هناك تركيز على أهمية الشراكات الدولية والتعاون مع المؤسسات التعليمية الرائدة على مستوى العالم لتبادل الخبرات والمعرفة، بهدف تحسين جودة التعليم وتقديم تجارب تعليمية متنوعة وملهمة للطلاب.</p>

    <p>كما شدد المؤسس والرئيس التنفيذي على دور المعلمين والمربين في تحقيق رؤية المؤسسة، وأهمية توفير التدريب المستمر لهم لضمان مواكبتهم لأحدث الأساليب والوسائل التعليمية. وأكد أن الاستثمار في تطوير فريق العمل هو أحد الركائز الأساسية لتحقيق النجاح والتميز في التعليم.</p>

    <p>في ختام اللقاء، دعا د. شلبي الجميع إلى متابعة جهود المؤسسة والمساهمة في دعم التعليم الإلكتروني وتطويره، معبرًا عن تفاؤله بمستقبل التعليم وقدرته على إحداث تغيير إيجابي في حياة الأجيال القادمة.</p>

            </div>

      </div>
      <div class="cards-column">
        <BlogCard
          v-for="blog in blogCards"
          :key="blog.id"
          :post="blog"
        />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard
        v-for="blog in blogCards"
        :key="blog.id"
        :post="blog"
      />
    </div>
  </div>
  </template>
  
  <script>
  import BlogHead from '@/components/ar/BlogHead.vue';
  import BlogCard from '@/components/en/BlogCard.vue';
  
  export default {
    name: "BlogTwo",
    components: {
      BlogHead,
      BlogCard
    },
    
    data() {
      return {
        title:"لقاء د. محمد شلبي برنامج بنت البلد",
        date: "06/09/2024",
        img: require('@/assets/images/blogs/ar-2.png'),
        category: "فعاليات",
        blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: require('@/assets/images/blogs/ar-1.png'),
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: require('@/assets/images/blogs/ar-2.png'),
          link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 5,
          title: 'كيف اتعلم اللغة الانجليزية',
          image: require('@/assets/images/blogs/ar-3.jpg'),
          link: '/ar/blog/learning-english-in-early-ages/',
          Date: '29 مايو ,2024',
          language: 'ُقافة اللغة الانجليزية',
        },
        {
          id: 6,
          title: 'انجاز جديد فى مشوار دكتور النجار',
          image: require('@/assets/images/blogs/ar-4.png'),
          link: '/ar/blog/new-achievement-in-dr-najjars-career',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
        {
          id: 3,
          title: 'الاحتفال الشهرى بالموظف المثالى',
          image: require('@/assets/images/blogs/ar-5.png'),
          link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
      ]
      }
    }
  }
  </script>
  
<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; 
  padding: 30px; 
}
.main-content {
  display: flex;
  flex-direction: row; 
  gap: 50px; 
  direction: rtl;
  padding: 5% 15% 0 15%;
}
.content-column {
  padding: 30px;
  border-radius: 12px; 
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.blog-cards-row {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%; 
  box-sizing: border-box; 
}
h1 {
  color: #808080; 
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  text-align: right;
  margin-bottom: 20px; 
  padding-left: 16%;
}
.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-view h2{
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
 }
.blog-view p {
  margin-bottom: 18px; 
  font-size: 18px;
}
.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}
.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}
.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px; 
  margin: 20px 0;
}
.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px; 
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
  .content-column{
    padding: 20px; 
  }
  .cards-column {
   display: none;
  }
  h1 {
    font-size: 28px;
  }
  .blog-view h3 {
    font-size: 24px;
  }
  .blog-view h4 {
    font-size: 20px;
  }
}
</style>