<template>
  <BlogHead :title="title" :img="img" :date="date"/>
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
          <div class="blog-view">
            <div v-for="post in blogPosts" :key="post.id" class="blog-post">
              <h3 class="blog-post-title" v-html="post.title"></h3>
              <div class="blog-post-body" v-html="post.content"></div>
              <div v-if="post.img">
                <img :src="post.img" alt='blogone' class='blog-post-img'/>
              </div>
            </div>
          </div>
      </div>
      <div class="cards-column">
        <BlogCard
          v-for="blog in blogCards"
          :key="blog.id"
          :post="blog"
        />
      </div>
    </div>
    <div class="blog-cards-row">
        <BlogCard
          v-for="blog in blogCards"
          :key="blog.id"
          :post="blog"
        />
      </div>
  </div>
</template>
  
  <script>
  import BlogHead from '@/components/en/BlogHead.vue';
  import BlogCard from '@/components/en/BlogCard.vue';

  export default {
    name: "BlogFive",
    components: {
      BlogHead,
      BlogCard
    },
    data() {
      return {
        title:"Activities For Positive Behavior",
        date: "29/02/2024",
        img: require('@/assets/images/blogs/blog5.png'),
        blogPosts: [
           {
            id: 1,
            title: "",
            content: "<p>Playing is an essential part of children’s lives, helping them to grow, learn, and develop their social and emotional skills. Therefore, it is important to choose games and activities that promote positive behavior and interaction among children.</p>",
          },
          {
            id: 2,
            title: "Here are some games and activities that can help promote positive behavior and interaction among children:",
            content: "<ol><li>Cooperative games: Such as building a fort or playing soccer, where children need to work together to achieve a common goal.</li><li>Problem-solving games: Such as puzzles or riddles, where children need to think, be creative, and collaborate to solve a problem.</li><li>Imitation games: Such as mimicking animals or cartoon characters, which require children to pay attention, imitate, and engage in social interaction.</li><li>Imaginative play: Such as playing with dolls or toy cars, where children use their imagination, creativity, and social interaction.</li><li>Artistic activities: Such as drawing, coloring, music, and dancing, which require children to express themselves, be creative, and engage in social interaction.</li></ol><p>It is important for parents to choose games and activities that suit their children’s age and interests. Parents should also ensure to interact with their children during playtime or activities to enhance social interaction and communication between parents and children.</p>",
          },
          {
          id: 3,
          title: "In addition to the mentioned games and activities, there are many other ways parents can promote positive behavior and interaction among their children. For example, parents can:",
          content: "<ul><li>Provide a safe and fun space for children to play.</li><li>Play with their children regularly.</li><li>Praise positive behavior.</li><li>Set clear boundaries and enforce them consistently.</li></ul>",
          },
          {
            id: 4,
            title: "",
            content: "<p>By providing a supportive and positive environment, parents can promote positive behavior and interaction among their children, helping them to grow and develop properly.</p><p><strong>I hope you found the article enjoyable! Feel free to share this article with friends and family. Until next time!</strong></p>",
          },

        ],
        blogCards: [
        {
            id: 1,
            title: 'Learning English in Early Ages',
            image: require('@/assets/images/blogs/blogone.jpg'),
            link: '/en/blog/learning-english-in-early-ages',
          },
          {
            id: 2,
            title:"Celebrating Children’s Language Progress.",
            image: require('@/assets/images/blogs/blog-2.png'),
            link: '/en/blog/celebrating-childrens-language-progress',
          },
          {
          id: 3,
          title: 'Seasons of The Year',
          image: require('@/assets/images/blogs/blog3.png'),
          link: '/en/blog/seasons-of-the-year',
        },
        {
          id: 4,
          title: 'Parents and Children Healthy Relationships',
          image: require('@/assets/images/blogs/blog4.png'),
          link: '/en/blog/parents-and-children-healthy-relationships',
        },
          {
            id: 5,
            title: 'Activities For Positive Behavior',
            image: require('@/assets/images/blogs/blog5.png'),
            link: '/en/blog/activities-for-positive-behavior',
          },
          {
            id: 6,
            title: 'Benefits Of Bilingual For Children',
            image: require('@/assets/images/blogs/blog6.jpg'),
            link: '/en/blog/benefits-of-bilingual-for-children',
          },
        ],
      };
    },

  };
  </script>
 <style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; 
  padding: 30px; 
}
.main-content {
  display: flex;
  flex-direction: row; 
  gap: 50px; 
  direction: ltr;
  padding: 5% 15% 0 15%;
}
.content-column {
  padding: 30px;
  border-radius: 12px; 
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.blog-cards-row {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%; 
  box-sizing: border-box; 
}
h1 {
  color: #808080; 
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px; 
  padding-left: 16%;
}
.blog-view {
  margin-left: 2.5%;
  text-align: left;
  color: #535353; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-view h2{
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
 }
.blog-view p {
  margin-bottom: 18px; 
  font-size: 18px;
}
.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}
.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}
.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px; 
  margin: 20px 0;
}
.strong-paragraph {
  font-weight: 700;
  text-align: left;
  color: #1a1a1a;
  font-size: 20px; 
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
  .content-column{
    padding: 20px; 
  }
  .cards-column {
   display: none;
  }
  h1 {
    font-size: 28px;
  }
  .blog-view h3 {
    font-size: 24px;
  }
  .blog-view h4 {
    font-size: 20px;
  }
}
</style>
