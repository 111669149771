<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>تعلم الساعة بالانجليزية هى مهارة مهمة للأطفال، حيث ستساعدهم على فهم الوقت بشكل أفضل وتنظيم مهامهم اليومية. في هذا المقال، سنقدم لك بعض الطرق لتعليم الأطفال الساعة بالانجليزية بطريقة ممتعة وتفاعلية.<br><br></p>
          
          <p><b>اولا تخيل:</b></p>

          <p>طفلك سعيد يخبرك بالوقت باللغة الانجليزية، صوته يرن بالثقة والفخر.</p>

          <p>نظراته تلمع عندما يقرأ الساعة على جدار الحديقة، أو يتابع الوقت على ساعته الرقمية الصغيرة.</p>

          <p>هو يدرك أهمية الوقت، ويبدأ بتنظيم حياته اليومية بطريقة رائعة.</p>

          <p>كل هذا ممكن! تعليم الساعة بالانجليزية للأطفال هو رحلة ممتعة عبر الزمن، مليئة بالمرح والاكتشافات.</p>

          <p><b>الرحلة تبدأ هنا:</b></p>

          <h2><b>الطريقة الأولى: تعرف على الأبطال</b></h2>

          <ul>
            <li aria-level="1">القرص: هو البطل الرئيسي، دائري الشكل، يحتضن الأرقام والعقارب.</li>
            <li aria-level="1">الأرقام: أصدقاء القرص المرحون، من 1 إلى 12، يبتسمون حول القرص.</li>
            <li aria-level="1">العقارب: الأخوين الديناميكيين، العقرب الكبير (الساعة) والعقرب الصغير (الدقيقة).</li>
          </ul>

          <p>يمكنك استخدام لعبة أو نشاط تعليمي ممتع لمساعدة الأطفال على حفظ هذه الأجزاء.</p>

          <img src="@/assets/images/blogs/blog10-1.jpg">

          <h2><b>الطريقة الثانية: أغاني تجعلك تتحرك</b></h2>

          <p>استخدم أغاني الأطفال والمقطوعات الموسيقية لمساعدة الأطفال على تعلم الكلمات والمصطلحات المتعلقة بالساعة. هناك العديد من الأغاني والمقطوعات الموسيقية الرائعة المتاحة على الإنترنت وعبر منصات التواصل الاجتماعي.</p>

          <p>“Five little monkeys jumping on the bed”: مع كل قفزة، يتقدم العقرب الصغير دقيقة واحدة.</p>

          <p>“Hickory Dickory Dock”: كم دقيقة حتى يضرب الجرس؟ لعبة ممتعة لتدريب مهارة تقدير الوقت.</p>

          <p>“If You’re Happy and You Know It”: صفق عند كل رقم، ارفع يدك عند كل ساعة.</p>

          <img src="@/assets/images/blogs/blog10-2.jpg">

          <h2>الطريقة الثالثة: أنشطة مليئة بالحيوية</h2>

          <p>
            <span style="font-size: 1rem;">استخدم الألعاب والنشاطات التعليمية لمساعدة الأطفال على ممارسة قراءة الساعة. هناك العديد من الألعاب والنشاطات التعليمية المتاحة في المكتبات والمتاجر المتخصصة كما يمكنك تنفيذ بعض الالعاب مع طفلك لتشجيعه على التعلم وقضاء وقت ممتع معه إليك بعض الأفكار:</span>
          </p>

          <p><b>لعبة قرص الساعة:</b> ارسم قرص ساعة على ورقة، قص العقارب من الورق المقوى، واسمح لطفلك أن يضعها في الوقت المطلوب.</p>
          <p><b>لعبة مطابقة الأرقام والعقارب: </b>اصنع بطاقات بها أرقام وعقارب مختلفة، اطلب من طفلك مطابقتها مع بعضها البعض.</p>
          <p><b>تحدي صانع الساعة: </b>أعطه مجموعة من الأرقام والعقارب، دعه يصنع الساعة بنفسه.</p>
          <p><b>لعبة قراءة الساعة:</b> اعرض على الطفل ساعة، ثم اطلب منه قراءة الوقت.</p><br><br>

          <p>مع الممارسة والصبر، سيتمكن أطفالك من تعلم الساعة بالانجليزية بسهولة. ومع هذه النصائح والألعاب، ستكون رحلة التعلم تجربة ممتعة لا تنسى!</p>

          <p><strong>هل استمتعتم بقراءة المقال؟ اذا وجدت المقال مفيد نرجوا منكم مشاركته مع الاهل والأصدقاء حتى تعم الفائدة…</strong></p>
        </div>
      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "تعلم الساعة بالانجليزية للأطفال: رحلة ممتعة عبر الزمن!",
      date: "01/22/2024",
      img: require('@/assets/images/blogs/ar-9.png'),
      category: "مراجع وكتب تعليمية",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: require('@/assets/images/blogs/ar-1.png'),
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: require('@/assets/images/blogs/ar-2.png'),
          link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 5,
          title: 'كيف اتعلم اللغة الانجليزية',
          image: require('@/assets/images/blogs/ar-3.jpg'),
          link: 'ar/blog/learning-english-in-early-ages/',
          Date: '29 مايو ,2024',
          language: 'ُقافة اللغة الانجليزية',
        },
        {
          id: 6,
          title: 'انجاز جديد فى مشوار دكتور النجار',
          image: require('@/assets/images/blogs/ar-4.png'),
          link: '/ar/blog/new-achievement-in-dr-najjars-career',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
        {
          id: 3,
          title: 'الاحتفال الشهرى بالموظف المثالى',
          image: require('@/assets/images/blogs/ar-5.png'),
          link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
      ],
    };
  },

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; 
  padding: 30px; 
}
.main-content {
  display: flex;
  flex-direction: row; 
  gap: 50px; 
  direction: rtl;
  padding: 5% 15% 0 15%;
}
.content-column {
  padding: 30px;
  border-radius: 12px; 
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.blog-cards-row {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%; 
  box-sizing: border-box; 
}
h1 {
  color: #808080; 
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  text-align: right;
  margin-bottom: 20px; 
  padding-left: 16%;
}
.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-view h2{
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
 }
.blog-view p {
  margin-bottom: 18px; 
  font-size: 18px;
}
.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}
.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}
.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px; 
  margin: 20px 0;
}
.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px; 
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
  .content-column{
    padding: 20px; 
  }
  .cards-column {
   display: none;
  }
  h1 {
    font-size: 28px;
  }
  .blog-view h3 {
    font-size: 24px;
  }
  .blog-view h4 {
    font-size: 20px;
  }
}
.image-row {
    display: flex;
    justify-content: space-around; 
    align-items: center;
}
.small-photo-in-row {
    width: 200px;
    height: 200px;
    object-fit: cover; 
    margin: 0; 
}
</style>