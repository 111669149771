<template>
  <BlogListOne />

  <div class="blog-filter">
    <h3 class="subtitle">احدث المنشورات ..</h3>
    <div class="language-buttons">
      <button v-for="language in languages" :key="language" @click="selectLanguage(language)" :class="{ active: selectedLanguage === language }">
        {{ language }}
      </button>
    </div>
  </div>  

  <div class="blog-list">
    <div class="blog-card-container">
      <div v-for="post in filteredBlogCategory" :key="post.id" @click="navigateTo(post.link)" class="blog-card">
        <div class="blog-card-image-wrapper">
          <img :src="post.image" alt="Blog Image" class="blog-card-image" />
        </div>
        <div class="blog-card-content">
          <h3 class="blog-card-title">{{ post.title }}</h3>
          <span class="blog-card-date">{{ post.Date }}</span>
          <p class="blog-card-description">{{ truncatedDescription(post.description) }}</p>
        </div>
      </div>
    </div>
  </div>  
</template>

<style scoped>
.blog-filter {
  background-color: #fff;
  margin-bottom: 0; 
  padding-bottom: 0; 
  padding-top: 220px;
}
.subtitle {
  text-align: center;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 10px; 
}
.language-buttons {
  display: flex;
  justify-content: center;
  direction: rtl;
  margin-bottom: 0; 
}
.language-buttons button {
  background-color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  margin: 0 10px;
  padding: 4px 12px;
  color: #666;
}
.language-buttons button.active {
  background-color: #216488;
  color: #fff;
}
.blog-list {
  background-color: #fff;
  padding: 30px 0;
}
.blog-card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  transform: scaleX(0.8);
}
.blog-card {
  max-width: 460px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #fff;
  cursor: pointer;
}
.blog-card-image-wrapper {
  width: 100%;
  height: 0;
  padding-bottom: 75%; 
  overflow: hidden;
  position: relative;
  border-radius: 15px;
}
.blog-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  transition: opacity 0.3s ease;
}
.blog-card-image:hover {
  opacity: 0.8; 
}
.blog-card-content {
  padding: 20px;
}
.blog-card-title {
  font-size: 1.6em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}
.blog-card-date {
  display: block;
  font-size: 1em;
  color: #9c9c9c;
  margin-bottom: 15px;
  direction: rtl;
}
.blog-card-description {
  font-size: 1em;
  color: #666;
  line-height: 1.5;
}
@media (max-width: 768px) {
  .subtitle {
    font-size: 28px;
  }
  .language-buttons {
    gap: 10px;
  }
  .language-buttons button {
    padding: 6px 12px;
    font-size: 14px;
  }
  .blog-card-container {
    gap: 20px;
  }
  .blog-card {
    max-width: 100%;
  }
  .blog-card-title {
    font-size: 1.4em;
  }
  .blog-card-date {
    font-size: 0.9em;
  }
  .blog-card-description {
    font-size: 0.9em;
  }
}
@media (max-width: 480px) {
  .blog-filter{
    padding-top: 0;
    margin-top: -20px;
  }
  .subtitle {
    font-size: 24px;
  }
  .language-buttons {
    flex-direction: column;
  }
  .language-buttons button {
    margin: 0;
  }
  .blog-card-title {
    font-size: 1.2em;
  }
  .blog-card-date {
    font-size: 0.9em;
  }
  .blog-card-description {
    font-size: 0.8em;
  }
}
</style>

<script>
import BlogListOne from '@/components/ar/BlogListOne.vue';

export default {
  name: 'BlogList',
  components: {
    BlogListOne
  },
  data() {
    return {
      selectedLanguage: 'كل المنشورات',
      languages: [
      'كل المنشورات',
      'أنشطة وألعاب',
      'ثقافة اللغة الانجليزية',
      'عالم القراءة والكتابة',
      'فعاليات',
      'مراجع وكتب تعليمية',
      'نصائح للآباء'
    ],      
    blogPosts: [
        {
          id: 1,
          title: 'كيف اتعلم اللغة الانجليزية',
          image: require('@/assets/images/blogs/ar-3.jpg'),
          link: '/ar/blog/learning-english-in-early-ages',
          Date: 'مايو 29, 2024',
          language: 'ثقافة اللغة الانجليزية',
          description:'كيف اتعلم اللغة الانجليزية وانضم لـ أكاديمية مونجلش الدولية إذا كنت تسأل نفسك، ” كيف اتعلم اللغة الانجليزية ؟” عليك…',
        },
        {
          id: 2,
          title: "لقاء د. محمد شلبي برنامج بنت البلد",
          image: require('@/assets/images/blogs/ar-2.png'),
          link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
          Date: 'يونيو 9, 2024',
          language: 'فعاليات',
          description:'قاء حصري ومميز مع د. محمد شلبي المؤسس والرئيس التنفيذي لمجموعة ايديوليكسا 360 العالمية، وأكاديمية مونجلش الدولية.',
        },
        {
          id: 3,
          title: 'الإعلان عن إنطلاق مجموعة إيديولكسا 360',
          image: require('@/assets/images/blogs/ar-1.png'),
          link: '/ar/blog/edulexa-360-group',
          Date: 'يونيو 9, 2024',
          language: 'فعاليات',
          description: 'تشرفنا بعقد اجتماع موسع للإعلان عن انطلاق مجموعة ايديولكسا 360 العالمية. بحضور أعضاء مجلس الإدارة وجميع أعضاء الفريق. لمناقشة رؤيتنا…',
        },
        {
          id: 4,
          title: 'الأستثمار فى فريق العمل بمونجلش',
          image: require('@/assets/images/blogs/ar-6.jpg'),
          link: '/ar/blog/investing-in-monglish-team',
          Date: 'مايو 27, 2024',
          language: 'فعاليات',
          description: 'تستثمر أكاديمية مونجلش الدولية في نمو فريقها من خلال ورش عمل ديناميكية في القيادة والإدارة.. ',
        },
        {
          id: 5,
          title: 'الاحتفال الشهرى بالموظف المثالى' ,
          image: require('@/assets/images/blogs/ar-5.png'),
          link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
          Date: 'مايو 28, 2024',
          language: 'فعاليات',
          description: 'أبطال وراء الكواليس في مونجلش بكم حققنا المستحيل ❤️ نود أن نعبّر عن امتناننا العميق لكل واحد منكم.',
        },
        {
          id: 6,
          title: 'انجاز جديد فى مشوار دكتور النجار',
          image: require('@/assets/images/blogs/ar-4.png'),
          link: '/ar/blog/new-achievement-in-dr-najjars-career',
          Date: 'مايو 28, 2024',
          language: 'فعاليات',
          description: 'خطوة جديدة في مسيرة النجاحات وإضافة رائعة إلى سجل حافل بالإنجازات. تتقدم أكاديمية مونجلش الدولية بأحر التهاني للدكتور محمد النجار…',
        },
        {
          id: 7,
          title: 'كيفية بناء علاقات صحية بين الأهل والأطفال من خلال تشجيع السلوك الإيجابي',
          image: require('@/assets/images/blogs/blog4.png'),
          link: '/ar/blog/innovative-education-methods',
          Date: 'يناير 24, 2024',
          language: 'نصائح للآباء',
          description: 'يُعد بناء علاقات صحية بين الأهل والأطفال أمرًا أساسيًا لنمو وتطور الأطفال بشكل سليم. حيث تُساعد هذه العلاقات على تعزيز…',
        },
        {
          id: 15,
          title: 'الألعاب والأنشطة التي تعزز السلوك والتفاعل الإيجابي',
          image: require('@/assets/images/blogs/blog5.png'),
          link: '/ar/blog/leadership-workshops',
          Date: 'يناير 24, 2024',
          language: 'أنشطة وألعاب',
          description: 'يُعد اللعب جزءًا أساسيًا من حياة الأطفال، فهو يُساعدهم على النمو والتعلم وتطوير مهاراتهم الاجتماعية والعاطفية. ولذلك، من المهم اختيار…',
        },
        {
          id: 8,
          title: 'الحدث البارز لغرفة التجارة السويسرية بمشاركة ايديوليكسا 360',
          image: require('@/assets/images/blogs/ar-8.jpg'),
          link: '/ar/blog/international-partnerships',
          Date: 'مايو 27, 2024',
          language: 'فعاليات',
          description: 'تسعد شركة ايديولكسا 360، الشركة الأم لأكاديمية مونجلش الدولية، بحضور الحدث البارز لغرفة التجارة السويسرية',
        },
        {
          id: 9,
          title: 'تعلم الساعة بالانجليزية للأطفال: رحلة ممتعة عبر الزمن!',
          image: require('@/assets/images/blogs/ar-9.png'),
          link: '/ar/blog/future-of-digital-education',
          Date: 'يناير 22, 2024',
          language: 'مراجع وكتب تعليمية',
          description: 'تعلم الساعة بالانجليزية هى مهارة مهمة للأطفال، حيث ستساعدهم على فهم الوقت بشكل أفضل وتنظيم مهامهم اليومية. في هذا المقال،…',
        },
        {
          id: 10,
          title: 'رحلة حول العالم مع فصول السنة بالانجليزي!',
          image: require('@/assets/images/blogs/blog3.png'),
          link: '/ar/blog/students-achievements',
          Date: 'يناير 22, 2024',
          language: 'عالم القراءة والكتابة',
          description: 'رحلة حول العالم مع فصول السنة بالانجليزي! مرحبا يا أصدقاء المغامرات اللغوية! هل أنتم مستعدون لرحلة حول العالم؟ لا، لن…',
        },
        {
          id: 11,
          title: 'الاحتفال بتحقيق الأطفال لأهدافهم اللغوية وتقدمهم',
          image: require('@/assets/images/blogs/blog-2.png'),
          link: '/ar/blog/technology-integration',
          Date: 'يناير 24, 2024',
          language: 'نصائح للآباء',
          description: 'تعد اللغة مهارة أساسية للتعلم والتواصل والنجاح في الحياة. إنها ضرورية للتفاعل مع الآخرين، والتعبير عن الذات، واكتساب المعرفة. لذلك،…',
        },
        {
          id: 12,
          title: 'اهمية اللغة الانجليزية فى إتاحة الفرص المستقبلية لطفلك',
          image: require('@/assets/images/blogs/ar-10.png'),
          link: '/ar/blog/annual-education-conference',
          Date: 'يناير 18, 2024',
          language: 'نصائح للآباء',
          description: 'تعتبر اللغة الإنجليزية من اللغات الهامة التي يجب على الأطفال تعلمها في وقت مبكر. يعتبر تحقيق التوازن في تعلم اللغتين،…',
        },
        {
          id: 13,
          title: 'التعلم عن بعد – نظرة عامة على المنهج التفاعلي في مونجلش',
          image: require('@/assets/images/blogs/ar-11.png'),
          link: '/ar/blog/teachers-training-program',
          Date: 'يناير 21, 2024',
          language: 'ثقافة اللغة الانجليزية',
          description: 'أصبح التعلم عن بُعد و الدراسة الاونلاين خيارًا شائعًا وفعّالًا للكثير خاصة في ظل التطور التكنولوجي السريع مما يتيح للطلاب…',
        },
        {
          id: 14,
          title: 'التعلم بطريقة اللعب: استراتيجية فعالة لتحفيز الأطفال',
          image: require('@/assets/images/blogs/ar-12.png'),
          link: '/ar/blog/community-engagement',
          Date: 'يناير 22, 2024',
          language: 'ثقافة اللغة الانجليزية',
          description: 'التعلم بطريقة اللعب هو أسلوب تعليمي يعتمد على استخدام الألعاب والنشاطات الترفيهية لمساعدة الأطفال على اكتساب المعرفة والمفاهيم الجديدة. وقد…',
        },
        {
          id: 16,
          title: 'مونجلش الدولية تحصل على اعتماد ISO-29993,29994 وفقًا للمعيار الدولي لنظم التعليم والتدريب عن بعد',
          image: require('@/assets/images/blogs/ar-13.png'),
          link: '/ar/blog/parent-involvement-importance',
          Date: 'يناير 14, 2024',
          language: 'فعاليات',
          description: 'ضمن رؤية أكاديمية مونجلش الدولية 2025 نسعى دائماً للوصول لأعلى مستويات الجودة من خلال التطوير المتزامن والسريع مع ما يتطلبه…',
        },
        {
          id: 17,
          title: 'فتح الباب للمستقبل: لماذا نشجع الصغارعلى تعلم الإنجليزية فى سن مبكرة',
          image: require('@/assets/images/blogs/blogone.jpg'),
          link: '/ar/blog/scholarship-programs',
          Date: 'يناير 16, 2024',
          language: 'ثقافة اللغة الانجليزية',
          description: 'تشير الأبحاث إلى أن الفترة من سنوات الطفولة المبكرة وحتى سن البلوغ تعتبر فترة حساسة لتعلم اللغات بشكل عام. يستفيد…',
        },
        {
          id: 18,
          title: 'فوائد لا تعد ولا تحصى لتعلم لغتين منذ الصغر',
          image: require('@/assets/images/blogs/blog6.jpg'),
          link: '/ar/blog/educational-research-and-innovation',
          Date: 'يناير 17, 2024',
          language: 'ثقافة اللغة الانجليزية',
          description: 'ثنائية اللغة أصبحت موضوعًا مهمًا في تربية الأطفال في هذا العصر الحديث. يعتبر العديد من الأهل أن فهم اللغات المتعددة…',
        }
      ], 
    };   
  },
  computed: {
    filteredBlogCategory() {
      if (this.selectedLanguage === 'كل المنشورات') {
        return this.blogPosts;
      }
      return this.blogPosts.filter((post) => post.language === this.selectedLanguage);
    },
  },
  methods: {
    navigateTo(link) {
      this.$router.push(link);
    },
    selectLanguage(language) {
      this.selectedLanguage = language;
    },
    truncatedDescription(description) {
      return description.length > 120 ? description.substring(0, 120) + '...' : description;
    },
  },
};
</script>