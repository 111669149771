<template>
<div class="vision-page">
  <div class="vision-sec1">
      <h1>Big Ambitions</h1>
      <p>
        monglish_frontend, with its locations in London, New York, Alexandria, Cairo, Dubai, and Riyadh, offers the 'Mongi-Immerse' method for effective English learning. Accredited by IOTQ and ISO 9001:2015, and a member of AAA, ESQ, and AROQA, it is committed to the highest standards of quality, providing practical training for all ages, achieving remarkable results. Serving over 10,000 clients since 2019 with an international team of distinguished teachers and staff.
      </p>
      <img src="@/assets/images/vision1.png" alt="mision 1">

      <VisionTwo />
  </div>

  <div class="kids-sec8-row">
      <h4>Vision &<br> Mission</h4>
      <p>
        "At Monglish, we have a vision to become the best digital academy offering an unparalleled experience in learning foreign languages, aiming to make a positive impact on the lives of our clients.
        Our mission is to expand globally by providing high-quality online training, based on the Mongi-Immerse methodology, through our multinational team of qualified experts."
      </p>
  </div>

  <img src="@/assets/images/vision2.png" alt="mision 2" class="vision-sec2">
  
  <div class="vision-sec3">
    <h1>The philosophy of the Mongi - immers</h1>
    <div class="vision-container">
      <iframe 
        src="https://player.vimeo.com/video/818262813" 
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>
  </div>

  <div class="kids-sec8-row">
      <h4>Main <br> Goals</h4>
      <p>
        "We aim to achieve excellence and enhance performance in educating learners at various levels by offering professional and diverse programs tailored to each learner's desires. We also strive to save learners' time and money by focusing on the goals they wish to achieve and providing them with flexible schedules to enjoy their online courses through interactive live sessions. We support our learners by offering engaging content that drives them toward achieving their career ambitions. We aspire to provide creative and high-quality courses that exceed the expectations of learners from all levels and backgrounds."
      </p>
  </div>

  <img src="@/assets/images/vision3.png" alt="mision 1" class="vision-sec2" style=" border-radius: 25px;"> 
</div>  
</template>

<script>
import VisionTwo from '../../components/en/VisionTwo.vue'; 

export default {
  name: 'VisionAndMission',
  components: {
    VisionTwo,
  }
};
</script>

<style scoped>
.vision-page{
  background-color: white;
}
.vision-sec1 {
  text-align: center;
  background-image: url('@/assets/images/vision_bg.jpg');
  background-size: cover;
  background-position: center;
  margin-top: -85px;
  color: white;
  padding: 5em 1em 1em 1em;
  height: auto;
  direction: ltr;
}
.vision-sec1 p {
  padding: 1% 5%;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  font-size: 1.3em;
}
.vision-sec1 h1 {
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  font-size: 3em;
}
.vision-sec1 img {
  width: 100%;
  padding: 1%;
  padding-top: 0;
}
@media (max-width: 768px) {
  .vision-sec1 img {
    height: 35vh;
  }
  .vision-sec1{
    height:115vh;
  }
}

.kids-sec8-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1em;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  color: #165e84;
  transform: scaleX(-1);
}
.kids-sec8-row h4 {
  font-size: 3vw;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.kids-sec8-row p {
  font-size: 1.3rem;
  color: gray;
  max-width: 75%;
  margin: 0 auto;
}
@media (min-width: 769px) {
  .kids-sec8-row {
    flex-direction: row;
    justify-content: center;
    text-align: left;
  }
  .kids-sec8-row h4 {
    width: 20%;
    margin: 0;
    padding-left: 1%;
  }
  .kids-sec8-row p {
    width: 70%;
    margin: 0;
    padding-right: 2%;
  }
}
@media (max-width: 768px) {
  .kids-sec8-row {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .kids-sec8-row h4 {
    width: 100%;
    margin: 0;
    font-size: 1.5em;
  }
  .kids-sec8-row p {
    width: 100%;
    margin: 0;
    font-size: 1em;
  }
}

.vision-sec2{
  width: 95%;
  margin: 0 auto;
  margin-bottom: 1%;
}

.vision-sec3 {
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}
.vision-sec3 h1 {
  font-size: 2.8em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  margin: 1em;
}
.vision-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 45%;
  margin-bottom: 1em;
  border-radius: 2em;
}
.vision-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2em;
}
@media (max-width: 1200px) {
  .vision-sec3 h1 {
    font-size: 2.2em;
  }
  .vision-container {
    width: 90%;
    padding-bottom: 50%;
  }
}
@media (max-width: 768px) {
  .vision-sec3 h1 {
    font-size: 1.8em;
  }
  .vision-container {
    width: 95%;
    padding-bottom: 56.25%; 
  }
}
@media (max-width: 480px) {
  .vision-sec3 h1 {
    font-size: 1.5em;
  }
  .vision-container {
    width: 100%;
    padding-bottom: 60%;
  }
}
</style>