<template>
  <div class="refund-policy">
    <h1 class="title">سياسة الاسترداد وتأجيل
    </h1>
    <h2 class="sub-title">يحق للمشارك طلب استرداد أو إرجاء (إعادة جدولة) في الحالات التالية:</h2>
    <ul class="list"><li>استرداد كامل: يُسترد المبلغ المدفوع بالكامل لقاء تكلفة الدورة الفعلية (بعد خصم 10٪ كرسوم إدارية) بشرط التقدم بطلب الاسترداد قبل 48 ساعة على الأقل من تاريخ بدء الدورة. لن يتم استرداد أي مبلغ في حالة طلب الإلغاء بعد أقل من 48 ساعة قبل بدء الدورة.</li>
      <li >إرجاء (تجميد): في حالة تسجيل مشترك جديد بخيار التجميد (تأجيل تاريخ البدء)، يمكن استرداد المبلغ بالكامل خلال 14 يومًا من تاريخ الدفع (شريطة عدم حضور اختبار تحديد المستوى أو بدء التدريب). يتوفر خيار التجميد لمدة أقصاها ستة أشهر.</li>
      <li>لا يحق للمشاركين الحصول على استرداد بعد الفترات المذكورة أعلاه.</li>
      <li>تتم معالجة عمليات الاسترداد خلال أسبوعين كحد أقصى، بعد الاتصال بنا.</li>
      <li>يتحمل العميل جميع تكاليف التحويل الدولي التي يتم دفعها لمعالجة عملية الاسترداد.</li>
      <li>يُرجى العلم أنه لا يمكن نقل تسجيلات الدورة إلى أشخاص آخرين (الأشقاء أو الأقارب / التجميد أثناء الدورات / حضور الفصول الأساسية فقط بدون النوادي).</li></ul>
  </div>
</template>

<script>
export default {
  name: 'RefundPolicy'
};
</script>
<style scoped>
.refund-policy {
  width: 80%;
  margin: auto;
  padding:  80px !important;
border-radius: 20px;
  background:white;
  box-shadow: 0 4 25 0 rgba(0, 0, 0, 0.2) !important ;
  margin-top: -130px;
  margin-bottom: 100px; }
.title {
  color:#165e84;
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.5em;
  margin-bottom: 10px;
}
.sub-title {
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 25px;
}
.list{
list-style: unset;
font-family: "DIN Next LT Arabic", Sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 25px;
  line-height: 1.5em;
}

@media only screen and (max-width: 768px){
  .refund-policy{
      padding: 30px !important;
  }
  .title{
      font-size: 28px;
  }
}
</style>
  