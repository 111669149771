<template>
  <div>
    <div class="video-ticket" @click="openModal">
      <div class="video-thumbnail">
        <img :src="thumbnailUrl" alt="Video Thumbnail" />
      </div>
      <div class="video-text">
        <div class="video-description">
          <p>{{ truncatedDescription }}</p>
        </div>
        <div class="video-date">
          <p>{{ publish_date }}</p>
        </div>
      </div>
    </div>
    
    <div v-if="isModalOpen" class="modal" @click.self="closeModal">
      <div class="modal-content">
        <iframe
            :width="iframeWidth"
          height="400"
          :src="videoUrl"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    videoId: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: ''
    },
    publish_date: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isModalOpen: false
    };
  },
  computed: {
    iframeWidth() {
      return window.innerWidth > 950 ? 900 : 300;
    },
    videoUrl() {
      return `https://www.youtube.com/embed/${this.videoId}?autoplay=1`;
    },
    thumbnailUrl() {
      return `https://img.youtube.com/vi/${this.videoId}/hqdefault.jpg`;
    },
    truncatedDescription() {
      const maxLength = 60;
      return this.description.length > maxLength 
        ? this.description.substring(0, maxLength) + '...'
        : this.description;
    }
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    }
  }
};
</script>

<style scoped>
.video-ticket {
  position: relative;
  background-color: white;
  height: 400px;
  width: 400px;
  cursor: pointer;
  margin-bottom: 25px;
  border-radius: 15px;
  z-index: 10;
  box-shadow: 0 0 10px 2px rgb(0, 0, 0, 0.07);
}
.video-thumbnail {
  position: relative;
  width: 100%;
  height: 225px;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 15px 15px 0 0;
}

.video-thumbnail img {
  width: auto;
  height: 305px;
}

.video-ticket .video-text {
  margin: 20px;
}

.video-ticket .video-text .video-description{
  font-size: 20px;
  font-weight: bold;
}

.video-ticket .video-text .video-date{
  font-size: 15px;
  opacity: .5;
  position: absolute;
  bottom: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.modal-content {
  position: relative;
  padding: 20px;
}

@media (max-width: 768px) {
  .video-ticket {
    width: 350px;
    height: 350px;
    margin-bottom: 0;
    overflow: hidden;
  }
  .video-thumbnail {
    height: 200px;
  }
  .video-thumbnail img {
    height: 268px;
  }
}
</style>
