<template>
    <div class="container">
        <h1>تم تسجيل طلبك بنجاح</h1>
        <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="#e56311">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g data-name="Layer 2" id="Layer_2">
                    <g data-name="E408, Success, Media, media player, multimedia" id="E408_Success_Media_media_player_multimedia">
                        <circle class="cls-1" cx="256" cy="256" r="246"></circle>
                        <polyline class="cls-1" points="115.54 268.77 200.67 353.9 396.46 158.1"></polyline>
                    </g>
                </g>
            </g>
        </svg>
        <h3>تمت عمليه الدفع برجاء التأكد من الايميل الخاص بك</h3>
    </div>
</template>

<script>
export default {
  name: 'PaymentSuccess'
}
</script>

<style scoped>
.cls-1 {
    fill: none;
    stroke: #f47d21;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 20px;
}
</style>
