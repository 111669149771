<template>
  <div class="faq-section-n">
    <h4>Student affairs</h4>
    <div class="faq-items-n">
      <div class="faq-items-sub">
        <div class="faq-item-min" v-for="(item, index) in faqItems_1" :key="index">
          <button @click="toggleAnswer(index)" :class="{ active: activeIndex === index }">
            {{ item.question }}
            <span :class="{ activeSpan: activeIndex === index }">
            <span v-if="activeIndex === index">-</span>
            <span v-else>+</span>
          </span>
          </button>
          <transition name="fade">
            <div v-show="activeIndex === index" :class="['answer', { 'answer-active': activeIndex === index }]">
              {{ item.answer }}
            </div>
          </transition>
        </div>
      </div>
      <div class="faq-items-sub">
        <div class="faq-item-min" v-for="(item, index) in faqItems_2" :key="index + 3">
          <button @click="toggleAnswer(index + 6)" :class="{ active: activeIndex === index + 6 }">
            {{ item.question }}
            <span :class="{ activeSpan: activeIndex === index + 6 }">
            <span v-if="activeIndex === index + 6">-</span>
            <span v-else>+</span>
          </span>
          </button>
          <transition name="fade">
            <div v-show="activeIndex === index + 6" :class="['answer', { 'answer-active': activeIndex === index + 6 }]">
              {{ item.answer }}
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewThreePartOne',
  data() {
    return {
      activeIndex: null,
      faqItems_1: [
        {
          question: 'Shyness and inability to interact with others',
          answer: 'Classes boost the student’s confidence and ability to interact with people from various nationalities and ages.'
        },
        {
          question: 'Boring curriculums and textbooks',
          answer: 'We offer an innovative curriculum that inspires and provides deep understanding without the need for many books.'
        },
        {
          question: 'How can I subscribe?',
          answer: 'Step one: Contact the Monglish team for a level assessment interview. Step two: Pay the tuition fees and choose the schedule. Step three: Start studying after 7-14 working days.'
        },
        {
          question: 'My English is very weak, can I understand the teacher?',
          answer: 'You will be able to understand the teacher clearly, and you will be placed in the level that suits you perfectly.'
        },
        {
          question: 'I have a complaint about the study?',
          answer: 'monglish_frontend is committed to ensuring complete satisfaction during the study period: we conduct weekly and monthly surveys to provide immediate support if there is a complaint through a distinguished customer service team available around the clock.'
        },
        {
          question: 'Inability to interact with others',
          answer: 'The academy helps trainees gain self-confidence and the ability to face and speak fluently with others through debate clubs across different classes at Monglish Academy.'
        }
      ],
      faqItems_2: [
        {
          question: 'Fatigue from excessive homework',
          answer: 'We use smart teaching methods and varied homework that students can complete independently without parental assistance, such as electronic question banks, audio and text assignments, and other methods. Homework is reviewed via the Monglish app.'
        },
        {
          question: 'I prefer not to study remotely',
          answer: 'Remote learning at Monglish combines flexibility and comfort, using modern technologies to enhance interaction and provide an effective and comprehensive learning experience, saving time and costs while ensuring quality education.'
        },
        {
          question: 'I want individual classes',
          answer: 'Monglish offers weekly individual classes to learn reading skills with a professional teacher. However, English is a communication language learned through groups with a limited number (6 to 8 individuals).'
        },
        {
          question: 'Can I get a refund on the subscription fees?',
          answer: 'The trainee is entitled to a full refund of the tuition fees if requested 48 hours before the start of the study.'
        },
        {
          question: 'Concerns about teacher competency',
          answer: 'Monglish Academy ensures careful selection of teachers, including a team of highly qualified educators accredited by prestigious international universities such as Cambridge and Oxford.'
        },
      ]
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style src="@/assets/styles/ar/ViewThree.css" scoped></style>

<style scoped>
.faq-section-n{
    direction:ltr;
}
.faq-item-n button , .faq-item-n .answer{
    text-align: left;
}
.faq-section-n h4{
    text-align: left;
    padding-left:30px;
}
</style>