<template>
  <div class="section-two">
    <div class="title my-5">
      <div>WE SERVE EVERYONE!</div>
      <div>The academy offers integrated educational and training courses for all those wishing to learn</div>
    </div>
    <div class="cards-container">
      <div class="card-2">
        <img src="@/assets/images/section2img1.png" alt="Study Abroad" />
        <div class="t1">STUDY ABROAD COURSES</div>
        <div class="t2">(UK & USA)</div>
        <div class="t3">Studying in a country where English is a native language is a great way to become fluent. As partner agents for accredited ESL centers in the UK & USA, we can help you chose the best English language programs. Our schools have more than 70 years of experience, and enable the issuance of study abroad visas.</div>
        <MainButton @click="goToPackagesPage">Start Now</MainButton>
      </div>
      <div class="card-2">
        <img src="@/assets/images/section2img2.png" alt="Kids Course" />
        <div class="t1">YOUNG LEARNER COURSES</div>
        <div class="t2">(ONLINE)</div>
        <div class="t3">Mongi-Immerse methodology is the ideal solution to ensure that young learners receive the highest levels of education and training in the English language, through daily language acquisition activities. Young learners will get the necessary guidance to excel and study with confidence.</div>
        <MainButton @click="goToKidsCoursesPage">Start Now</MainButton>
      </div>
      <div class="card-2">
        <img src="@/assets/images/section2img3.png" alt="Adults Course" />
        <div class="t1">ADULT EDUCATION COURSES</div>
        <div class="t2">(ONLINE)</div>
        <div class="t3">Excel with your English language skills and 10X your results with online learning today! Teaming up with our experienced tutors allows you to easily improve your language, so you can achieve more on a professional and a personal level.</div>
        <MainButton @click="goToAdultsCoursesPage">Start Now</MainButton>
      </div>
    </div>
  </div>
</template>


<script>
import MainButton from '../Elements/MainButton.vue';

export default {
  name: 'ViewTwo',
  components: {
    MainButton,
  },
  methods: {
    goToKidsCoursesPage() {
      this.$router.push({
        path: '/en/kids-courses',  
        hash: '#packages-section'  
      });
    },
    goToAdultsCoursesPage() {
      this.$router.push({
        path: '/en/adult-courses',  
        hash: '#packages-section'  
      });
    },
    goToPackagesPage() {
      this.$router.push({ name: 'PackagesViewEn' });
    }
  },
}
</script>

<style src="@/assets/styles/ar/ViewTwo.css" scoped></style>
