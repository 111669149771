<template>
  <div class="terms-and-conditions">
    <h1>Terms and Conditions</h1>
    <section>
      <h2>Your guide to a better learning experience</h2>
      <p>Everything you need to know about our terms and conditions is here to guide you.</p>
    </section>

    <div>
      <div class="search-box">
        <input
          class="search-input"
          type="text"
          v-model="searchQuery"
          placeholder="... Search"
        />
        <button @click="filterItems">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>

    <div>
      <!-- Tabs -->
      <div class="tabs">
        <router-link
          v-for="(tab, index) in filteredTabs"
          :key="index"
          :to="tab.path"
          :class="{ active: activeTab === index }"
          @click="setActiveTab(index)"
        >
          {{ tab.name }}
        </router-link>
      </div>
    </div>
  </div>
  <!-- Dynamic Tab Content -->
  <div class="dynamiccontent">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditionsEn",
  data() {
    return {
      searchQuery: '',
      activeTab: 0,
      tabs: [
        { name: 'All', path: '/en/terms-and-conditions/all' },
        { name: 'Refund', path: '/en/terms-and-conditions/refund' },
        { name: 'Security and protection', path: '/en/terms-and-conditions/security' },
        { name: 'Privacy Policy', path: '/en/terms-and-conditions/privacy' },
        { name: 'Terms And Conditions', path: '/en/terms-and-conditions/terms' }
      ],
      filteredTabs: []
    };
  },
  methods: {
    filterItems() {
      this.filteredTabs = this.tabs.filter(tab =>
        tab.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
      // Reset active tab if no search result matches current active tab
      if (this.filteredTabs.length === 0 || !this.filteredTabs[this.activeTab]) {
        this.activeTab = 0;
      }
    },
    setActiveTab(index) {
      this.activeTab = index;
    },
    updateActiveTab() {
      const currentPath = this.$route.path;
      const matchingTab = this.tabs.findIndex(tab => tab.path === currentPath);
      this.activeTab = matchingTab !== -1 ? matchingTab : 0;
    }
  },
  watch: {
    '$route'() {
      this.updateActiveTab();
    }
  },
  created() {
    this.filteredTabs = this.tabs; // Initialize with all tabs
    this.updateActiveTab(); // Set the active tab on initial load
  }
};
</script>

<style scoped>
/* Your existing styles */
.terms-and-conditions {
  direction: ltr;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-image: url('@/assets/images/faq_bg.jpg');
  background-size: cover;
  background-position: center;
  height: 90vh;
  margin-top: -117px;
  padding-top: 105px;
  background-color: white;
  padding-bottom: 50px;
}
h1 {
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: white;
  margin-top: 120px;
  margin-bottom: 15px;
}
h2 {
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-size: 64px;
  font-weight: 700;
  text-align: center;
  color: white;
  margin-bottom: 15px;
}
p {
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color: white;
  margin-bottom: 15px;
}
input {
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 5px 0;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}
button {
  background-color: #f47d21;
  color: white;
  border: none;
  padding: 13px 30px;
  border-radius: 8px;
  width: 15%;
}
.search-box {
  margin: auto;
  text-align: center;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 8px;
  width: 50%;
  margin-bottom: 30px;
}
.search-input {
  border: none;
  margin: 0px;
  max-width: 85%;
}
.tabs a {
  position: relative;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-size: 20px;
  font-weight: 500;
  background-color: #61CE7000;
  color: white;
}
.tabs a.active::after {
  content: '\2022'; /* Unicode for a bullet/dot */
  position: absolute;
  left: 50%;
  bottom: -30px;
  font-size: 40px; /* Adjust size of the dot */
  color: orange; /* Adjust the color of the dot */
  transform: translateX(-50%);
}
.tab-content {
  margin-top: 20px;
}
.tabs {
  display: flex;
  justify-content: center;
}
@media only screen and (max-width: 768px){
  h2{
    font-size: 35px;
  }
  .search-box {
    width: 90%;
  }
  .tabs{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .tabs button{
    width: 45%;
    font-size: 20px;
  }
}
@media only screen and (max-width: 391px){
  .terms-and-conditions{
    margin-top: -85px;
    padding-top: 5px;
    height: 95vh;
  }}
@media only screen and (max-width: 376px){
  .terms-and-conditions{
    margin-top: -85px;
    padding-top: 5px;
    height: 120vh;
  }}
@media only screen and (max-width: 1369px){
  .terms-and-conditions{
    height: 75vh;
  }}
@media only screen and (max-width: 1281px){
  .terms-and-conditions{
    height: 95vh;
  }}
@media only screen and (max-width: 1025px){
  .terms-and-conditions{
    height: 60vh;
  }}
@media only screen and (max-width: 821px){
  .terms-and-conditions{
    height: 76vh;
  }}
@media only screen and (max-width: 541px){
  .terms-and-conditions{
    height: 120vh;
  }}
@media only screen and (max-width: 431px){
  .terms-and-conditions{
    height: 97vh;
  }}
@media only screen and (max-width: 376px){
  .terms-and-conditions{
    height: 120vh;
  }}
@media only screen and (max-width: 345px){
  .terms-and-conditions{
    height: 90vh;
  }}
</style>
