<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>تعد اللغة مهارة أساسية للتعلم والتواصل والنجاح في الحياة. إنها ضرورية للتفاعل مع الآخرين، والتعبير عن الذات، واكتساب المعرفة. لذلك، من المهم الاحتفال بتحقيق الأطفال لأهدافهم اللغوية وتقدمهم.</p>

          <h2><b>أهمية الاحتفال بأهداف اللغة</b></h2>

          <p>يساعد الاحتفال بأهداف اللغة الأطفال على:</p>

          <ul>
            <li style="font-weight: 400;" aria-level="1">
              <b>تعزيز شعور الطفل بالثقة بالنفس:</b>
              <span style="font-weight: 400;"> عندما يشعر الطفل بتقدير إنجازاته، يزداد شعوره بالثقة بالنفس ويصبح أكثر تحفيزًا لتحقيق المزيد.</span>
            </li>
            <li style="font-weight: 400;" aria-level="1">
              <b>تحفيز الطفل على الاستمرار في التميز:</b>
              <span style="font-weight: 400;"> عندما يشعر الطفل بتقدير إنجازاته، يصبح أكثر رغبة في الاستمرار في التميز وتحقيق المزيد من النجاحات.</span>
            </li>
            <li style="font-weight: 400;" aria-level="1">
              <b>تقوية العلاقات بين الطفل والوالدين:</b>
              <span style="font-weight: 400;"> عندما يحتفل الوالدين بإنجازات طفلهما، يزداد شعورهم بالحب والارتباط به.</span>
            </li>
          </ul>

          <h2><b>طرق الاحتفال بإنجازات الطفل:</b></h2>

          <p>هناك العديد من الطرق للاحتفال بأهداف اللغة للأطفال. إليك بعض الأفكار:</p>

          <ul>
            <li aria-level="1">
              <b>التعبير عن المشاعر الإيجابية:</b>
              <ul>
                <li>عبّر عن فرحتك وسعادتك بإنجازات طفلك، سواء كانت كبيرة أو صغيرة.</li>
                <li aria-level="2">استخدم عبارات إيجابية مثل “مبروك!” و “أنا فخور بك!” و “أنت رائع!”.</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li aria-level="1"><b>تقديم الهدايا:</b></li>
          </ul>

          <ul>
            <li style="list-style-type: none;">
              <ul>
                <li aria-level="2">قدّم هدية رمزية للطفل كنوع من التقدير والتشجيع.</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li style="list-style-type: none;">
              <ul>
                <li aria-level="2">تأكد من أن الهدية مناسبة لعمر الطفل واهتماماته.</li>
              </ul>
            </li>
          </ul>
          
          <ul>
            <li aria-level="1"><b>قضاء وقت ممتع معًا:</b></li>
          </ul>

          <ul>
            <li style="list-style-type: none;">
              <ul>
                <li aria-level="2">خصص وقتًا للعب مع طفلك أو ممارسة نشاط ممتع يفضله.</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li style="list-style-type: none;">
              <ul>
                <li aria-level="2">استغل هذا الوقت للتحدث مع طفلك عن إنجازاته وكيف حققها.</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li aria-level="1"><b>التعبير عن الامتنان:</b></li>
          </ul>

          <ul>
            <li style="list-style-type: none;">
              <ul>
                <li aria-level="2">عبّر عن امتنانك لوجوده في حياتك.</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li aria-level="1"><b>مشاركة إنجازات الطفل مع الآخرين:</b></li>
          </ul>

          <ul>
            <li style="list-style-type: none;">
              <ul>
                <li aria-level="2">شارك إنجازات طفلك مع العائلة والأصدقاء.</li>
              </ul>
            </li>
          </ul>

          <ul>
            <li style="list-style-type: none;">
              <ul>
                <li aria-level="2">انشر صورًا أو مقاطع فيديو لإنجازات طفلك على مواقع التواصل الاجتماعي.</li>
              </ul>
            </li>
          </ul>

          <img src="@/assets/images/blogs/blog2-1.jpg">

          <h2><b>أمثلة على كيفية الاحتفال بإنجازات الطفل:</b></h2>

          <p>إليك بعض الأمثلة لتشجيع وتحفيز أطفالك على الأستمرار:</p>

          <ul>
            <li aria-level="1">إذا تعلم طفلك كلمة جديدة، يمكنك الاحتفال بذلك عن طريق لعب لعبة أو قراءة كتاب حول المفهوم الذي تمثله الكلمة.</li>
            <li aria-level="1">إذا بدأ طفلك في تكوين جمل كاملة، يمكنك الاحتفال بذلك عن طريق كتابة قصة عنه أو صنع قطعة فنية تعكس تقدمه.</li>
            <li aria-level="1">إذا بدأ طفلك في قراءة الكتب بمفرده، يمكنك الاحتفال بذلك عن طريق الذهاب إلى المكتبة أو معرض الكتاب.</li>
          </ul>

          <h2>نصائح لتشجيع الطفل على التطور:</h2>

          <ul>
            <li style="font-weight: 400;" aria-level="1"><b>توفير بيئة داعمة:</b>
              <ul>
                <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">وفّر بيئة آمنة وداعمة للطفل يشعر فيها بالراحة والحرية للتعبير عن نفسه.</span></li>
                <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">شجّع طفلك على تجربة أشياء جديدة واكتشاف مهاراته وقدراته.</span></li>
              </ul>
            </li>
            <li style="font-weight: 400;" aria-level="1"><b>تحديد أهداف واقعية:</b>
              <ul>
                <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">ساعد طفلك على تحديد أهداف واقعية قابلة للتحقيق.</span></li>
                <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">قسّم الأهداف الكبيرة إلى خطوات صغيرة قابلة للتحقيق.</span></li>
              </ul>
            </li>
            <li style="font-weight: 400;" aria-level="1"><b>التحفيز والتشجيع:</b>
              <ul>
                <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">شجّع طفلك على بذل المزيد من الجهد لتحقيق أهدافه.</span></li>
                <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">ركّز على نقاط قوته ومهاراته، وساعده على التغلب على نقاط ضعفه.</span></li>
              </ul>
            </li>
            <li style="font-weight: 400;" aria-level="1"><b>التقدير والاعتراف:</b>
              <ul>
                <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">عبّر عن تقديرك لجهود طفلك، حتى لو لم ينجح في تحقيق هدفه.</span></li>
                <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">ركّز على التعلم والتجربة، وليس فقط على النتيجة النهائية.</span></li>
              </ul>
            </li>
          </ul>

          <p><span style="font-weight: 400;">يُعدّ الاحتفال بإنجازات الطفل وتشجيعه على التطور من أهم الأمور التي تُساهم في تنمية مهاراته وقدراته، وتعزيز ثقته بنفسه، وجعله شخصًا إيجابيًا وناجحًا في المستقبل.</span></p>

          <p><b>تذكر:</b></p>

          <ul>
            <li style="font-weight: 400;" aria-level="1">
              <span style="font-weight: 400;">كل طفل فريد من نوعه، وله قدرات وإمكانيات مختلفة.</span>
            </li>
            <li style="font-weight: 400;" aria-level="1">
              <span style="font-weight: 400;">قارن طفلك بنفسه فقط، ولا تقارنه بالآخرين.</span>
            </li>
            <li style="font-weight: 400;" aria-level="1">
              <span style="font-weight: 400;">استمتع برحلة طفلك وتعلم معه، وكن صبورًا وداعمًا له.</span>
            </li>
          </ul>

          <p><strong>اتمنى أن يكون المقال نال أعجبكم! لا تترددوا في مشاركة هذا المقال مع الأصدقاء والأهل. حتى المرة القادمة!&nbsp;</strong></p>
        </div>
      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "الاحتفال بتحقيق الأطفال لأهدافهم اللغوية وتقدمهم",
      date: "01/24/2024",
      img: require('@/assets/images/blogs/blog-2.png'),
      category: "نصائح للآباء",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: require('@/assets/images/blogs/ar-1.png'),
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: require('@/assets/images/blogs/ar-2.png'),
          link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 5,
          title: 'كيف اتعلم اللغة الانجليزية',
          image: require('@/assets/images/blogs/ar-3.jpg'),
          link: 'ar/blog/learning-english-in-early-ages/',
          Date: '29 مايو ,2024',
          language: 'ُقافة اللغة الانجليزية',
        },
        {
          id: 6,
          title: 'انجاز جديد فى مشوار دكتور النجار',
          image: require('@/assets/images/blogs/ar-4.png'),
          link: '/ar/blog/new-achievement-in-dr-najjars-career',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
        {
          id: 3,
          title: 'الاحتفال الشهرى بالموظف المثالى',
          image: require('@/assets/images/blogs/ar-5.png'),
          link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
      ],
    };
  },

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; 
  padding: 30px; 
}
.main-content {
  display: flex;
  flex-direction: row; 
  gap: 50px; 
  direction: rtl;
  padding: 5% 15% 0 15%;
}
.content-column {
  padding: 30px;
  border-radius: 12px; 
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.blog-cards-row {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%; 
  box-sizing: border-box; 
}
h1 {
  color: #808080; 
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  text-align: right;
  margin-bottom: 20px; 
  padding-left: 16%;
}
.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-view h2{
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
 }
.blog-view p {
  margin-bottom: 18px; 
  font-size: 18px;
}
.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}
.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}
.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px; 
  margin: 20px 0;
}
.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px; 
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
  .content-column{
    padding: 20px; 
  }
  .cards-column {
   display: none;
  }
  h1 {
    font-size: 28px;
  }
  .blog-view h3 {
    font-size: 24px;
  }
  .blog-view h4 {
    font-size: 20px;
  }
}
.image-row {
    display: flex;
    justify-content: space-around; 
    align-items: center;
}
.small-photo-in-row {
    width: 200px;
    height: 200px;
    object-fit: cover; 
    margin: 0; 
}
</style>