<template>
  <BlogHead :title="title" :img="img" :date="date"/>
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>
            Research shows that the period from early childhood to puberty is a sensitive period for language learning in general. Children benefit from an innate ability to absorb languages quickly and easily at this stage.
            <br />
            <br />
            Therefore, it is preferable to start teaching children English early, while providing an environment rich in stimulation and communication in the target language.
            <br />
            <br />
            English language learning for children can be incorporated into daily activities and games to promote natural and enjoyable learning for children.
          </p>
          <h3>The importance of children learning language acquisition at an early age:</h3>
          <ul>
            <li>Childhood is a golden period for acquiring skills and languages, where understanding the importance of language acquisition at an early age plays a major role in shaping children’s future.</li>
            <li>Language is not just a means of communication, but it is also a foundation for understanding the world and building healthy relationships in the future.</li>
            <li>Language acquisition in the early stages plays a crucial role in developing children’s cognitive abilities, and this process contributes to the development of thinking and expression skills, which positively contributes to general intelligence and the child’s ability to deal with mental challenges.</li>
          </ul>
          <h3>Developing cognitive abilities:</h3>
          <p>
            Children learning language at an early age plays a crucial role in developing children’s cognitive abilities.
            <br />
            <br />            
            This process helps develop thinking and expression skills, which positively affects general intelligence and the child’s ability to face mental challenges.
          </p>
          <h3>Improving reading and writing skills:</h3>
          <p>
            Language is the foundation for learning reading and writing skills. If a child has a strong foundation in language, they will find it easier to develop reading and writing skills, leading to better performance in school and in their academic life.
          </p>
          <h3>Establishing a foundation for learning other languages:</h3>
          <p>
            Children learning and acquiring language early on helps build a strong foundation for learning other languages in the future. The child becomes more prepared to absorb new languages, which opens up a wider horizon for understanding the world and communicating with diverse cultures.
          </p>
          <img src="@/assets/images/blogs/learning-1-1.jpg" class="blog-post-img" />
          <h3>The cognitive benefits of bilingualism in children:</h3>
          <h4>Enhancing creative thinking:</h4>
          <p>
            Studies indicate that children who learn two languages have greater creative abilities. It allows them to switch between languages easily, which enhances creativity and the development of new ideas in an effective way.
          </p>
          <h4>Enhancing logical thinking:</h4>
          <p>
            Logical thinking skills are one of the most important benefits that children who speak two languages achieve. This works to develop the ability to solve problems and make decisions more accurately.
          </p>
          <h4>Promoting social skill development:</h4>
          <p>
            Learning English for children helps open doors to communication with other children around the world. This enhances social interaction and the development of cooperation skills, which positively affects their social and emotional development.
          </p>
          <h3>How English language education enhances your child’s future opportunities:</h3>
          <p>
            In our diverse and connected world, English has become the global language of communication. Teaching English to children at an early age enhances their understanding of the world and gives them a powerful tool for effective communication. This helps develop thinking skills and understand advanced concepts better.
          </p>
          <h4>Opening up job opportunities:</h4>
          <p>
            Learning English for children at an early age allows children to be better prepared for the future. Their deep understanding of the language contributes to achieving wider job opportunities, as they become able to interact with companies and global institutions.
          </p>
          <h4>Facilitating academic learning:</h4>
          <p>
            English language education enhances their ability to absorb knowledge from various sources. This contributes to improving their academic performance and excelling in different fields, which opens doors to higher education for them.
          </p>
          <h4>Promoting confidence and optimism:</h4>
          <p>
            In addition to the cognitive and social benefits of learning, teaching English to children helps build their confidence and optimism. They feel confident that they have the tools they need to interact and adapt in a world characterized by cultural diversity.
          </p>
          <h3>How to motivate children to learn English:</h3>
          <img src='@/assets/images/blogs/learning-1-2.jpg' class="blog-post-img" />
          <p>Motivating English language education for children requires innovative methods:-</p>
          <ul>
            <li>Educational games and inspiring stories can be used to make the learning process enjoyable and effective.</li>
            <li>Play is an effective way to promote the fun of learning.</li>
            <li>Interactive games can teach children vocabulary and sentence structures in an informal and engaging way.</li>
            <li>Technology offers tremendous opportunities to facilitate the language learning process through smartphone applications.</li>
          </ul>
          <h3>The role of parents in supporting children’s learning:</h3>
          <p>Parents play a crucial role in supporting children’s learning:-</p>
          <ul>
            <li>They should encourage children and provide a supportive environment to motivate them to learn English.</li>
            <li>It is also necessary to avoid excessive pressure while providing sufficient support for children during these experiences to face the challenges of learning English.</li>
          </ul>
            <br />
            <br />          
          <p class="strong-paragraph">
            It can be said that early language acquisition is not only necessary for children’s personal development, but also plays a crucial role in their academic and social success. Parents and teachers should be prepared to support this vital process and guide children towards a smart and future full of possibilities.
            <br /><br />
            Did you enjoy reading the article? If you found the article useful, please share it with your friends so that the benefit can be shared.
          </p>
        </div>
      </div>
      <div class="cards-column">
        <BlogCard
          v-for="blog in blogCards"
          :key="blog.id"
          :post="blog"
        />
      </div>
    </div>

    <h1>You may like it</h1>
    <div class="blog-cards-row">
      <BlogCard
        v-for="blog in blogCards"
        :key="blog.id"
        :post="blog"
      />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogOne",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title:"Learning English in Early Ages",
      date: "02/28/2024",
      img: require('@/assets/images/blogs/blogone.jpg'),
      blogCards: [
        {
          id: 1,
          title: 'Learning English in Early Ages',
          image: require('@/assets/images/blogs/blogone.jpg'),
          link: '/en/blog/learning-english-in-early-ages',
        },
        {
          id: 2,
          title: "Celebrating Children’s Language Progress.",
          image: require('@/assets/images/blogs/blog-2.png'),
          link: '/en/blog/celebrating-childrens-language-progress',
        },
        {
          id: 3,
          title: 'Seasons of The Year',
          image: require('@/assets/images/blogs/blog3.png'),
          link: '/en/blog/seasons-of-the-year',
        },
        {
          id: 4,
          title: 'Parents and Children Healthy Relationships',
          image: require('@/assets/images/blogs/blog4.png'),
          link: '/en/blog/parents-and-children-healthy-relationships',
        },
        {
          id: 5,
          title: 'Activities For Positive Behavior',
          image: require('@/assets/images/blogs/blog5.png'),
          link: '/en/blog/activities-for-positive-behavior',
        },
        {
          id: 6,
          title: 'Benefits Of Bilingual For Children',
          image: require('@/assets/images/blogs/blog6.jpg'),
          link: '/en/blog/benefits-of-bilingual-for-children',
        },
      ],
    };
  },
};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; 
  padding: 30px; 
}
.main-content {
  display: flex;
  flex-direction: row; 
  gap: 50px; 
  direction: ltr;
  padding: 5% 15% 0 15%;
}
.content-column {
  padding: 30px;
  border-radius: 12px; 
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.blog-cards-row {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%; 
  box-sizing: border-box; 
}
h1 {
  color: #808080; 
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px; 
  padding-left: 16%;
}
.blog-view {
  margin-left: 2.5%;
  text-align: left;
  color: #535353; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-view p {
  margin-bottom: 18px; 
  font-size: 18px;
}
.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}
.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}
.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px; 
  margin: 20px 0;
}
.strong-paragraph {
  font-weight: 700;
  text-align: left;
  color: #1a1a1a;
  font-size: 20px; 
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
  .content-column{
    padding: 20px; 
  }
  .cards-column {
   display: none;
  }
  h1 {
    font-size: 28px;
  }
  .blog-view h3 {
    font-size: 24px;
  }
  .blog-view h4 {
    font-size: 20px;
  }
}
</style>
