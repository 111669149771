<template>
    <div>
      <!-- Render all components together -->
      <TermsConditionComponent />
      <PrivacyPolicyComponent />
      <SecurityComponent />
      <RefundComponent />
    </div>
  </template>
  
  <script>
  import TermsConditionComponent from '@/components/ar/TermsCondition.vue';
  import PrivacyPolicyComponent from '@/components/ar/PrivacyPolicy.vue';
  import SecurityComponent from '@/components/ar/SecurityPolicy.vue';
  import RefundComponent from '@/components/ar/RefundPolicy.vue';
  
  export default {
    name: 'AllTermsConditionsComponent',
    components: {
      TermsConditionComponent,
      PrivacyPolicyComponent,
      SecurityComponent,
      RefundComponent
    }
  };
  </script>
  