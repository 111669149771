<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
            <p>معاً نحو الريادة والتميز مع Edulixa 360 ☄️<br><br></p>

<p>في يوم مليء بالفخر والنجاح، تشرفنا بعقد اجتماع موسع للإعلان عن انطلاق مجموعة Edulixa 360 العالمية، بحضور أعضاء مجلس الإدارة وجميع أعضاء الفريق، لمناقشة رؤيتنا المستقبلية وأهدافنا التي نسعى لتحقيقها.<br><br></p>

<p>فخورون جداً بما حققناه من نجاحات وتقدم ملموس في تطبيق منهجية “المعايشة” التي أثبتت فعاليتها في تعليم الأطفال باستخدام تقنيات تفاعلية تجعل التعلم متعة وشغفاً لا ينتهيان.<br><br></p>

<p>نود أن نشكر كل من ساهم في هذا الإنجاز، من معلمينا وموظفينا وشركائنا في Edulixa 360، في هذه الرحلة الناجحة. نتطلع دائماً لمواصلة هذا التقدم وتحقيق المزيد من الإنجازات التي تثبت مكانتنا كرواد في مجال التعليم والتطوير.<br><br></p>

<p>نشعر بفخر كبير بما أنجزناه حتى الآن، ونؤمن بأن هذا ليس سوى بداية رحلتنا نحو مستقبل أفضل في عالم التعليم. إن رؤيتنا تعتمد على الابتكار والتفوق، ونسعى جاهدين لتقديم تجارب تعليمية متميزة تلبي احتياجات الطلاب وتعزز من إمكانياتهم.<br><br></p>

<p>في Edulixa 360، نلتزم بتطوير تقنيات وأساليب تعليمية حديثة تواكب التغيرات السريعة في العالم، ونعمل على خلق بيئة تعليمية محفزة تشجع على التفكير النقدي والإبداع. نؤمن بأن التعليم هو الأساس لبناء مستقبل مشرق، ومن هذا المنطلق، نعمل بلا كلل لتحقيق أهدافنا وتحقيق رؤيتنا.<br><br></p>

<p>نجاحنا هو نتيجة لتفاني وجهود فريق عملنا الرائع، ونشكرهم على إسهاماتهم القيمة. بفضل تفانيهم وعملهم الجاد، استطعنا أن نحقق هذا التقدم ونصنع فرقًا حقيقيًا في حياة الأطفال الذين نتعامل معهم.<br><br></p>

<p>نحن على يقين بأننا قادرون على تحقيق المزيد من النجاحات والإنجازات في المستقبل، ونسعى دائماً لتطوير أنفسنا وتقديم أفضل ما لدينا. معاً، سنواصل السير على هذا الطريق، نحو تحقيق الريادة والتميز في مجال التعليم.<br><br></p>

  <div class="image-row">
    <img src="@/assets/images/blogs/p1.png" class="small-photo-in-row" alt="Image 1">
    <img src="@/assets/images/blogs/p2.png" class="small-photo-in-row" alt="Image 2">
    <img src="@/assets/images/blogs/p3.png" class="small-photo-in-row" alt="Image 3">
    <img src="@/assets/images/blogs/p4.png" class="small-photo-in-row" alt="Image 4">
  </div>
<p>ملتزمون بتعزيز الشراكات والتعاون مع مؤسسات تعليمية رائدة حول العالم لتبادل الخبرات والمعرفة، مما يساهم في رفع مستوى التعليم لدينا وتقديم أفضل الحلول التعليمية الممكنة. نرى أن التعاون الدولي هو مفتاح تحقيق التقدم والابتكار في مجالات التعليم المختلفة.<br><br></p>

<p>كما أننا نركز على التطوير المستمر لفريق العمل، حيث نوفر لهم التدريب اللازم والدعم المستمر لضمان مواكبتهم لأحدث التطورات في مجال التعليم. نؤمن بأن المعلمين هم جوهر العملية التعليمية، وأن استثمارنا في تنمية مهاراتهم هو استثمار في مستقبل أفضل لأطفالنا.<br><br></p>

<p>في المستقبل القريب، نخطط لإطلاق العديد من المبادرات والمشاريع الجديدة التي ستساهم في تعزيز رؤيتنا وتوسيع نطاق تأثيرنا. سنستمر في تطوير المناهج التعليمية وتقديم برامج متكاملة تتماشى مع احتياجات الطلاب وتساعدهم على تحقيق أقصى إمكاناتهم.<br><br></p>

<p>نعمل على دمج التكنولوجيا بشكل أكبر في العملية التعليمية، من خلال استخدام أدوات وتقنيات حديثة مثل الذكاء الاصطناعي والواقع الافتراضي، لتقديم تجارب تعليمية تفاعلية ومبتكرة تجعل التعلم أكثر تشويقًا وإثارة.<br><br></p>

<p>في الختام، نشكر مرة أخرى كل من دعمنا وشاركنا في هذه الرحلة. إن نجاحنا هو نتيجة لجهود جماعية وتفانٍ من قبل الجميع. نعدكم بأننا سنستمر في العمل بجد لتحقيق المزيد من الإنجازات وأن نكون دائمًا في طليعة الابتكار والتفوق في مجال التعليم.<br><br></p>

<p>معاً نصنع مستقبلًا مشرقًا لأطفالنا، معاً نرسم طريق النجاح، ومعاً نحقق الريادة والتميز في عالم التعليم.<br><br></p>


        </div>
      </div>
      <div class="cards-column">
        <BlogCard
          v-for="blog in blogCards"
          :key="blog.id"
          :post="blog"
        />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard
        v-for="blog in blogCards"
        :key="blog.id"
        :post="blog"
      />
    </div>
  </div>
</template>
  
  <script>
    import BlogHead from '@/components/ar/BlogHead.vue';
    import BlogCard from '@/components/en/BlogCard.vue';

  export default {
    name: "BlogThree",
    components: {
      BlogHead,
      BlogCard
    },
    data() {
      return {
        title:"الإعلان عن إنطلاق مجموعة إيديولكسا 360",
        date: "06/09/2024",
        img: require('@/assets/images/blogs/ar-1.png'),
        category: "فعاليات",
        blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: require('@/assets/images/blogs/ar-1.png'),
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: require('@/assets/images/blogs/ar-2.png'),
          link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 5,
          title: 'كيف اتعلم اللغة الانجليزية',
          image: require('@/assets/images/blogs/ar-3.jpg'),
          link: '/ar/blog/learning-english-in-early-ages/',
          Date: '29 مايو ,2024',
          language: 'ُقافة اللغة الانجليزية',
        },
        {
          id: 6,
          title: 'انجاز جديد فى مشوار دكتور النجار',
          image: require('@/assets/images/blogs/ar-4.png'),
          link: '/ar/blog/new-achievement-in-dr-najjars-career',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
        {
          id: 3,
          title: 'الاحتفال الشهرى بالموظف المثالى',
          image: require('@/assets/images/blogs/ar-5.png'),
          link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
      ]
      };
    },

  };
  </script>
  
<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; 
  padding: 30px; 
}
.main-content {
  display: flex;
  flex-direction: row; 
  gap: 50px; 
  direction: rtl;
  padding: 5% 15% 0 15%;
}
.content-column {
  padding: 30px;
  border-radius: 12px; 
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.blog-cards-row {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%; 
  box-sizing: border-box; 
}
h1 {
  color: #808080; 
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  text-align: right;
  margin-bottom: 20px; 
  padding-left: 16%;
}
.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-view h2{
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
 }
.blog-view p {
  margin-bottom: 18px; 
  font-size: 18px;
}
.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}
.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}
.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px; 
  margin: 20px 0;
}
.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px; 
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
  .content-column{
    padding: 20px; 
  }
  .cards-column {
   display: none;
  }
  h1 {
    font-size: 28px;
  }
  .blog-view h3 {
    font-size: 24px;
  }
  .blog-view h4 {
    font-size: 20px;
  }
}
.image-row {
    display: flex;
    justify-content: space-around; 
    align-items: center;
}
.small-photo-in-row {
    width: 200px;
    height: 200px;
    object-fit: cover; 
    margin: 0; 
}

</style>