<template>
  <div class="team-section-en">
    <div class="team-counter-en" v-for="(counter, index) in counters" :key="index">
      <div class="team-number-en">{{ counter.current }}+</div>
      <div class="team-label-en">{{ counter.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OurTeamCounter',
  data() {
    return {
      counters: [
        { label: 'Educational experts', end: 175, current: 0 },
        { label: 'Class supervisors', end: 200, current: 0 },
        { label: 'Customer service experts', end: 130, current: 0 },
        { label: 'Admissions and registration', end: 40, current: 0 },
      ],
    };
  },
  mounted() {
    this.startCounters();
  },
  methods: {
    startCounters() {
      this.counters.forEach(counter => {
        this.animateValue(counter, 0, counter.end, 3000);
      });
    },
    animateValue(counter, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        counter.current = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        } else {
          counter.current = end;
        }
      };
      window.requestAnimationFrame(step);
    },
  },
};
</script>

<style scoped>
.team-section-en {
  background-size: cover;
  background: #fdfdfd;
  border-radius:15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 170px; 
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.3); 
  padding-bottom: 10px;
  gap: 70px;
}
.team-counter-en {
  text-align: center;
  color: #ff8b17;
  margin-right: 30px; 
  margin-left: 30px;
}
.team-number-en {
  font-size: 60px;
  font-weight: bold;
}
.team-label-en {
  font-size: 18px;
}
</style>