<template>
  <header>
     <GalleryBG />
     <PhotosPages />
  </header>
</template>

<script>
import GalleryBG from '../../components/en/GalleryBG.vue'
import PhotosPages from '../../components/ar/PhotosPages.vue'

export default {
  name : 'PhotoGallery',
  components: {
    GalleryBG,
    PhotosPages
  }
}
</script>