<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <h2>ضمن رؤية أكاديمية مونجلش الدولية 2025</h2>

          <p>نسعى دائماً للوصول لأعلى مستويات الجودة من خلال التطوير المتزامن والسريع مع ما يتطلبه سوق العمل العالمي ، والحرص الدائم على الإرتقاء بالعملية التعليمية ويتمثل هذا في حصول الأكاديمية على شهادات الأيزو الدولية للجودة .</p>

          <img src="@/assets/images/blogs/blog16-1.png">
          
          <h2><span style="font-weight: 400;">دور معايير الأيزو فى تعريف العملية التعليمية :&nbsp;</span></h2>

          <p style="line-height: 1.38; text-align: right; margin-top: 12pt; margin-bottom: 0pt;">
            <span style="font-size: 13pt; font-family: 'Simplified Arabic'; color: #000000; background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">أصبحت معايير الأيزو تلقى قبول وانتشار عالمي لمــا تتميز به فـى إدارة&nbsp; مراكز التدريب لكـل مـن القـائمين علـى الإدارة والعـاملين، فقـد جعلهـم: ـ أكثـر فهما لمـا يجـب علـيهم أن يفعلوه، وأكثـر قـدرة علـى تأكيد أن عملهم يفي بالمتطلبات، وأكثر خبرة فى ضبط العمليات عنـدما تكـون النتـائج غيـر متطابقة مع المتطلبـــات، مما يســـاعد فـــى حـــل المشــكلات، يزيـــد فـــرص التواصــل بـــالتركيز علـــى العمليات، ويهيئ البيئة اللازمة لذلك.</span>
          </p>

          <p>
            <span style="font-weight: 400;">وأدركت العديد من الدول الدور الذي يؤديه التدريب فـي دعـم تنافسيتها، حيث اعتبر التدريب كقوة تأخـذ بيـد الفــرد والمجتمع&nbsp; نحــو مكانـة تنافسية أفضــل، ويؤكد ذلــك سرعة إعادة النظر نحو التدريب، إذا واجهـت الدولـة أيـة صعوبات أو تهديدات تخـص مركزهــا التنافسى…. وهــذا يؤكــد أن التدريب هــو العامــل الأساسي الــذى تعــود إليه الـدول، وتهتم بتطـويره، وتنميـة قـدرة التنافس لمؤسسـاتها عنـدما تشـعر بـالقلق علـى مكانتهـــا.</span>
          </p>

          <p>
            <span style="font-weight: 400;">وتـأتي أهميـــة التنافس فـــي مجـــال التدريب فـي: أَّنهـا تمكـن البلـدان مـن مقارنـة مكانتهم باسـتخدام المؤشرات والمراجع الدولية. وقد أعطت هذه المراجع للبلدان معيارا لمعرفـة أيـن توجـد بالمقارنة بالدول الأخري. وهي تساعد الحكومات على فهـم مـواطن قوتهـا وضـعفها في إطار عناصر التنافسية، ، كما يساعدها علـى فهـم الإجراءات التــي يتعــين اتخاذها والتعــديلات التــي ينبعــي إدخالهــا علــى معــايير مــنح الجودة مـــن أجـــل تحقيـــق نتـــائج أفضـــل بشأن التنافس الوطني، وتحســـين أدائهـــا وتحسين قدرتها الأنتاجية داخـل المنظمـة. ويتضـمن ذلك تحسـين الأداء البشـري، للمشـاريع التشغيلية والنوعية.</span>
          </p>

          <p>
            <span style="font-weight: 400;">التدريب الجيد غالباً مــا يكــون مثــار قلــق دولــى فــى كــل الــدول والهيئــات الحكوميــة وتضــعط المجتمعــات المعنيــة علــى المؤسســات التدريبية لتكــون أكثـر كفـاءة لإعداد المتدرب لسـوق العمـل فـى محاولـة لتلبيـة المعـايير الدوليـة وتحسين أدائه، والحصول على الثقة العامـة فـي مراكز التدريب من جميع المسـتويات لـذا فمراكز التعليم المستمر و التدريب تحـاول اســتخدام مـداخل مختلفـة فــى محاولـة الاستجابة لهــذه الاتجاهـات.</span>
          </p>

          <p>
            <span style="font-weight: 400;">قامـت العديـد مـن مراكز التعليم المستمر والتدريب باسـتعارة التخصصـات مـن “المعـايير الدوليـة الأيزو ” مـن عــالم الأعمـال فــى محاولـة التصـدى لهــذه الضـعوط، وبالتــالى فهنـاك إمكانيـة للتطبيق والاستفادة مــن فوائـد الأيزو، ومتطلباته القابلة للتطبيق لإعداد مراكز التدريب والتعليم المستمر، حيث اصبح التوجه الى الأيزو الآن أكثــــر تطـــور فــــى الإدارة عــــن التقــدم الاقتصــادي والصناعى والتقنــى المتســارع وانتقــل إلــى مؤسســات التــدريب والتعليم المستمر حتــى صــارت هذه المراكز بالدول المتقدمة تتسابق فيمـا بينهـا للحصـول عليـها.</span>
          </p>

          <p>
            <span style="font-weight: 400;">الأيزو هو مجموعة المواصفات القياسية المسلسلة المتفق عليها عالميا و التـى ينبغي توافرها فـى مدخلات عمليـــات المنظمــة أو المؤسســـة ذاتهــا والتى تتعلـــق بالتحسين المستمر فـــى المخرجات و كافـة الاعتبارات الأخرى ذات التأثير الإيجـابى علـى الخدمـة أو المنتج النهائي بحيث يحقق رغبات وتوقعات المستهلك الأول وهـو المجتمع بكل مؤسساته وجماعاته وأفراده.</span>
          </p>

          <p>
            <span style="font-weight: 400;">اما فى الدول العربية ودول الخليج فتعاني أغلبية مراكز التدريب من العديد من المشكلات التي تحد من محاولتها للحاق بركب التنافسية العالمية، ويعزى هذا التدهور بدءا بالسياسات الاقتصادية الكلية للمركز، مرورا بمدى توافر وتنوع الخدمات التدريبة ومرافق البنية التكنولوجية الأساسية مما يؤدي لضعف قدرتها التنافسية على المستوي المحلي والدولي.</span>
          </p>

          <p>
            <span style="font-weight: 400;">القليل من المراكز التدريبة المتميزة فى السعودية خصيصا وباقى دول الخليج لتعليم اللغة الانجليزية للاطفال والتى يأتى فى مقدمتها مركز” مونجلش” كأفضل طريقة لتعلم اللغة الانجليزية من خلال استخدام “منهج المعايشة “والمعتمد من “مكتبة الكونجرس الأمريكية”، فأن هذا المركز تؤمن ادراته بمساهمة الجـودة فـى منح الفرصــة للمنظمــة لــدخول الأسواق العالميــة واحــتلال مراكــز قويــة فيهــا مقارنــة بمنافسيها.</span>
          </p>

          <p>
            <span style="font-weight: 400;">حيث اعتمدت ادارته على استراتيحية ثقافــة التنــافس والتى تشجع الجميع ممن يبحثون عن “كيف اتعلم اللغة الانجليزية” علي التطـوير والتجديـد والتحـرك تجـاه التحسـين المسـتمر من خلال خطة علمية مدروسة تقوم علي محوران:</span>
          </p>

          <p>
            <b>المحور الأول</b>
            <span style="font-weight: 400;"> وهو حصول المدربين فيها على شهادات اعتماد المدرب الرقمي طبقا لخطة علمية محددة التوقيت للتعلم عن بعد.</span>
          </p>

          <p>
            <b>المحور الثاني</b>
            <span style="font-weight: 400;"> فهو الحصول على العديد من شهادات الايزو خلال فترة زمنية قياسية، والتى تأتي على رأسها شهادة المواصفة الدولية لنظام إدارة المؤسسات التدريبية&nbsp; ISO 21001&nbsp; وهي من أهم المواصفات التي أصبحت دليلاً على تطبيق أفضل الممارسات التدريبية “للتعلم عن بعد” داخل مونجلش بحيث أصبحت شهادة المطابقة والاعتماد ISO 21001 هي البديل الآمن والعالمي المعترف به دوليا من كل دول العالم حيث انها صدرت من منظمة الايزو العالمية وهى المنظمة الوحيدة التي تضع المعايير المتفق عليها والمعترف بها في جميع دول العالم.</span>
          </p>

          <p>
            <span style="font-weight: 400;">يوفر ISO 21001 أدوات فعالة لمونجلش أكاديمي لتقديم منتجات وخدمات تدريبية لتحسين قدرتها على تلبية متطلبات المتدربين والعملاء الراغبين فى تعليم اللغة الانجليزية للاطفال بهدف تعزيز رضائهم وزيادة مصداقيتها، و انعكاس صورة مميزة للتعلم عن بعد من خلال أكاديمية مونجلش والتى تتطبق أفضل طريقة لتعلم اللغة الانجليزية دوليا.</span>
          </p>

          <p>
            <span style="font-weight: 400;">حصلت مونجلش على شهادة المعيار الدولي ISO 29994:2021&nbsp; والذى يحقق العديد من المزايا لمونجلش وفقًا لأفضل الممارسات فيما يتعلق بالجودة، مما يؤثر بالإيجاب على أداء مونجلش أكاديمي والعاملين فيها أيضا وبالتالي يوفر خدمة أفضل ويزيد من رضاء العملاء لذلك عندما يتم منحها للمراكز المعتمدة وفقًا للمعيار الدولي لنظم التعليم والتدريب عن بعد، سيضيف هذا الإنجاز ثقة كبيرة للعملاء داخل مونجلش على المستوى المحلي والدولي.</span>
          </p>

          <p>
            <span style="font-weight: 400;">واخيرا حصلت مونجلش على&nbsp; شهادة المعيار الدولي ISO 29993:2017 لخدمات التعلم خارج التعليم الرسمي، بما في ذلك جميع أنواع التعلم مدى الحياة (مثل التدريب المهني والتدريب داخل الشركات، سواء بالاستعانة بمصادر خارجية أو داخلية). وتشمل هذه الخدمات التدريبية المقدمة من مونجلش والموجهة للمتدربين أنفسهم، وكذلك إلى الرعاة الذين يحصلون على الخدمات نيابة عن المتدربين. وتكون السمات الرئيسية لهذه الأنواع من الخدمات هي تحديد أهداف التدريب وتقييم الخدمات، والمراجعة المستمرة للمنهج التدريبي، وايضا تنطوي على التفاعل مع المتدرب بوساطة </span>
            <b>تكنولوجيا التعلم عن بعد</b>
            <span style="font-weight: 400;">.</span>
          </p>

          <p>
            <span style="font-weight: 400;">تمثل هذه الشهادات برهانأ على قدرة مركز “</span>
            <b>مونجلش</b>
            <span style="font-weight: 400;">” الأكاديمي على تحقيـق مكاناً متميزاً فـى مجـال الجـودة ومـن ثـَّم إمكانيـة التنـافس علـى المراتـب الأولي فى التطور خاصة فى ظل حدة التنافس التـى تسـود السوق، حيث تواجه مراكز تدريب الغات الأجنبية تحديدا تحـديات كبيـرة أهمهـا كيف يصل المركز لبناء وتعزيز ميزة تنافسية تسمح له باحتلال مركـز تنافسـى قوى، والمحافظة عليه وتعظيمه.</span>
          </p>

          <p>
            <span style="font-weight: 400;">لذا تري إدارة “مونجلش” ان جـــودة الخـدمات والمنتجـــات مـــن أهـــم المتغيـــرات التـــى تســـعى لتحقيقها وذلـك لضـمان تحقيـق رضـا عملائها وولائهم من خلال تخطـــيط وتطـوير البـــرامج التدريبيـــة للمتدريبن وتوثيقهـــا وتحديـــد مواضــيع الدراســـة وأوقاتها لكــل برنــامج، وتقييم الطلبــة،&nbsp; ومـــدى تناســق وصــحة وصلاحية تحديد المستوى وطــرق التقييم الأخرى، والاحتفاظ بسجلات أداء المتدربين فــى المــادة العلمية والتى يمكن الاستفادة منها فى تشخيص أسباب ضعف المتدربين خاصة فئة الأطفال، كذلك الإجراءات التحفيزية، و الطـرق الممكنة لتحسين أدائهم، وايضاً للتطوير من الأجهزة والبرمجيات المستخدمة عن طريق المركز الأكاديمي “مونجليش”.</span>
          </p>
          
          <p>
            <span style="font-weight: 400;">ان تطبيـــــق معايير الايزو فـــــى “مونجلش” يمنحهـــــا الاحترام والتقـــــدير المحلـــــى والاعتراف الدولي ويحسن المركـز التنافسي للمتدربين لديه، بمـا يـؤدى للحفـاظ علـــى زيادة ثقة و إنتماء المتدربين لمونجلش بســـبب تلبيـــة حاجاتهم بشكل مستمرعن طريق الأسلوب العلمى المتفرد فى التدريس “المعايشة”&nbsp; .</span>
          </p>

          <p>
            <span style="font-weight: 400;">ومما سبق يتضح لنا ان ممارسة معايير الايزو تؤدي دور هاماا في بناء ودعم القدرات التنافسية الضرورية لنجاح مراكز التدريب عامه والتعلم عن بعد خاصه فهناك ما يقرب من ٦٥% من التباين في القدرات التنافسية، والتى تتأثر بشمل مباشر بممارسات معايير الأيزو، في حين أن إخفاقات الجودة هي المتغير في أداء الجودة الداخلية والذي يؤثر على القدرات التنافسية.</span>
          </p>
        </div>
      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "مونجلش الدولية تحصل على اعتماد ISO-29993,29994 وفقًا للمعيار الدولي لنظم التعليم والتدريب عن بعد",
      date: "01/14/2024",
      img: require('@/assets/images/blogs/ar-16.png'),
      category: "فعاليات",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: require('@/assets/images/blogs/ar-1.png'),
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: require('@/assets/images/blogs/ar-2.png'),
          link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 5,
          title: 'كيف اتعلم اللغة الانجليزية',
          image: require('@/assets/images/blogs/ar-3.jpg'),
          link: 'ar/blog/learning-english-in-early-ages/',
          Date: '29 مايو ,2024',
          language: 'ُقافة اللغة الانجليزية',
        },
        {
          id: 6,
          title: 'انجاز جديد فى مشوار دكتور النجار',
          image: require('@/assets/images/blogs/ar-4.png'),
          link: '/ar/blog/new-achievement-in-dr-najjars-career',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
        {
          id: 3,
          title: 'الاحتفال الشهرى بالموظف المثالى',
          image: require('@/assets/images/blogs/ar-5.png'),
          link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
      ],
    };
  },

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; 
  padding: 30px; 
}
.main-content {
  display: flex;
  flex-direction: row; 
  gap: 50px; 
  direction: rtl;
  padding: 5% 15% 0 15%;
}
.content-column {
  padding: 30px;
  border-radius: 12px; 
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.blog-cards-row {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%; 
  box-sizing: border-box; 
}
h1 {
  color: #808080; 
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  text-align: right;
  margin-bottom: 20px; 
  padding-left: 16%;
}
.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-view h2{
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
 }
.blog-view p {
  margin-bottom: 18px; 
  font-size: 18px;
}
.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}
.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}
.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px; 
  margin: 20px 0;
}
.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px; 
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
  .content-column{
    padding: 20px; 
  }
  .cards-column {
   display: none;
  }
  h1 {
    font-size: 28px;
  }
  .blog-view h3 {
    font-size: 24px;
  }
  .blog-view h4 {
    font-size: 20px;
  }
}
.image-row {
    display: flex;
    justify-content: space-around; 
    align-items: center;
}
.small-photo-in-row {
    width: 200px;
    height: 200px;
    object-fit: cover; 
    margin: 0; 
}
</style>