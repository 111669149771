<template>
  <div class="job-listings">
    <h2 class="job-listings-title">قائمة الوظائف</h2>
    <div class="job-item" v-for="job in jobs" :key="job.id">
      <div class="job-container1">
        <div class="job-column">
          <div class="job-header">
            <div class="job-title">
              <h2>{{ job.title }}</h2>
            </div>
            <div class="job-type">
              <h2>{{ job.type }}</h2>
            </div>
          </div>
          <div class="job-footer">
            <div class="job-footer-wrapper">
              <div class="job-category"><p>{{ job.category }}</p></div>
              <div class="job-date"><p>{{ job.date }}</p></div>
            </div>
          </div>
        </div>
        <div class="job-column">
          <div class="job-info">
            <p>{{ job.experience }}</p>
            <p :class="getWorkTypeClass(job.workType)">{{ job.workType }}</p>
          </div>
        </div>
        <div class="job-column">
          <button class="details-button" @click="toggleDetails(job.id)">
            تفاصيل الوظيفة
            <svg fill="#ffffff" height="14px" width="14px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path>
              </g>
            </svg>
          </button>
        </div>
        <div class="job-gradient"></div>
      </div>
      <transition name="slide-fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div v-if="isJobActive(job.id)" class="job-container2">
          <div class="job-description" v-html="job.description"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeJob: null,
        jobs: [
          {
            id: 1,
            title: 'معلم اللغة الإنجليزية',
            type: 'دوام كامل',
            experience: 'أكثر من عامين',
            workType: 'عمل من المكتب او المنزل',
            category: 'أكاديمي',
            date: 'نشر من: يومين',
            description: `
              <h2 style="font-size: 1.5rem;">وصف الوظيفة:</h2>
              <p>• شاغر وظيفي لمعلم بدوام كامل لمدة 6 أيام عمل و8 ساعات عمل يوميًا.</p>
              <p>• ساعات العمل من الساعة 2 ظهرًا حتى الساعة 10 مساءً في الصيف ومن الساعة 1 ظهرًا حتى الساعة 9 مساءً في الشتاء.</p>
              <p>• تدريس ما يصل إلى 5 ساعات في اليوم.</p>
              <p>• إجراء دروس تفاعلية على الزوم مركزة على الطلاب لمجموعة من الطلاب.</p>
              <p>• معظم الخطط وعروض البوربوينت متوفرة ولكن يُشجع المعلمون على تصميم دروسهم بالطريقة التي يرونها مناسبة.</p>
              <p>• تقديم تقرير مختصر عن كل جلسة لمشاركتها مع المتعلمين.</p>
              <p>• متابعة السجلات وورقات التقييم وتحديثها يوميًا.</p>
              <p>• إعطاء المتعلمين تغذية راجعة كافية على واجباتهم المنزلية يوميًا.</p>
              <p>• حضور اجتماعات الأهالي/الطلاب مرة واحدة في الشهر ومشاركة التغذية الراجعة الشفوية مع المتعلمين والإجابة على أسئلتهم.</p>
              <p>• إعداد أو تحرير وتصحيح اختبارات المستوى ومشاركة النتائج مع المتعلمين.</p>
              <p>• كتابة تقارير تقييم المستوى المعلوماتية ومشاركتها مع المتعلمين.</p>
              <p>• الإبلاغ عن أي طلاب يحتاجون إلى تدخلات خاصة.</p>
              <p>• حضور والمشاركة بفعالية في جلسات التطوير المهني المستمر مرة في الأسبوع.</p>
              <p>• المشاركة في اجتماعات الطاقم.</p>
              <p>• التواصل بفعالية مع المتعلمين/الأهالي في جميع الجوانب المتعلقة بالتعلم والتدريس.</p>
              <p>• القيام بأنشطة التفكير الذاتي كما هو مكلف.</p>
              <p>• القيام بمهام أخرى متعلقة بالتعلم والتدريس كما هو موكل.</p>
              <p>* يرجى ملاحظة أن الساعات الوحيدة غير المدرجة ضمن ساعات العمل هي: جلسات التطوير المهني المستمر يوم الاثنين ونادي المناظرة يوم السبت.</p>
              <p>• جلسة التطوير المهني المستمر من الساعة 12 ظهرًا حتى 2 ظهرًا يوم الاثنين.</p>
              <p>• نادي المناظرة يُعقد أحيانًا لمدة ساعة يوم السبت (ليس كل سبت) بين الساعة 01:00 و02:00 ظهرًا.</p>
              <p>• في الشتاء، تكون من الساعة 12:00 ظهرًا.</p>

              <h2 style="font-size: 1.5rem;">مزايا أكاديمية مونجلش:</h2>
              <p>• بيئة عمل صحية للغاية حيث يتم احترام ودعم وتحدي ومكافأة المعلمين.</p>
              <p>• نهج وممارسة إدارية على مستوى عالمي.</p>
              <p>• الأكاديمية تتوسع في أسواق دولية جديدة مثل أوروبا وأمريكا الشمالية وجنوب شرق آسيا، مما يوفر فرصًا استثنائية للنمو الوظيفي.</p>
              <p>• الأكاديمية تقدم خدمة عالمية المستوى يفخر المعلم بالمشاركة فيها.</p>
              <p>• لست المعلم الوحيد المسؤول عن مجموعة من المتعلمين، فهناك معلمون مؤهلون آخرون يدعمونك.</p>
              <p>• رضا الطلاب/الأهالي يكاد يكون 100%.</p>
              <p>• زيادات في الأجور تُمنح بانتظام وفقًا لأداء المعلم.</p>
              <p>• المعلمون يتم تقييمهم ومعاملتهم بأقصى درجات الاحترام.</p>
              <p>• يتم توفير التأمين الصحي والاجتماعي.</p>
              <p>• يتم توفير تطوير مهني مستمر استثنائي لمساعدة المعلمين على التطور.</p>
              <p>• 3 أيام إجازة في نهاية الشهر لراحة المعلمين.</p>
              <p>• جدول زمني ثابت.</p>

              <h2 style="font-size: 1.5rem;">متطلبات الوظيفة:</h2>
              <p>• الإتقان بمستوى C1 للغة الإنجليزية شرط أساسي.</p>
              <p>• يُفضل حملة شهادات TKT، TESOL، TEFL، CELTA، و DELTA.</p>
              <p>• مهارات تقديم ممتازة.</p>
              <p>• إنترنت مستقر وحاسوب شخصي أو لابتوب.</p>
              <p>• الخبرة في التدريس عبر الإنترنت تُعد ميزة إضافية.</p><br>
              <a href="/ar/job-registration/" style="background: linear-gradient(45deg, #fe9b4f, #f47e23);color: white;border: none;align-self: center;padding: 10px 20px;cursor: pointer;border-radius: 20px;font-size: 16px;font-weight: 500;font-family: 'DIN Next LT Arabic', sans-serif;display: flex;align-items: center;justify-content: center;width:50%;">
                التقديم للوظيفة
                <svg width="14px" height="14px" style="color:white;" viewBox="0 0 1024 1024" class="icon" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#ffffff" d="M609.408 149.376 277.76 489.6a32 32 0 0 0 0 44.672l331.648 340.352a29.12 29.12 0 0 0 41.728 0 30.592 30.592 0 0 0 0-42.752L339.264 511.936l311.872-319.872a30.592 30.592 0 0 0 0-42.688 29.12 29.12 0 0 0-41.728 0z"/>
                </svg>
              </a>
            `
          },
          {
            id: 2,
            title: 'مشرف الصف',
            type: 'دوام كامل',
            experience: '3 الي 4 سنوات',
            workType: 'عمل من المكتب',
            category: 'أكاديمي',
            date: 'نشر من: يومين',
            description: `
              <h2 style="font-size: 1.5rem;">وصف الوظيفة</h2>
              <p>• الإشراف على الفصول الدراسية.</p>
              <p>• إعداد التقارير بدقة وكفاءة.</p>
              <p>• إنشاء تقارير درجات الطلاب.</p>
              <p>• تصنيف تقارير الطلاب والوثائق الأخرى.</p>
              <p>• طلب مستلزمات جديدة بما في ذلك القرطاسية.</p>
              <p>• الرد على الهاتف.</p>
              <p>• التعامل مع الاستفسارات والشكاوى.</p>
              <p>• تلقي الرسائل للمعلمين.</p>
              <p>• استقبال الأهالي والأشخاص الآخرين الذين يزورون المدرسة.</p>
              <p>• تقديم توجيه مناسب للزوار/الطلاب/الأهالي حول المدرسة.</p>
              <p>• تحديث السجلات الخاصة بالطلاب وأعضاء الهيئة التدريسية.</p>
              
              <h2 style="font-size: 1.5rem;">أكاديمية مونجلش تقدم مجموعة متنوعة من المزايا</h2>
              <p>• بيئة عمل صحية للغاية حيث يتم احترام ودعم وتحدي ومكافأة المعلمين.</p>
              <p>• نهج وممارسة إدارية على مستوى عالمي.</p>
              <p>• الأكاديمية تتوسع في أسواق دولية جديدة مثل أوروبا وأمريكا الشمالية وجنوب شرق آسيا، مما يوفر فرصًا استثنائية للنمو الوظيفي.</p>
              <p>• الأكاديمية تقدم خدمة عالمية المستوى يفخر المعلم بالمشاركة فيها.</p>
              <p>• لست المعلم الوحيد المسؤول عن مجموعة من المتعلمين، فهناك معلمون مؤهلون آخرون يدعمونك.</p>
              <p>• رضا الطلاب/الأهالي يكاد يكون 100%.</p>
              <p>• زيادات في الأجور تُمنح بانتظام وفقًا لأداء المعلم.</p>
              <p>• المعلمون يتم تقييمهم ومعاملتهم بأقصى درجات الاحترام.</p>
              <p>• يتم توفير التأمين الصحي والاجتماعي.</p>
              <p>• يتم توفير تطوير مهني مستمر استثنائي لمساعدة المعلمين على التطور.</p>
              <p>• 3 أيام إجازة في نهاية الشهر لراحة المعلمين.</p>
              <p>• جدول زمني ثابت.</p>
              
              <h2 style="font-size: 1.5rem;">متطلبات الوظيفة</h2>
              <p>• درجة البكالوريوس.</p>
              <p>• خبرة في خدمة العملاء أو مجال ذو صلة تتراوح بين 3 إلى 4 سنوات.</p>
              <p>• إتقان استخدام مايكروسوفت أوفيس (خاصةً وورد وإكسل).</p>
              <p>• إتقان اللغة الإنجليزية شرط أساسي.</p>
              <p>• سرعة طباعة ممتازة باللغتين العربية والإنجليزية.</p>
              <p>• دقة ممتازة في الطباعة.</p>
              <p>• مهارات تواصل وتفاوض متميزة.</p>
              <p>• لسكان الإسكندرية فقط.</p><br>
              <a href="/ar/job-registration/" style="background: linear-gradient(45deg, #fe9b4f, #f47e23);color: white;border: none;align-self: center;padding: 10px 20px;cursor: pointer;border-radius: 20px;font-size: 16px;font-weight: 500;font-family: 'DIN Next LT Arabic', sans-serif;display: flex;align-items: center;justify-content: center;width:50%;">
                التقديم للوظيفة
                <svg width="14px" height="14px" style="color:white;" viewBox="0 0 1024 1024" class="icon" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#ffffff" d="M609.408 149.376 277.76 489.6a32 32 0 0 0 0 44.672l331.648 340.352a29.12 29.12 0 0 0 41.728 0 30.592 30.592 0 0 0 0-42.752L339.264 511.936l311.872-319.872a30.592 30.592 0 0 0 0-42.688 29.12 29.12 0 0 0-41.728 0z"/>
                </svg>
              </a>
            `
          },
          {
            id: 3,
            title: 'موظف مبيعات',
            type: 'دوام كامل',
            experience: '3 الي 4 سنوات',
            workType: 'عمل من المكتب',
            category: 'مبيعات',
            date: 'نشر من: يومين',
            description: `
              <h2 style="font-size: 1.5rem;">وصف الوظيفة</h2>
              <p>• بدء المبيعات مع العملاء المحتملين عبر الهاتف.</p>
              <p>• طرح الأسئلة لجذب اهتمام العملاء والحفاظ على استمرارية المحادثة.</p>
              <p>• الاستماع إلى احتياجات العملاء لتوليد مبيعات متكررة.</p>
              <p>• جمع وتوثيق معلومات العملاء، طرق الدفع، المشتريات، وردود الفعل تجاه المنتجات.</p>
              <p>• مواكبة جميع الخدمات وإبلاغ العملاء بالخدمات الجديدة.</p>
              <p>• الإجابة على أسئلة العملاء حول الخدمات.</p>
              <p>• تحقيق حصص المبيعات.</p>

              <h2 style="font-size: 1.5rem;">أكاديمية مونجلش تقدم مجموعة متنوعة من المزايا</h2>
              <p>• بيئة عمل صحية للغاية حيث يتم احترام ودعم وتحدي ومكافأة المعلمين.</p>
              <p>• نهج وممارسة إدارية على مستوى عالمي.</p>
              <p>• الأكاديمية تتوسع في أسواق دولية جديدة مثل أوروبا وأمريكا الشمالية وجنوب شرق آسيا، مما يوفر فرصًا استثنائية للنمو الوظيفي.</p>
              <p>• الأكاديمية تقدم خدمة عالمية المستوى يفخر المعلم بالمشاركة فيها.</p>
              <p>• لست المعلم الوحيد المسؤول عن مجموعة من المتعلمين، فهناك معلمون مؤهلون آخرون يدعمونك.</p>
              <p>• رضا الطلاب/الأهالي يكاد يكون 100%.</p>
              <p>• زيادات في الأجور تُمنح بانتظام وفقًا لأداء المعلم.</p>
              <p>• المعلمون يتم تقييمهم ومعاملتهم بأقصى درجات الاحترام.</p>
              <p>• يتم توفير التأمين الصحي والاجتماعي.</p>
              <p>• يتم توفير تطوير مهني مستمر استثنائي لمساعدة المعلمين على التطور.</p>
              <p>• 3 أيام إجازة في نهاية الشهر لراحة المعلمين.</p>
              <p>• جدول زمني ثابت.</p>

              <h2 style="font-size: 1.5rem;">متطلبات الوظيفة</h2>
              <p>• مهارات تواصل ممتازة.</p>
              <p>• مهارات التفكير الإبداعي.</p>
              <p>• الصبر والقدرة على جذب العملاء في المحادثة.</p>
              <p>• قدرة بيعية ممتازة.</p>
              <p>• معرفة عملية بالبرمجيات الكمبيوترية ذات الصلة.</p>
              <p>• مهارات تعاملية وحل المشكلات ممتازة.</p>
              <p>• القدرة على التعامل مع الرفض والضغط عند التواصل مع العملاء.</p><br>
              <a href="/ar/job-registration/" style="background: linear-gradient(45deg, #fe9b4f, #f47e23);color: white;border: none;align-self: center;padding: 10px 20px;cursor: pointer;border-radius: 20px;font-size: 16px;font-weight: 500;font-family: 'DIN Next LT Arabic', sans-serif;display: flex;align-items: center;justify-content: center;width:50%;">
                التقديم للوظيفة
                <svg width="14px" height="14px" viewBox="0 0 1024 1024" class="icon" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#ffffff" d="M609.408 149.376 277.76 489.6a32 32 0 0 0 0 44.672l331.648 340.352a29.12 29.12 0 0 0 41.728 0 30.592 30.592 0 0 0 0-42.752L339.264 511.936l311.872-319.872a30.592 30.592 0 0 0 0-42.688 29.12 29.12 0 0 0-41.728 0z"/>
                </svg>
              </a>
            `
          }
        ]
      };
    },
    methods: {
      getWorkTypeClass(workType) {
        return workType === 'عمل من المكتب او المنزل' ? 'work-from-office-home' : 'work-from-office';
      },
      toggleDetails(jobId) {
        if (this.activeJob === jobId) {
          this.activeJob = null; // Collapse if the same job is clicked
        } else {
          this.activeJob = jobId; // Expand the new job
        }
      },
      isJobActive(jobId) {
        return this.activeJob === jobId;
      },
      beforeEnter(el) {
        el.style.height = '0';
        el.style.opacity = '0';
      },
      enter(el, done) {
        el.offsetHeight; // trigger reflow
        el.style.transition = 'height 0.5s ease, opacity 0.5s ease';
        el.style.height = 'auto';
        el.style.opacity = '1';
        done(); // Call done() to signal that the transition has finished
      },
      leave(el, done) {
        el.style.transition = 'height 0.5s ease, opacity 0.5s ease';
        el.style.height = '0';
        el.style.opacity = '0';
        el.addEventListener('transitionend', done); // Call done() after the transition ends
      }
    }
  };
</script>

<style scoped>
  .job-listings {
    padding: 20px;
    font-family: 'DIN Next LT Arabic', sans-serif;
    background-color: white;
  }
  .job-listings h2.job-listings-title {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .job-item {
    background-color: #fff;
    border: 2px solid #DCDCE0;
    width: 75%;
    border-radius: 20px;
    margin-bottom: 20px;
    /* padding: 0 20px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }

  .job-container1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.25rem;
    position: relative;
    border: 2px solid #e5e5e5;
    border-radius: 20px;
    border-left: none;
    border-top: none;
  }

  .job-container1 .job-column:first-child{
    gap: 15px;
  }

  .job-container2{
    display: flex;
    flex-direction: row;
    overflow: hidden; /* Ensure content doesn't overflow during animation */
  }
  
  .job-column {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }
  .job-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;
  }
  .job-title{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .job-title h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #001b48;
  }
  .job-type{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .job-type h2 {
    font-size: 14px;
    color: #fff;
    background-color: #165E84;
    padding: 10px 40px;
    border-radius: 20px;
    text-align: center;
    /* width: 40%; */
  }
  .job-footer {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #666;
    margin-left: 15rem;
  }
  .job-footer p {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    font-size: medium;
  }
  .job-footer .job-footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap:5px;
  }
  .job-date {
    color: green;
    font-size: 14px;
  }
  .job-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .job-info p {
    font-size: 14px;
    text-align: center;
  }
  .job-info p:first-child{
    color: #808080;
  }
  .details-button {
    background: linear-gradient(45deg, #fe9b4f, #f47e23);
    color: white;
    border: none;
    align-self: center;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'DIN Next LT Arabic', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65%;
  }
  .details-button svg {
    margin-right: 10px;
  }
  .job-gradient {
    width: 2%;
    background: linear-gradient(171deg, #2C80AC 28%, #165E84 71%);
    border-radius: 0 20px 20px 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .work-from-office-home {
    color: #f47e23;
  }
  .work-from-office {
    color: #165E84;
  }
  .job-description {
    position: relative;
    padding: 10px;
    margin: 0 3rem;
    border-radius: 0 0 20px 20px;
    font-size: 14px;
    color: #333;
  }
  .job-description p {
    margin: 0;
    line-height: 1.6;
  }

  /* collapse job details animation */
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.5s ease-in;
  }

  .slide-fade-enter, 
  .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
    height: 0;
    opacity: 0;
  }

  .slide-fade-enter-to,
  .slide-fade-leave {
    height: auto;
    opacity: 1;
  }
  /* end collapse job details animation */

  @media (min-width: 320px) and (max-width: 479px) {
    .job-listings h2{
      text-align: center;
      font-size: 2.5rem;
      font-weight: 500;
      font-family: "DIN Next LT Arabic", Sans-serif;
      margin-bottom: 20px;
    }
    .job-item{
      width: 100%;
    }
    .job-container1{
      flex-direction: column;
      padding: 0 1.25rem;
    }

    .job-container1 .job-column:first-child{
      gap: 0;
    }

    .job-container1 .job-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      gap: 15px;
    }

    .job-gradient {
      width: auto;
      height: 5%;
      background: linear-gradient(171deg, #2C80AC 28%, #165E84 71%);
      border-radius: 0 20px 20px 0;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }
  
    .job-header{
      flex-direction: column;
      gap: 20px;
    }
    .job-footer{
      justify-content: center;
      margin: auto;
    }
    .job-footer p {
      font-size: large;
    }
    .job-footer .job-footer-wrapper{
      flex-direction: column;
      align-items: center;
    }
    .job-type h2{
      font-size: 1.2rem;
      width: 20rem;
    }

    .job-info p{
      font-size: large;
    }

    .details-button{
      width: 100%;
    }

    .job-description{
      margin: 0;
    }
  }
</style>
