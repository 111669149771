<template>
    <div v-if="showPopup" class="popup-overlay" @click="closePopup">
      <div class="popup-content" @click.stop>
        <button class="close-button" @click="closePopup">&times;</button>
        <div class="popup-body">
          <div class="popup-text">
            <h2>سجل الآن!</h2>
            <h1>واستمتع بخصم 35%</h1>
            <div class="pop-card">
               <p>على باقات المعايشة "6 أشهر"</p>
            </div>
            <p class="pop-desc">واحصل علي حصة تجريبية مجانية ! لفترة محدودة</p>
            <form @submit.prevent="register" class="pop-form">
              <div class="inline-group">
                <div class="form-pop">
                  <label for="fullName" class="pop-label">الاسم الكامل</label>
                  <input type="text" class="pop-input" id="fullName" placeholder="الاسم الكامل" v-model="fullName" required />
                </div>
                <div class="form-pop">
                  <label for="phoneNumber" class="pop-label">رقم الهاتف</label>
                  <input type="text" class="pop-input" id="phoneNumber" placeholder="رقم الهاتف" v-model="phoneNumber" required />
                </div>
              </div>
              <div class="form-pop">
                <label for="email" class="pop-label">البريد الإلكتروني</label>
                <input type="email" class="pop-input" id="email" placeholder="example@email.com" v-model="email" required />
              </div>
              <button type="submit" class="pop-btn">سجل</button>
            </form>
            <p class="pop-bottom">يتم الاحتفاظ بالتفاصيل الشخصية من قبل أكاديمية مونجلش الدولية ولن يتم استخدامها إلا فيما يتعلق بطلبك. يرجى قراءة <a href="/ar/terms-and-conditions">شروط الاستخدام</a> لمزيد من المعلومات.</p>
          </div>
          <div class="popup-image "></div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      showPopup: false,
      fullName: '',
      email: '',
      phoneNumber: ''
    };
  },
  mounted() {
    const popupShown = sessionStorage.getItem('popupShown');
    if (!popupShown) {
      this.showPopup = true;
    }
  },
  methods: {
    closePopup() {
      this.showPopup = false;
      sessionStorage.setItem('popupShown', 'true');
    },
    register() {
      this.closePopup();
      const payload = {
        name: this.fullName,
        email: this.email,
        phone: this.phoneNumber,
      };
      let headers = {'Content-Type': 'application/json'};
      axios.post(`/api/free-session-forms`, payload, {headers})
          .then(response => {
            const data = response.data;
            if (response.status === 201) {
              console.log('Form submitted successfully:', data);
              this.formSubmitted = true;
            } else {
              console.error('Error in response:', data.message || 'Unknown error');
            }
          })
          .catch(error => {
            console.error('Error submitting form:', error);
          });
    },
  }
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  direction: rtl;
  font-family: 'DIN Next LT Arabic-n', sans-serif;
  overflow: auto;
}
.popup-content {
  background: white;
  display: flex;
  max-width: 900px;
  width: 100%;
  max-height:fit-content;
  border-radius: 20px;
  position: relative;
}
.popup-body {
  display: flex;
  width: 100%;
}
.popup-text {
  flex: 1;
  padding: 30px;
  text-align: start;
  line-height: 1.5; 
  margin-top: 10px;
}
.popup-text h2 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 10px; 
}
.popup-text h1 {
  font-size: 2em;
  font-weight: 700;
  line-height: 90%;
  color: #ff6f00;
  margin-bottom: 20px; 
}
.pop-card {
  background: linear-gradient(45deg, #ff9442, #ff6f00);
  color: white;
  border-radius: 25px 0 0 25px;
  margin-inline-start: -30px;
  padding-inline-start: 30px;
  margin-top: 10px;
  padding-top: 8px;
  padding-bottom: 16px;
}
.pop-card p {
  line-height: 90%;
  font-size: 1.75em;
  font-weight: 500;
}

.pop-desc {
  line-height: 1.5;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.popup-image {
  flex: 1;
  background-image: url('@/assets/images/pop.png');
  background-size: cover;
  background-position: center;
  border-radius: 20px 0 0 20px;
  padding: 3.75em; 
  width: 100%;
}
.close-button {
  position: absolute;
  top: -1.25rem; 
  right: 0.625em; 
  background: none;
  border: none;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-size: 3.75em; 
  font-weight: 500;
  cursor: pointer;
}

.pop-form {
  text-align: right;
  direction: rtl;
}

.pop-input {
  width: calc(100% - 1.25em);
  padding: 0.625em;
  margin: 0;
  border-radius: 10px;
  background-color: #f3f7ff;
  border: none;
}

form .form-pop {
  margin-bottom: 20px; 
}

.pop-label {
  display: block;
  margin-right: 0.75em;
  text-align: right;
  font-weight: 500;
  color: #bababa;
  margin-top: 0; /* Consistent spacing */
  margin-bottom: 5px; /* Added spacing below label */
}

.pop-bottom {
  color: #979797;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  margin-top: 20px; /* Consistent margin */
}

.pop-bottom a {
  color: #165e84;
  text-decoration: underline; 
}
.inline-group {
  display: flex;
  justify-content: space-between;
}
.inline-group .form-group {
  width: 48%;
}
.pop-btn {
  background: linear-gradient(45deg, #ff9442, #ff6f00);
  color: white;
  border: none;
  padding: 0.25rem 0.25rem; 
  cursor: pointer;
  border-radius: 20px;
  width: 60%;
  margin-inline: 20%;
  font-size: 1.5em; 
  font-family: 'DIN Next LT Arabic', sans-serif;
}
.pop-bottom {
  color: #979797;
}
@media (max-width: 1000px) {
  .popup-overlay {
    align-items: flex-start;
  }
  .popup-content {
    max-width: 90%;
    margin-block: 50px;
  }
  .popup-text {
    padding: 1.5em;
  }
  .popup-text h2 {
    font-size: 1.5em;
  }
  .popup-text h1 {
    font-size: 2.25em;
  }

  .pop-card {
    margin-inline-start: -1.5em;
  }
  .pop-card p {
    font-size: 1.25em;
  }
  .pop-desc {
    font-size: 1.25em;
  }
  .popup-image {
    display: none;
  }
  .close-button {
    top: -35px;
    right: 10px;
  }
  .pop-input {
    width: calc(100% - 1em);
  }
  .pop-btn {
    padding: 0.75em 1em;
    font-size: 1.25em; 
  }
}
</style>
