<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>يُعد اللعب جزءًا أساسيًا من حياة الأطفال، فهو يُساعدهم على النمو والتعلم وتطوير مهاراتهم الاجتماعية والعاطفية. ولذلك، من المهم اختيار الألعاب والأنشطة التي تعزز السلوك والتفاعل الإيجابي لدى الأطفال.<br><br></p>

          <p>فيما يلي بعض الألعاب والأنشطة التي يمكن أن تساعد في تعزيز السلوك والتفاعل الإيجابي لدى الأطفال:<br><br></p>

          <p><strong>ألعاب التعاون:</strong> مثل لعبة بناء القلعة أو لعبة كرة القدم، حيث تتطلب هذه الألعاب من الأطفال العمل معًا لتحقيق هدف مشترك. <br><br></p>

          <p><strong>ألعاب حل المشكلات:</strong> مثل لعبة البازل أو لعبة الألغاز، حيث تتطلب هذه الألعاب من الأطفال التفكير والإبداع والتعاون لحل مشكلة ما.<br><br></p>

          <p><strong>ألعاب التقليد:</strong> مثل لعبة تقليد الحيوانات أو لعبة تقليد الشخصيات الكرتونية، حيث تتطلب هذه الألعاب من الأطفال الانتباه والتقليد والتواصل الاجتماعي.<br><br></p>

          <p><strong>ألعاب التخيل:</strong> مثل لعبة بيت الدمى أو لعبة لعبة السيارات، حيث تتطلب هذه الألعاب من الأطفال استخدام خيالهم والإبداع والتفاعل الاجتماعي.<br><br></p>

          <p><strong>الأنشطة الفنية:</strong> مثل الرسم والتلوين والموسيقى والرقص، حيث تتطلب هذه الأنشطة من الأطفال التعبير عن أنفسهم والإبداع والتفاعل الاجتماعي.<br><br></p>

          <p>من المهم أن يختار الأهل الألعاب والأنشطة التي تناسب عمر واهتمامات أطفالهم. كما يجب أن يحرصوا على التفاعل مع أطفالهم أثناء اللعب أو ممارسة النشاط، وذلك لتعزيز التفاعل الاجتماعي والتواصل بين الأهل والأطفال. <br><br></p>

          <p>بالإضافة إلى الألعاب والأنشطة المذكورة أعلاه، هناك العديد من الطرق الأخرى التي يمكن للأهل من خلالها تعزيز السلوك والتفاعل الإيجابي لدى أطفالهم. على سبيل المثال، يمكن للأهل:<br><br></p>

          <ul>
            <li>
              <strong>توفير مساحة آمنة وممتعة للأطفال للعب.</strong>
            </li>
            <li>
              <strong>اللعب مع أطفالهم بانتظام.</strong>
            </li>
            <li>
              <strong>الثناء على السلوك الإيجابي.</strong>
            </li>
            <li>
              <strong>وضع حدود واضحة وتطبيقها بشكل ثابت.</strong>
            </li>
          </ul>
          <br><br>

          <p><strong>من خلال توفير بيئة داعمة وإيجابية، يمكن للأهل تعزيز السلوك والتفاعل الإيجابي لدى أطفالهم، ومساعدة أطفالهم على النمو والتطور بشكل سليم</strong> <br><br></p>

          <p><strong>اتمنى أن يكون المقال نال أعجبكم! لا تترددوا في مشاركة هذا المقال مع الأصدقاء والأهل. حتى المرة القادمة!&nbsp;</strong> <br><br></p>

          <div class="image-row">
            <img src="@/assets/images/blogs/p9.jpg" class="small-photo-in-row" alt="Image 1">
            <img src="@/assets/images/blogs/p10.jpg" class="small-photo-in-row" alt="Image 2">
            <img src="@/assets/images/blogs/p11.jpg" class="small-photo-in-row" alt="Image 3">
          </div>
        </div>

      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "الألعاب والأنشطة التي تعزز السلوك والتفاعل الإيجابي",
      date: "01/24/2024",
      img: require('@/assets/images/blogs/blog5.png'),
      category: "أنشطة وألعاب",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: require('@/assets/images/blogs/ar-1.png'),
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: require('@/assets/images/blogs/ar-2.png'),
          link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 5,
          title: 'كيف اتعلم اللغة الانجليزية',
          image: require('@/assets/images/blogs/ar-3.jpg'),
          link: 'ar/blog/learning-english-in-early-ages/',
          Date: '29 مايو ,2024',
          language: 'ُقافة اللغة الانجليزية',
        },
        {
          id: 6,
          title: 'انجاز جديد فى مشوار دكتور النجار',
          image: require('@/assets/images/blogs/ar-4.png'),
          link: '/ar/blog/new-achievement-in-dr-najjars-career',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
        {
          id: 3,
          title: 'الاحتفال الشهرى بالموظف المثالى',
          image: require('@/assets/images/blogs/ar-5.png'),
          link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
      ],
    };
  },

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; 
  padding: 30px; 
}
.main-content {
  display: flex;
  flex-direction: row; 
  gap: 50px; 
  direction: rtl;
  padding: 5% 15% 0 15%;
}
.content-column {
  padding: 30px;
  border-radius: 12px; 
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.blog-cards-row {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%; 
  box-sizing: border-box; 
}
h1 {
  color: #808080; 
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  text-align: right;
  margin-bottom: 20px; 
  padding-left: 16%;
}
.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-view h2{
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
 }
.blog-view p {
  margin-bottom: 18px; 
  font-size: 18px;
}
.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}
.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}
.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px; 
  margin: 20px 0;
}
.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px; 
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
  .content-column{
    padding: 20px; 
  }
  .cards-column {
   display: none;
  }
  h1 {
    font-size: 28px;
  }
  .blog-view h3 {
    font-size: 24px;
  }
  .blog-view h4 {
    font-size: 20px;
  }
}
.image-row {
    display: flex;
    justify-content: space-around; 
    align-items: center;
}
.small-photo-in-row {
    width: 200px;
    height: 200px;
    object-fit: cover; 
    margin: 0; 
}
</style>