<template>
  <div class="monglish-reasons">
    <div class="reasons-text">
      <h1>تميزكم في تعلم الإنجليزية يبدأ هنا</h1>
      <p>اكتشفوا التعليم الإلكتروني المتطور في أكاديمية مونجلش الدولية، حيث نقدم تجربة تدريبية غنية ومتعددة الوسائط عبر الأجهزة الذكية، تتيح تفاعلاً ديناميكيًا مع المدربين والزملاء لتطوير المهارات بمرونة، مع الوصول إلى دوراتنا في أي وقت ومن أي مكان، وفرصة التعلم مع متخصصين عالميين، لتجربة تعليمية فريدة ومتميزة ١٠٠٪ عبر الإنترنت.</p>
    </div>

    <div class="reasons-card">
      <h3>ساعد و طور طفلك !</h3>
      <p>ليصل لما يطمح له في مستقبله</p>
    </div>
  </div>

  <div class="vision-sec3">
    <h1>المعايشة التامة</h1>
    <div class="vision-container">
      <iframe 
        src="https://player.vimeo.com/video/818262933" 
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>
  </div>

  <HomeReasons />

  <HomeCounter />

  <PackagesAr />

  <div class="vision-sec3">
    <h1>المعايشة التامة</h1>
    <div class="vision-container">
      <iframe 
        src="https://player.vimeo.com/video/818262887" 
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>
  </div>
</template>

<script>
import PackagesAr from '../../views/ar/PackagesPage.vue';
import HomeCounter from '../../components/ar/HomeCounter.vue';
import HomeReasons from '../../components/ar/HomeReasons.vue';

export default {
  name: 'HomePage',
  components: {
    PackagesAr,
    HomeCounter,
    HomeReasons
  },
}
</script>

<style scoped>
.monglish-reasons {
  background-image: url('@/assets/images/reason_bg.jpg');
  background-size: cover;
  background-position: center;
  height: 110vh;
  margin-top: -85px;
  padding: 12% 1% 1% 1%;
  font-family: 'DIN Next LT Arabic';
  box-sizing: border-box;
}
.reasons-text {
  color: white;
  text-align: right;
  padding: 1em;
  padding-left: 50%;
}
.reasons-text h1 {
  margin-top: 150px;
  font-size: 3em;
  font-weight: 700;
  font-family: 'DIN Next LT Arabic-n';
}
.reasons-text p {
  font-size: 1.3em;
}
.reasons-card {
  background-color: white;
  margin-top: 2%;
  padding: 1em 3em; 
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  text-align: right;
  width: 20%; 
}
.reasons-card h3 {
  color: #535353;
  font-size: 1.8em;
}
.reasons-card p {
  color: #535353;
  font-size: 1.3em;
}
@media (max-width: 768px) {
  .monglish-reasons {
    height: auto;
  }
  .reasons-text{
    text-align: center;
    max-width: 100%;
    padding-left: 0;
    padding-top: 12%;
  }
  .reasons-text h1 {
    margin-top: 20px;
    font-size: 2em;
  }
  .reasons-text p{
    font-size: 1em;
    line-height: 1.5em;
  }
  .reasons-card{
    width: 100%;
    margin: 10px auto;
    padding: 20px;
    text-align: center;
  }
  .reasons-card h3{
    font-size: 1.5em;
  }
  .reasons-card p{
    font-size: 1em;
  }
}
@media (max-width: 480px) {
  .reasons-text h1{
    font-size: 1.5em;
  }
  .reasons-text p{
    font-size: 0.9em;
  }
  .reasons-card h3{
    font-size: 1.3em;
  }
  .reasons-card p{
    font-size: 0.9em;
  }
}

.vision-sec3 {
  background-color: #fff;
  text-align: center;
  color: #165e84;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}
.vision-sec3 h1 {
  font-size: 2.8em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  margin: 1em;
}
.vision-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 45%;
  margin-bottom: 1em;
  border-radius: 2em;
}
.vision-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2em;
}
@media (max-width: 1200px) {
  .vision-sec3 h1 {
    font-size: 2.2em;
  }
  .vision-container {
    width: 90%;
    padding-bottom: 50%;
  }
}
@media (max-width: 768px) {
  .vision-sec3 h1 {
    font-size: 1.8em;
  }
  .vision-container {
    width: 95%;
    padding-bottom: 56.25%; 
  }
}
@media (max-width: 480px) {
  .vision-sec3 h1 {
    font-size: 1.5em;
  }
  .vision-container {
    width: 100%;
    padding-bottom: 60%;
  }
}
</style>