export const routeMappings = {
  // English to Arabic mappings
  '/en/': '/',
  '/en/our-team': '/ar/our-team',
  '/en/courses': '/ar/courses',
  '/en/kids-courses': '/ar/kids-courses',
  '/en/adult-courses': '/ar/adult-courses',
  '/en/about-us': '/ar/about-us',
  '/en/join-us': '/ar/join-us',
  '/en/blog': '/ar/blog',
  '/en/contact': '/ar/contact',
  '/en/register': '/ar/register',
  '/en/login': '/ar/login',
  '/en/vision-mission': '/ar/vision-mission',
  '/en/study-abroad': '/ar/study-abroad',
  '/en/faq': '/ar/faq',
  '/en/accredations': '/ar/accredations',
  '/en/packages': '/ar/packages',
  '/en/cart': '/ar/cart',
  '/en/checkout': '/ar/checkout',
  '/en/monglish-immerse': '/ar/monglish-immerse',
  '/en/monglish-promise': '/ar/monglish-promise',
  '/en/monglish-reasons': '/ar/monglish-reasons',
  '/en/certifications': '/ar/certifications',
  '/en/our-founder': '/ar/our-founder',
  '/en/youtube-videos': '/ar/youtube-videos',
  '/en/photo-gallery': '/ar/photo-gallery',

  // Arabic to English mappings
  '/': '/en/',
  '/ar/our-team': '/en/our-team',
  '/ar/courses': '/en/courses',
  '/ar/kids-courses': '/en/kids-courses',
  '/ar/adult-courses': '/en/adult-courses',
  '/ar/about-us': '/en/about-us',
  '/ar/join-us': '/en/join-us',
  '/ar/blog': '/en/blog',
  '/ar/contact': '/en/contact',
  '/ar/register': '/en/register',
  '/ar/login': '/en/login',
  '/ar/vision-mission': '/en/vision-mission',
  '/ar/study-abroad': '/en/study-abroad',
  '/ar/faq': '/en/faq',
  '/ar/accredations': '/en/accredations',
  '/ar/packages': '/en/packages',
  '/ar/cart': '/en/cart',
  '/ar/checkout': '/en/checkout',
  '/ar/monglish-immerse': '/en/monglish-immerse',
  '/ar/monglish-promise': '/en/monglish-promise',
  '/ar/monglish-reasons': '/en/monglish-reasons',
  '/ar/certifications': '/en/certifications',
  '/ar/our-founder': '/en/our-founder',
  '/ar/youtube-videos': '/en/youtube-videos',
  '/ar/photo-gallery': '/en/photo-gallery',
};
