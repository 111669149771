<template>
  <main class="section1">
    <div class="section1-content">
      <div class="text-content">
        <div class="welcome flex">
          <div>مرحبا بكم في الموقع الرسمي لشبكة مونجلش العالمية</div>
          <div>Monglish Global Network</div>
        </div>
        <h1 class="title">تعلم الإنجليزية من خلال منهج المعايشة الفريد الخاص بنا</h1>
        <p class="subtitle">أكاديمية مونجلش الدولية (المملكة المتحدة والولايات المتحدة الأمريكية ومصر)</p>
        <ul class="circle-list">
          <li>
            <span class="check-mark">
              <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3465_10579)">
                <path d="M12 0.5C9.62663 0.5 7.30655 1.20379 5.33316 2.52236C3.35977 3.84094 1.8217 5.71509 0.913451 7.9078C0.00519943 10.1005 -0.232441 12.5133 0.230582 14.8411C0.693605 17.1689 1.83649 19.3071 3.51472 20.9853C5.19295 22.6635 7.33115 23.8064 9.65892 24.2694C11.9867 24.7324 14.3995 24.4948 16.5922 23.5866C18.7849 22.6783 20.6591 21.1402 21.9776 19.1668C23.2962 17.1935 24 14.8734 24 12.5C24 9.3174 22.7357 6.26515 20.4853 4.01472C18.2348 1.76428 15.1826 0.5 12 0.5V0.5ZM18.82 9.605L11.29 18.145C11.1685 18.2819 11.0211 18.3935 10.8564 18.4733C10.6916 18.5531 10.5128 18.5995 10.33 18.61H10.24C9.89893 18.6121 9.56926 18.4874 9.31501 18.26L5.28501 14.685C5.00785 14.439 4.83977 14.093 4.81774 13.7231C4.7957 13.3532 4.92152 12.9897 5.16751 12.7125C5.41349 12.4353 5.7595 12.2673 6.12942 12.2452C6.49934 12.2232 6.86285 12.349 7.14001 12.595L10.14 15.24L16.745 7.74C16.9903 7.46219 17.336 7.2932 17.7059 7.27023C18.0758 7.24726 18.4397 7.37217 18.7175 7.6175C18.9953 7.86282 19.1643 8.20846 19.1873 8.57838C19.2102 8.9483 19.0853 9.31219 18.84 9.59L18.82 9.605Z" fill="white"/>
                </g>
              </svg>
            </span>
            معتمدون دولياً
          </li>
          <li>
          <span class="check-mark">
              <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3465_10579)">
                <path d="M12 0.5C9.62663 0.5 7.30655 1.20379 5.33316 2.52236C3.35977 3.84094 1.8217 5.71509 0.913451 7.9078C0.00519943 10.1005 -0.232441 12.5133 0.230582 14.8411C0.693605 17.1689 1.83649 19.3071 3.51472 20.9853C5.19295 22.6635 7.33115 23.8064 9.65892 24.2694C11.9867 24.7324 14.3995 24.4948 16.5922 23.5866C18.7849 22.6783 20.6591 21.1402 21.9776 19.1668C23.2962 17.1935 24 14.8734 24 12.5C24 9.3174 22.7357 6.26515 20.4853 4.01472C18.2348 1.76428 15.1826 0.5 12 0.5V0.5ZM18.82 9.605L11.29 18.145C11.1685 18.2819 11.0211 18.3935 10.8564 18.4733C10.6916 18.5531 10.5128 18.5995 10.33 18.61H10.24C9.89893 18.6121 9.56926 18.4874 9.31501 18.26L5.28501 14.685C5.00785 14.439 4.83977 14.093 4.81774 13.7231C4.7957 13.3532 4.92152 12.9897 5.16751 12.7125C5.41349 12.4353 5.7595 12.2673 6.12942 12.2452C6.49934 12.2232 6.86285 12.349 7.14001 12.595L10.14 15.24L16.745 7.74C16.9903 7.46219 17.336 7.2932 17.7059 7.27023C18.0758 7.24726 18.4397 7.37217 18.7175 7.6175C18.9953 7.86282 19.1643 8.20846 19.1873 8.57838C19.2102 8.9483 19.0853 9.31219 18.84 9.59L18.82 9.605Z" fill="white"/>
                </g>
              </svg>
            </span>
            وسائل التعلم التفاعلية
          </li>
          <li>
          <span class="check-mark">
              <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3465_10579)">
                <path d="M12 0.5C9.62663 0.5 7.30655 1.20379 5.33316 2.52236C3.35977 3.84094 1.8217 5.71509 0.913451 7.9078C0.00519943 10.1005 -0.232441 12.5133 0.230582 14.8411C0.693605 17.1689 1.83649 19.3071 3.51472 20.9853C5.19295 22.6635 7.33115 23.8064 9.65892 24.2694C11.9867 24.7324 14.3995 24.4948 16.5922 23.5866C18.7849 22.6783 20.6591 21.1402 21.9776 19.1668C23.2962 17.1935 24 14.8734 24 12.5C24 9.3174 22.7357 6.26515 20.4853 4.01472C18.2348 1.76428 15.1826 0.5 12 0.5V0.5ZM18.82 9.605L11.29 18.145C11.1685 18.2819 11.0211 18.3935 10.8564 18.4733C10.6916 18.5531 10.5128 18.5995 10.33 18.61H10.24C9.89893 18.6121 9.56926 18.4874 9.31501 18.26L5.28501 14.685C5.00785 14.439 4.83977 14.093 4.81774 13.7231C4.7957 13.3532 4.92152 12.9897 5.16751 12.7125C5.41349 12.4353 5.7595 12.2673 6.12942 12.2452C6.49934 12.2232 6.86285 12.349 7.14001 12.595L10.14 15.24L16.745 7.74C16.9903 7.46219 17.336 7.2932 17.7059 7.27023C18.0758 7.24726 18.4397 7.37217 18.7175 7.6175C18.9953 7.86282 19.1643 8.20846 19.1873 8.57838C19.2102 8.9483 19.0853 9.31219 18.84 9.59L18.82 9.605Z" fill="white"/>
                </g>
              </svg>
            </span>
            دعم طوال الأسبوع
          </li>
        </ul>
        <div class="one-line from-row-to-column">
          <MainButton @click="goToPackages">أشترك معنا الآن</MainButton>
          <a href="/ar/accredations">
            <button class="info-btn one-line">
              المزيد من المعلومات
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M5 12H19M5 12L11 6M5 12L11 18" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </g>
              </svg>
            </button>
          </a>
        </div>

        <h1 class="title-secondary mt-3">اعتماداتنا و عضوياتنا</h1>
        <div class="accreditations-wrapper mt-2">
          <Carousel
          ref="carousel"
          :itemsToShow="7"
          :wrapAround="true"
          :mouseDrag="true"
          :autoplay="3000"
          :transition="500"
          :gap="20" 
          >
            <Slide
              v-for="(accreditationImage, index) in accreditationImages"
              :key="index"
              class="carousel-slide" 
            >
              <img :src="accreditationImage.img" alt="Reason Image" class="reason-image" />
            </Slide>
          </Carousel>
        </div>
      </div>

      <div class="image-content hidden lg:block">
        <img src="@/assets/images/Desktop.png" alt="Home Section 1 Image" />
      </div>
    </div>

    <div class="text-center mt-8">
      <h1 class="title-2">شُركاء النجاح</h1>
      <div class="companies">
        <img src="@/assets/images/companies/zoom.png">
        <img src="@/assets/images/companies/Rectangle-6052-1.png">
        <img src="@/assets/images/companies/Britishey.png">
        <img src="@/assets/images/companies/Rectangle-6053-1.png">
        <img src="@/assets/images/companies/Rectangle-6054.png">
        <img src="@/assets/images/companies/micro.png">
        <img src="@/assets/images/companies/Rectangle-6056.png">
        <img src="@/assets/images/companies/Rectangle-6057-1.png">
      </div>
    </div>
  </main>
</template>


<script>
import MainButton from '../Elements/MainButton.vue';
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'ViewOne',
  components: {
    Carousel,
    Slide,
    MainButton
  },
  data() {
    return {
      accreditationImages: [
        { img: require('@/assets/images/accreditations/Rectangle-6114.png') },
        { img: require('@/assets/images/accreditations/Rectangle-6115.png') },
        { img: require('@/assets/images/accreditations/Rectangle-6116.png') },
        { img: require('@/assets/images/accreditations/Rectangle-6117.png') },
        { img: require('@/assets/images/accreditations/Rectangle-6118.png') },
        { img: require('@/assets/images/accreditations/Rectangle-6122.png') },
        { img: require('@/assets/images/accreditations/Rectangle-6123.png') },
        { img: require('@/assets/images/accreditations/par2b.png') },
        { img: require('@/assets/images/accreditations/Rectangle-29917.png') },
        { img: require('@/assets/images/accreditations/Logos.png')},
      ],
      currentStartIndex: 0,
      isTransitioning: false,
      transitionTime: 500,
    };
  },
  computed: {
    visibleAccreditations() {
      return [...this.accreditationImages, ...this.accreditationImages];
    },
    carouselStyle() {
      return {
        transform: `translateX(+${this.currentStartIndex * 95}px)`,
        transition: this.isTransitioning ? `transform ${this.transitionTime}ms ease-in-out` : 'none',
      };
    },
  },
  mounted() {
    setInterval(() => {
      this.moveCarousel();
    }, 3000);
  },
  methods: {
    moveCarousel() {
      this.isTransitioning = true;
      this.currentStartIndex += 1;
    },
    handleTransitionEnd() {
      this.isTransitioning = false;
      this.currentStartIndex = 0;

      const firstImage = this.accreditationImages.shift();
      this.accreditationImages.push(firstImage);
    },
    goToPackages(){
      this.$router.push({ name: 'PackagesViewAr' });
    }
  },
};
</script>


<style src="@/assets/styles/ar/ViewOne.css" scoped></style>