<template>
  <div class="section">
    <div class="home-swiper-text">
      <h3>We illuminate the path of global education</h3>
      <h1>With lights of knowledge from every corner of the world</h1>
    </div>
    <div class="carousel-cont">
      <Carousel
          ref="carousel"
          :itemsToShow="itemsToShow"
          :wrapAround="true"
          :mouseDrag="true"
          :autoplay="3000"
          :transition="500"
      >
        <Slide v-for="(location, index) in locations" :key="index">
          <div class="location-info">
            <img :src="location.img" alt="Location Image" class="location-image" />
            <h2>{{ location.title }}</h2>
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      locations: [
        {
          img: require('@/assets/images/Swiper/1.jpg'),
          title: 'Headquarters of the Kingdom of Saudi Arabia',
          location: 'Riyadh, الرياض'
        },
        {
          img: require('@/assets/images/Swiper/2.jpg'),
          title: 'Egypt Headquarter - Alexandria',
          location: 'Alexandria, الإسكندرية'
        },
        {
          img: require('@/assets/images/Swiper/3.jpg'),
          title: 'USA headquarter - New York',
          location: 'New York, نيويورك'
        },
        {
          img: require('@/assets/images/Swiper/4.jpg'),
          title: 'UK Headquarter - London',
          location: 'London, لندن'
        }
      ]
    };
  },
  computed: {
    itemsToShow() {
      return window.innerWidth <= 700 ? 1 : 3;
    }
  },
};
</script>

<style scoped>
.section {
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic-n', sans-serif;
}

.home-swiper-text {
  color: white;
  text-align: center;
  margin-block: 50px;
}

.home-swiper-text h1 {
  font-size: 2.5rem;
  font-weight: 700;
}

.home-swiper-text h3 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 80%;
  padding-bottom: 10px;
}

.carousel-cont {
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-inline: 50px;
}

.location-image {
  width: 80%;
  margin-inline: 10%;
  object-fit: cover;
  border-radius: 10px;
}

.location-info {
  height: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: flex-start;
}

.location-info h2 {
  font-size: 1.5rem;
  margin-top: 5px;
}

.location-info p {
  font-size: 1rem;
}
@media (max-width: 1000px) {
  .home-swiper-text {
    margin-block: 20px;
  }

  .home-swiper-text h1 {
    font-size: 1.3rem;
    font-weight: 500;
  }

  .home-swiper-text h3 {
    font-size: 1.5rem;
  }

  .carousel-cont {
    padding-bottom: 0;
  }

  .location-image {
    width: 90%;
    margin-inline: 0;
  }

  .location-info {
    height: 300px;
  }
}

</style>
