<template>
  <div class="job-listings-en">
    <h2 class="job-listings-title-en">List of jobs</h2>
    <div class="job-item-en" v-for="job in jobs" :key="job.id">
      <div class="job-container1-en">
        <div class="job-column-en">
          <div class="job-header-en">
            <div class="job-title-en">
              <h2>{{ job.title }}</h2>
            </div>
            <div class="job-type-en">
              <h2>{{ job.type }}</h2>
            </div>
          </div>
          <div class="job-footer-en">
            <div class="job-footer-wrapper-en">
              <div class="job-category-en">{{ job.category }}</div>
              <div class="job-date-en">{{ job.date }}</div>
            </div>
          </div>
        </div>
        <div class="job-column-en">
          <div class="job-info-en">
            <p>{{ job.experience }}</p>
            <p :class="getWorkTypeClass(job.workType)">{{ job.workType }}</p>
          </div>
        </div>
        <div class="job-column-en">
          <button class="details-button-en" @click="toggleDetails(job.id)">
             Job Details
            <svg fill="#ffffff" height="14px" width="14px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 330 330" xml:space="preserve">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path>
              </g>
            </svg>
          </button>
        </div>
        <div class="job-gradient-en"></div>
      </div>
      <transition name="slide-fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <div v-if="isJobActive(job.id)" class="job-container2-en">
          <div class="job-description-en" v-html="job.description"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeJob: null,
        jobs: [
          {
            id: 1,
            title: 'English teacher',
            type: 'Full Time',
            experience: 'more than 2 years',
            workType: 'Working from office or home',
            category: 'academics',
            showDetails: false,
            date: 'Posted by: Two days',
            description: `
              <h2 style="font-size: 1.5rem;">job description:</h2>
              <p>• Job opening for a full-time teacher with 6 working days and 8 working hours per day.</p>
              <p>• Working hours are 2pm to 10pm in summer and 1pm to 9pm in winter.</p>
              <p>• Teaching up to 5 hours a day.</p>
              <p>• Conduct interactive, student-focused lessons on Zoom for a group of students.</p>
              <p>• Most plans and PowerPoint presentations are available but teachers are encouraged to design their lessons as they see fit.</p>
              <p>• Provide a brief report for each session to share with learners.</p>
              <p>• Follow up and update records and evaluation papers daily.</p>
              <p>• Give learners adequate feedback on their homework daily.</p>
              <p>• Attend parent/student meetings once a month and share verbal feedback with learners and answer their questions.</p>
              <p>• Prepare, edit and correct level tests and share the results with learners.</p>
              <p>• Writing informational level assessment reports and sharing them with learners.</p>
              <p>• Report any students who require special interventions.</p>
              <p>• Attend and actively participate in continuing professional development sessions once a week.</p>
              <p>• Participate in crew meetings.</p>
              <p>• Communicate effectively with learners/parents in all aspects related to learning and teaching.</p>
              <p>• Perform self-reflection activities as assigned.</p>
              <p>• Perform other tasks related to learning and teaching as assigned.</p>
              <p>*Please note that the only hours not included in office hours are: Continuing Professional Development sessions on Mondays and Debate Club on Saturdays.</p>
              <p>• Continuing professional development session from 12pm to 2pm on Monday.</p>
              <p>• Debate Club occasionally meets for an hour on Saturdays (not every Saturday) between 1:00 and 2:00 p.m.</p>
              <p>• In winter, it is from 12:00 noon.</p>

              <h2 style="font-size: 1.5rem;">Monglish Academy offers a variety of benefits:</h2>
              <p>• A very healthy work environment where teachers are respected, supported, challenged and rewarded.</p>
              <p>• World-class management approach and practice.</p>
              <p>• The Academy is expanding into new international markets such as Europe, North America and Southeast Asia, providing exceptional opportunities for career growth.</p>
              <p>• The academy provides a world-class service in which the teacher is proud to participate.</p>
              <p>• You are not the only teacher responsible for a group of learners, there are other qualified teachers who support you.</p>
              <p>• Student/parent satisfaction is almost 100%.</p>
              <p>• Pay increases are given regularly according to teacher performance.</p>
              <p>• Teachers are evaluated and treated with the utmost respect.</p>
              <p>• Health and social insurance is provided.</p>
              <p>• Exceptional continuing professional development is provided to help teachers develop.</p>
              <p>• 3 days off at the end of the month for the convenience of teachers.</p>
              <p>• Fixed schedule.</p>

              <h2 style="font-size: 1.5rem;">Job Requirements:</h2>
              <p>• Proficiency at C1 level of English is a prerequisite.</p>
              <p>• TKT, TESOL, TEFL, CELTA, and DELTA certifications are preferred.</p>
              <p>• Excellent presentation skills.</p>
              <p>• Stable Internet and a personal computer or laptop.</p>
              <p>• Experience teaching online is a plus.</p><br>
              <a href="/en/job-registration/" style="background: linear-gradient(45deg, #fe9b4f, #f47e23);color: white;border: none;align-self: center;padding: 10px 20px;cursor: pointer;border-radius: 20px;font-size: 16px;font-weight: 500;font-family: 'DIN Next LT Arabic', sans-serif;display: flex;align-items: center;justify-content: center;width:50%;">
                Apply for the job
                <svg width="14px" height="14px" style="color:white;" viewBox="0 0 1024 1024" class="icon" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#ffffff" d="M340.864 149.312a30.592 30.592 0 000 42.752L652.736 512 340.864 831.872a30.592 30.592 0 000 42.752 29.12 29.12 0 0041.728 0L714.24 534.336a32 32 0 000-44.672L382.592 149.376a29.12 29.12 0 00-41.728 0z"/>
                </svg>
              </a>
            `
          },
          {
            id: 2,
            title: 'Class supervisor',
            type: 'Full Time',
            experience: '3 to 4 years',
            workType: 'Working from office',
            category: 'academics',
            date: 'Posted by: Two days',
            showDetails: false,
            description: `
              <h2 style="font-size: 1.5rem">job description:</h2>
              <p>• Supervising classrooms.</p>
              <p>• Preparing reports accurately and efficiently.</p>
              <p>• Create student grade reports.</p>
              <p>• Classification of student reports and other documents.</p>
              <p>• Order new supplies including stationery.</p>
              <p>• Answer the phone.</p>
              <p>• Dealing with inquiries and complaints.</p>
              <p>• Receive messages for teachers.</p>
              <p>• Receiving families and other people who visit the school.</p>
              <p>• Providing appropriate orientation to visitors/students/parents around the school.</p>
              <p>• Updating the records of students and faculty members.</p>

              <h2 style="font-size: 1.5rem">Monglish Academy offers a variety of benefits:</h2>
              <p>• A very healthy work environment where teachers are respected, supported, challenged and rewarded.</p>
              <p>• World-class management approach and practice.</p>
              <p>• The Academy is expanding into new international markets such as Europe, North America and Southeast Asia, providing exceptional opportunities for career growth.</p>
              <p>• The academy provides a world-class service in which the teacher is proud to participate.</p>
              <p>• You are not the only teacher responsible for a group of learners, there are other qualified teachers who support you.</p>
              <p>• Student/parent satisfaction is almost 100%.</p>
              <p>• Pay increases are given regularly according to teacher performance.</p>
              <p>• Teachers are evaluated and treated with the utmost respect.</p>
              <p>• Health and social insurance is provided.</p>
              <p>• Exceptional continuing professional development is provided to help teachers develop.</p>
              <p>• 3 days off at the end of the month for the convenience of teachers.</p>
              <p>• Fixed schedule.</p>

              <h2 style="font-size: 1.5rem">Job Requirements:</h2>
              <p>• Bachelor’s degree.</p>
              <p>• Experience in customer service or related field ranging from 3 to 4 years.</p>
              <p>• Proficiency in using Microsoft Office (especially Word and Excel).</p>
              <p>• Proficiency in the English language is a prerequisite.</p>
              <p>• Excellent typing speed in Arabic and English.</p>
              <p>• Excellent printing accuracy.</p>
              <p>• Outstanding communication and negotiation skills.</p>
              <p>• For Alexandria residents only.</p><br>
              <a href="/en/job-registration/" style="background: linear-gradient(45deg, #fe9b4f, #f47e23);color: white;border: none;align-self: center;padding: 10px 20px;cursor: pointer;border-radius: 20px;font-size: 16px;font-weight: 500;font-family: 'DIN Next LT Arabic', sans-serif;display: flex;align-items: center;justify-content: center;width:50%;">
                Apply for the job
                <svg width="14px" height="14px" style="color:white;" viewBox="0 0 1024 1024" class="icon" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#ffffff" d="M340.864 149.312a30.592 30.592 0 000 42.752L652.736 512 340.864 831.872a30.592 30.592 0 000 42.752 29.12 29.12 0 0041.728 0L714.24 534.336a32 32 0 000-44.672L382.592 149.376a29.12 29.12 0 00-41.728 0z"/>
                </svg>
              </a>
            `
          },
          {
            id: 3,
            title: 'Educational consultant',
            type: 'Full Time',
            experience: '3 to 4 years',
            workType: 'Working from office',
            category: 'academics',
            date: 'Posted by: Two days',
            showDetails: false,
            description: `
              <h2 style="font-size: 1.5rem;">job description:</h2>
              <p>• Initiate sales with potential customers over the phone.</p>
              <p>• Ask questions to capture customer interest and keep the conversation going.</p>
              <p>• Listen to customer needs to generate repeat sales.</p>
              <p>• Collect and document customer information, payment methods, purchases, and reactions to products.</p>
              <p>• Keeping up with all services and informing customers of new services.</p>
              <p>• Answering customer questions about services.</p>
              <p>• Achieving sales quotas.</p>

              <h2 style="font-size: 1.5rem">Monglish Academy offers a variety of benefits:</h2>
              <p>• A very healthy work environment where teachers are respected, supported, challenged and rewarded.</p>
              <p>• World-class management approach and practice.</p>
              <p>• The Academy is expanding into new international markets such as Europe, North America and Southeast Asia, providing exceptional opportunities for career growth.</p>
              <p>• The academy provides a world-class service in which the teacher is proud to participate.</p>
              <p>• You are not the only teacher responsible for a group of learners, there are other qualified teachers who support you.</p>
              <p>• Student/parent satisfaction is almost 100%.</p>
              <p>• Pay increases are given regularly according to teacher performance.</p>
              <p>• Teachers are evaluated and treated with the utmost respect.</p>
              <p>• Health and social insurance is provided.</p>
              <p>• Exceptional continuing professional development is provided to help teachers develop.</p>
              <p>• 3 days off at the end of the month for the convenience of teachers.</p>
              <p>• Fixed schedule.</p>

              <h2 style="font-size: 1.5rem">Job Requirements:</h2>
              <p>• Excellent communication skills.</p>
              <p>• Creative thinking skills.</p>
              <p>• Patience and ability to engage customers in conversation.</p>
              <p>• Excellent selling ability.</p>
              <p>• Working knowledge of relevant computer software.</p>
              <p>• Excellent interpersonal and problem-solving skills.</p>
              <p>• The ability to deal with rejection and pressure when communicating with clients.</p><br>
              <a href="/en/job-registration/" style="background: linear-gradient(45deg, #fe9b4f, #f47e23);color: white;border: none;align-self: center;padding: 10px 20px;cursor: pointer;border-radius: 20px;font-size: 16px;font-weight: 500;font-family: 'DIN Next LT Arabic', sans-serif;display: flex;align-items: center;justify-content: center;width:50%;">
                Apply for the job
                <svg width="14px" height="14px" style="color:white;" viewBox="0 0 1024 1024" class="icon" xmlns="http://www.w3.org/2000/svg">
                  <path fill="#ffffff" d="M340.864 149.312a30.592 30.592 0 000 42.752L652.736 512 340.864 831.872a30.592 30.592 0 000 42.752 29.12 29.12 0 0041.728 0L714.24 534.336a32 32 0 000-44.672L382.592 149.376a29.12 29.12 0 00-41.728 0z"/>
                </svg>
              </a>
            `
          }
        ]
      };
    },
    methods: {
      getWorkTypeClass(workType) {
        return workType === 'Working from office or home' ? 'work-from-office-home-en' : 'work-from-office-en';
      },
      toggleDetails(jobId) {
        if (this.activeJob === jobId) {
          this.activeJob = null; // Collapse if the same job is clicked
        } else {
          this.activeJob = jobId; // Expand the new job
        }
      },
      isJobActive(jobId) {
        return this.activeJob === jobId;
      },
      beforeEnter(el) {
        el.style.height = '0';
        el.style.opacity = '0';
      },
      enter(el, done) {
        el.offsetHeight; // trigger reflow
        el.style.transition = 'height 0.5s ease, opacity 0.5s ease';
        el.style.height = 'auto';
        el.style.opacity = '1';
        done(); // Call done() to signal that the transition has finished
      },
      leave(el, done) {
        el.style.transition = 'height 0.5s ease, opacity 0.5s ease';
        el.style.height = '0';
        el.style.opacity = '0';
        el.addEventListener('transitionend', done); // Call done() after the transition ends
      }
    }
  };
</script>

<style scoped>
  .job-listings-en {
    padding: 20px;
    font-family: 'DIN Next LT Arabic', sans-serif;
    background-color: white;
    direction: ltr;
  }
  .job-listings-en h2.job-listings-title-en {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .job-item-en {
    background-color: #fff;
    border: 2px solid #DCDCE0;
    width: 75%;
    border-radius: 20px;
    margin-bottom: 20px;
    /* padding: 0 20px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }

  .job-container1-en {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.25rem;
    position: relative;
    border: 2px solid #e5e5e5;
    border-radius: 20px;
    border-left: none;
    border-top: none;
  }

  .job-container1-en .job-column-en:first-child{
    gap: 15px;
  }

  .job-container2-en{
    display: flex;
    flex-direction: row;
    overflow: hidden; /* Ensure content doesn't overflow during animation */
  }

  .job-column-en {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }
  .job-header-en {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    gap: 15px;
  }
  .job-title-en{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .job-title-en h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: #001b48;
  }
  .job-type-en{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .job-type-en h2 {
    font-size: 14px;
    color: #fff;
    background-color: #165E84;
    padding: 10px 40px;
    border-radius: 20px;
    text-align: center;
    /* width: 40%; */
  }
  .job-footer-en {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    color: #666;
    margin-left: 15rem;
  }
  .job-footer-en p {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    font-size: medium;
  }
  .job-footer-en .job-footer-wrapper-en {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap:5px;
  }
  .job-date-en {
    color: green;
    font-size: 14px;
  }
  .job-body-en {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .job-info-en p {
    font-size: 14px;
    text-align: center;
  }
  .job-info-en p:first-child{
    color: #808080;
  }
  .details-button-en {
    background: linear-gradient(45deg, #fe9b4f, #f47e23);
    color: white;
    border: none;
    align-self: center;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
    font-family: 'DIN Next LT Arabic', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65%;
  }
  .details-button-en svg {
    margin-right: 10px;
  }
  .job-gradient-en {
    width: 2%;
    background: linear-gradient(171deg, #2C80AC 28%, #165E84 71%);
    border-radius: 20px 0 0 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .work-from-office-home-en {
    color: #f47e23;
  }
  .work-from-office-en {
    color: #165E84;
  }
  .job-description-en {
    position: relative;
    padding: 10px;
    margin: 0 3rem;
    border-radius: 0 0 20px 20px;
    font-size: 14px;
    color: #333;
  }
  .job-description-en p {
    margin: 0;
    line-height: 1.6;
  }

  /* collapse job details animation */
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.5s ease-in;
  }

  .slide-fade-enter, 
  .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
    height: 0;
    opacity: 0;
  }

  .slide-fade-enter-to,
  .slide-fade-leave {
    height: auto;
    opacity: 1;
  }
  /* end collapse job details animation */

  @media (min-width: 320px) and (max-width: 479px) {
    .job-listings-en h2{
      text-align: center;
      font-size: 2.5rem;
      font-weight: 500;
      font-family: "DIN Next LT Arabic", Sans-serif;
      margin-bottom: 20px;
    }
    .job-item-en{
      width: 100%;
    }
    .job-container1-en{
      flex-direction: column;
      padding: 0 1.25rem;
    }

    .job-container1-en .job-column-en:first-child{
      gap: 0;
    }

    .job-container1-en .job-column-en {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0;
      margin-top: 20px;
      margin-bottom: 20px;
      gap: 15px;
    }

    .job-gradient-en {
      width: auto;
      height: 5%;
      background: linear-gradient(171deg, #2C80AC 28%, #165E84 71%);
      border-radius: 0 20px 20px 0;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }
  
    .job-header-en{
      flex-direction: column;
      gap: 20px;
    }
    .job-footer-en{
      justify-content: center;
      margin: auto;
    }
    .job-footer-en p {
      font-size: large;
    }
    .job-footer-en .job-footer-wrapper-en{
      flex-direction: column;
      align-items: center;
    }
    .job-type-en h2{
      font-size: 1.2rem;
      width: 20rem;
    }

    .job-info-en p{
      font-size: large;
    }

    .details-button-en{
      width: 100%;
    }

    .job-description-en{
      margin: 0;
    }
  }
</style>
