<template>
  <div class="our-team-container-en">
    <div class="our-team-en">
      <div class="padding-full-en">
        <div class="ourfounder-img-mobile-en">
          <img src="@/assets/images/founder.png" alt="founder" class="founder-team-en">
        </div>
        <div class="padding-h3-title-en">
          <h3 class="padding-text-full-en">Monglish International</h3>
        </div>
        <div class="padding-h2-title-en">
          <h1>Academy experts</h1>
        </div>
        <div class="margin-text-p-en">
          <p class="margin-text-up-en">
            monglish_frontend is distinguished by a specialized global team that includes more than 300 success partners, who provide a unique educational experience in teaching the English language with the highest levels of quality and professionalism, relying on an innovative curriculum that simulates the experience of living among native speakers of the language. We are committed to providing an outstanding educational experience that enhances the success and excellence of learners.
          </p>
        </div>
        <div class="joinus-button-en">
          <button>
            Join the Monglish team
            <svg aria-hidden="true" width="1em" height="1em" class="joinus-svg e-font-icon-svg e-fas-long-arrow-alt-left" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
              <path fill="#fff" d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"></path>
            </svg>
          </button>
        </div>
        <div class="ourteam-counter-en">
          <OurTeamCounter />
        </div>
        <div class="ourfounder-img-en">
          <img src="@/assets/images/founder-en.png" alt="founder" class="founder-team-en">
        </div>
      </div>
    </div>
  
    <div class="team-row-en">
      <h3>Management That Makes A Difference</h3>
      <p>
        The backbone of our success; they work hard and with dedication at every step to ensure the provision of an exceptional educational experience and the achievement of milestones at every stage of the educational process.
      </p>
    </div>
  
    <div class="team-managers-en">
      <div v-for="manager in teamManagers" :key="manager.id" class="manager-card-en">
        <div class="manager-info-en">
          <h4>{{ manager.name }}</h4>
          <p>{{ manager.title }}</p>
        </div>
        <img :src="manager.image" :alt="manager.name">
      </div>
    </div>

    <div class="team-slider-en">
      <h1>Partners in Success</h1>
      <div class="slider-container-en">
        <button @click="nextSlide" class="slider-button-en nextt-en">❯</button>
        <div class="slides-en">
          <img :src="images[currentIndex]" alt="Team Image" />
        </div>
        <button @click="prevSlide" class="slider-button-en prevv-en">❮</button>
      </div>
    </div>
  
    <VideoView />
     
    <div class="last-section-team-en">
       <div class="ourteam-sec6-en"> 
      <img src="@/assets/images/ourteam-6.jpg" alt="ourteam-6" class="ourteam-6-en">
    </div>
  
    <div class="ourteam-text-en"> 
      <p>Interested In Joining?</p>
      <p>Check Out The Link Below!</p>
      <button>
        Visit the Mongi-Careers Portal
        <svg aria-hidden="true" width="1em" height="1em" class="joinus-svg e-font-icon-svg e-fas-long-arrow-alt-left" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
          <path fill="#fff" d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"></path>
        </svg>
      </button>
    </div>
    </div>
  </div>
</template>

<script>
import OurTeamCounter from '../../components/en/TeamCounter.vue'; 
import VideoView from '../../components/en/VideoView.vue'; 

export default {
  name: 'OurTeam',
  components: {
    OurTeamCounter,
    VideoView
  },
  data() {
    return {
      teamManagers: [
        {
          id: 1,
          name: 'Dr. Mohamed Al-Najjar',
          title: 'Vice Chairman',
          image: require('@/assets/images/team/1.jpg')
        },
        {
          id: 2,
          name: 'Dr. Hatem Hassanein',
          title: 'Academic Director',
          image: require('@/assets/images/team/2.jpg')
        },
        {
          id: 3,
          name: 'Ms. Heba Hassan',
          title: 'Assistant to the Chairman for Sales and Marketing',
          image: require('@/assets/images/team/3.jpg')
        },
        {
          id: 4,
          name: 'Eng. Mohamed Salem',
          title: 'Executive Director of Technology Affairs',
          image: require('@/assets/images/team/4.jpg')
        },
        {
          id: 5,
          name: 'Mr. Safi Al-Rahman Abaza',
          title: 'Deputy Marketing Director',
          image: require('@/assets/images/team/5.jpg')
        },
        {
          id: 6,
          name: 'Ms. Maysa Al-Sheikh',
          title: 'Human Resources Director',
          image: require('@/assets/images/team/6.jpg')
        },
        {
          id: 8,
          name: 'Mr. Karim Basiony',
          title: 'Deputy Customer Service Director',
          image: require('@/assets/images/team/8.jpg')
        },
        {
          id: 12,
          name: 'Dr. Nasr Karkour',
          title: 'Assistant Chairman, Alexandria Branch Manager',
          image: require('@/assets/images/team/12.jpg')
        },
        {
          id: 11,
          name: 'Ms. Samia Anwar',
          title: 'Director of Higher Stages',
          image: require('@/assets/images/team/11.jpg')
        },
        {
          id: 10,
          name: 'Dr. Soha Essam',
          title: 'Director of Foundation Stages',
          image: require('@/assets/images/team/10.jpg')
        },
        {
          id: 9,
          name: 'Mr. Walid Raslan',
          title: 'Accounts Manager',
          image: require('@/assets/images/team/9.jpg')
        },
      ],
      images: [
        require('@/assets/images/slider/en/1.png'),
        require('@/assets/images/slider/en/2.png'),
        require('@/assets/images/slider/en/3.png'),
        require('@/assets/images/slider/en/4.png'),
        require('@/assets/images/slider/en/5.png'),
        require('@/assets/images/slider/en/6.png'),
        require('@/assets/images/slider/en/8.png'),
        require('@/assets/images/slider/en/9.png'),
        require('@/assets/images/slider/en/10.png'),
        require('@/assets/images/slider/en/11.png'),
        require('@/assets/images/slider/en/12.png'),
        require('@/assets/images/slider/en/13.png'),
        require('@/assets/images/slider/en/14.png'),
        require('@/assets/images/slider/en/15.png'),
        require('@/assets/images/slider/en/16.png')
      ],
      currentIndex: 0
    };
  },
  methods: {
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    }
  }
};
</script>

<style src="@/assets/styles/en/OurTeam.css"></style>