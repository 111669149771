<template>
  <footer class="monglish-footer">
    <!-- Footer Top -->
    <div class="footer-top">
      <div class="footer_logo">
        <router-link to="/">
          <img src="@/assets/images/logo.png" alt="Monglish Logo" class="logo-footer-main" />
        </router-link>
      </div>
      
      <div class="social-links">
        <a href="#"><i class="fab fa-linkedin"></i></a>
        <a href="#"><i class="fab fa-telegram"></i></a>
        <a href="#"><i class="fab fa-snapchat"></i></a>
        <a href="#"><i class="fab fa-youtube"></i></a>
        <a href="#"><i class="fab fa-twitter"></i></a>
        <a href="#"><i class="fab fa-tiktok"></i></a>
        <a href="#"><i class="fab fa-instagram"></i></a>
        <a href="#"><i class="fab fa-facebook"></i></a>
      </div>
    </div>

    <!-- Footer Middle -->
    <hr />

    <!-- Footer Middle -->
    <div class="footer-middle mt-10">
      <div class="footer-description">
        <p>أكاديمية مونجلش معتمدة في إنجلترا وويلز (رقم 14083316) ومقرها القانوني بلندن، تعترف بشهادات اللغة الإنجليزية من جامعة كامبريدج و تضم مدربين بشهادات TKT/CELTA/DELTA.</p>
        <a class="link_to_text" href="https://www.cambridgeenglish.org/why-choose-us/global-recognition/?location=egypt&city=select%20all&roinstitutetype=&page=k-o&product=all">لمزيد من المعلومات اضغط هنا</a>
      </div>

      <div class="footer-links">
        <h3>روابط</h3>
        <p><a href="#" class="link_to_text">التسويق بالعمولة</a></p>
        <p><a href="/ar/terms-and-conditions/security" class="link_to_text">الأمن والحماية</a></p>
        <p><a href="/ar/terms-and-conditions/refund" class="link_to_text">الاسترداد والإلغاء</a></p>
        <p><a href="/ar/terms-and-conditions/terms" class="link_to_text">الشروط والأحكام</a></p>
        <p><a href="/ar/terms-and-conditions/privacy" class="link_to_text">سياسة الخصوصية</a></p>
      </div>

      <div class="footer-social">
        <h3>روابط اجتماعية أخرى</h3>
        <p><a href="#" class="link_to_text"><i class="fab fa-facebook"></i> Training and Development</a></p>
        <p><a href="#" class="link_to_text"><i class="fab fa-facebook"></i> Community</a></p>
        <p><a href="#" class="link_to_text"><i class="fab fa-instagram"></i> monglish_for_training</a></p>
        <p><a href="#" class="link_to_text"><i class="fab fa-telegram"></i> Monglish Academy group</a></p>
      </div>

      <div class="footer-contact">
        <h3>تواصل معنا من خلال</h3>
        <p class="phone-info">
          <i class="fas fa-phone"></i> 201000743039+
        </p>
        <p>
          <a href="https://wa.me/+201000743039" target="_blank" class="link_to_text">
            <i class="fab fa-whatsapp"></i> الواتس اب
          </a>
        </p>
        <p>
          <a href="https://www.messenger.com" target="_blank" class="link_to_text">
            <i class="fab fa-facebook-messenger"></i> ماسنجر
          </a>
        </p>
      </div>

    </div>

    <!-- Footer Bottom -->
  <div class="footer-bottom">
    <div class="footer-subscription footer-subscription-ar">
      <h3>اشترك للحصول على عروضنا الحصرية</h3>
      <div class="sub-input">
        <input type="email" placeholder="البريد الإلكتروني">
        <button>اشترك</button>
      </div>
      <img src="@/assets/images/pay.png" class="footer-payment" alt="Visa">
    </div>

    <div class="footer-certifications">
          <h3>اعتمادات الجودة</h3>
          <div class="certificates">
            <img src="@/assets/images/par2b.png" alt="AAA" />
            <img src="@/assets/images/par2b-1-1.png" alt="AAA" />
          </div>
          <div class="certificates">
           <img src="@/assets/images/Rectangle-6117.png" alt="ARQOA" />
            <img src="@/assets/images/accreditations/Logos.png" alt="AAA"/>
            </div>
        </div>

    <div class="footer-certifications">
          <h3>الشهادات</h3>
          <div class="certificates">
            <img src="@/assets/images/Rectangle-6123.png" alt="ARQOA" />
            <img src="@/assets/images/Rectangle-29917.png" alt="AAA" />
          </div>
        </div>

    <div class="footer-certifications">
          <h3>عضو مؤسسي في</h3>
          <div class="certificates">
            <img src="@/assets/images/Rectangle-6122.png" alt="AAA" />
            <img src="@/assets/images/Rectangle-6116.png" alt="ARQOA" />
          </div>
        </div>
  </div>

    <!-- Footer Bottom -->
  <h2 class="center-text">سهولة الوصول لنا في كل مكان</h2>
  <div class="footer-locations">
    <div class="location-grid">
      <div class="location">
        <h3>مقر مصر - الإسكندرية</h3>
        <p>201010178521+</p>
        <p>alex.office@monglish.email</p>
      </div>
      <div class="location">
        <h3>مقر المملكة المتحدة - لندن</h3>
        <p>442038850766+</p>
        <p>office@monglish.uk</p>
      </div>
      <div class="location">
        <h3>مقر الإمارات - دبي</h3>
        <p>97142285285+</p>
        <p>dubai.office@monglish.email</p>
      </div>
      <div class="location">
        <h3>المكتب التمثيلي في السعودية - الرياض</h3>
        <p>9668008500770+</p>
        <p>riyadh.office@monglish.email</p>
      </div>
      <div class="location">
        <h3>مقر الولايات المتحدة - نيويورك</h3>
        <p>19178190745+</p>
        <p>office@monglish.uk</p>
      </div>
    </div>
  </div>
  </footer>
  <div class="footer-box">
© 2019-2024 أكاديمية مونجلش، محفوظة الحقوق. "مونجلش" و"منهج المعايشة" هما علامتان لمنهجنا المسجل عالميًا والمحمي بحقوق النشر. لمزيد من التفاصيل، زوروا رابط التسجيل.
</div>
</template>

<script>
export default {
  name: 'MonglishFooter'
}
</script>
<style scoped>
.monglish-footer {
  background-color: #165e84;
  padding-block: 20px;
  padding-inline: 5%;
  font-family: 'DIN Next LT Arabic-n', sans-serif;
  direction: rtl;
  color: #FFFFFF80;
}
.center-text{
  text-align: center;
  color: white;
}
</style>

<style src="@/assets/styles/MonglishFooter.css" scoped></style>