<template>
  <div>
    <div class="promise-sec1">
       <h1>نعدكم بتقديم القيمة العالية</h1>
       <p>بانضمامك إلى مونجليش، ستتمتع بالقدرة على تعيين وتيرة تعلمك والتقدم في اللغة الإنجليزية بما يتوافق مع ظروفك. دوراتنا توفر مرونة استثنائية، مما يزيد من كفاءة التعلم ويعزز النتائج على المدى الطويل، وهذا يمثل جوهر التزامنا في توفير قيمة لا تُقدر بثمن لعملائنا في مونجلش.</p>
       <div class="imagee-containerr">
          <img src='@/assets/images/promise3-ar.png' alt="promise3">
       </div>
       <div class="imagee-containerr2">
          <img src='@/assets/images/promise1-ar.png' alt="promise1">
          <img src='@/assets/images/promise2-ar.png' alt="promise2">
       </div>
    </div> 

    <div class="content-promise">
      <div class="image-promise">
        <img src="@/assets/images/promise-sec2.png" alt="promise-sec2">
      </div>
      <div class="text-promise">
        <h2>التجربة الممتعة</h2>
        <p>
          مع مونجلش سوف يستمتع الطفل بالتعلم وزيادة تركيزه بالمعلومات الجديدة وتدوينها بذاكرته، نتيجة للتنوع بطرائق تقديم المعلومات بالموضوعات المختلفة التي تشد انتباهه بوتيرة أسرع. فإذا كنت على استعداد لتعليم طفلك اللغة الإنجليزية، وطرق التواصل بها والتفوق فيها، فقد وصلت إلى المكان الصحيح! إننا نقدم مجموعة متنوعة من الدورات التي تناسب جميع مستويات إتقان اللغة الانجليزية، كما تناسب الفئات العمرية المختلفة.
        </p>
      </div>
    </div>

    <div class="vision-sec3">
    <h1>نبني الثقة بالنفس</h1>
    <div class="vision-container">
      <iframe 
        src="https://player.vimeo.com/video/818262964"
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>
    </div>

    <div class="promise-sec3-space">
      <div class="promise-sec3">
        <div class="promise-sec3-inner">
          <div class="text-content-promise">
            <h1>التطوير الدائم</h1>
            <p>
              في مونجلش، نقدم دورات لغة إنجليزية متخصصة ونوادي تفاعلية لكل مستوى وأسلوب تعلم. تصميمنا الفريد يلبي احتياجاتك الشخصية، مما يضمن تقدمك السريع والممتع في إتقان الإنجليزية، بعيدًا عن الطرق التقليدية.
            </p>
          </div>
        </div>
      </div>
    </div>

  <div class="slider-3d">
    <h1>ما رأيكم في سماع آرائهم من القلب؟</h1>
    <div class="carousel-container">
      <button class="slider-nav left" @click="moveLeft">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </button>

      <transition-group name="slide" tag="div" class="carousel-track">
        <div v-for="(item, index) in generateItems()" :key="index" :class="['item', 'level' + item.level]">
          <img :src="item.src" :alt="'Image ' + index" @click="openPopup(item.videoSrc)" />
        </div>
      </transition-group>

      <button class="slider-nav right" @click="moveRight">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>

    <div v-if="isPopupOpen" class="popup-overlay" @click="closePopup">
      <div class="popup-content" @click.stop>
        <button class="close-button" @click="closePopup">&times;</button>
        <iframe
          v-if="currentVideoSrc"
          :src="currentVideoSrc"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="popup-video"
        ></iframe>
      </div>
    </div>
  </div>

  <div class="kids-sec8">
    <div class="kids-sec8-row">
      <h4>الآراء الإيجابية <br><span> لأولياء الأمور</span></h4>
      <p>نظرا للتفوق المبهر الذي يحققه خريجو أكاديمية مونجلش الدولية، خصوصا بالنسبة للأطفال والمراهقين، فإن أولياء
        أمورهم فخورين بما وصل إليه أبنائهم، وسعادتهم بالتطوير السريع الذي شهده أبنائهم والتي يعربون عنها في كل مناسبة
        تجعلنا نشعر بالفخر أكثر، وتزيدنا عزما على الاجتهاد من أجل التطوير والتحسين المستمر للدورات التي نقدمها، من أجل
        الوصول إلى أفضل النتائج لطلابنا دائما. إن مونجلش وصلت إلى ما وصلت إليه بدعم هؤلاء الرائعين!</p>
    </div>

    <div class="adult-swiper">
      <div ref="swiperContainer" class="swiper-container">
        <div class="swiper-wrapper">
          <div v-for="(review, index) in reviews" :key="index" class="swiper-slide">
            <p>
              <span class="review-stars">{{ '★'.repeat(review.stars) }}</span>
            </p>
            <p>{{ review.review }}</p>
            <p class="review-by">{{ review.by }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>  

    <PackagesAr />

    <div class="vision-sec5">
    <h1>المعايشة التامة</h1>
    <div class="vision-container">
      <iframe 
        src="https://player.vimeo.com/video/818262990"
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>
    </div>
  </div>
</template>

<script>
import PackagesAr from '../../views/ar/PackagesPage.vue';
import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'KidsCourses',
  components: {
    PackagesAr
  },
  data() {
    return {
      isPopupOpen: false,
      currentVideoSrc: '',
      active: 0,
      items: [
        { src: require('@/assets/images/video/20.png') , videoSrc: 'https://www.youtube.com/embed/u4pJkaKFDlo?autoplay=1'},
        { src: require('@/assets/images/video/21.png') , videoSrc: 'https://www.youtube.com/embed/QkotruhuVPM?autoplay=1'},
        { src: require('@/assets/images/video/22.png') , videoSrc: 'https://www.youtube.com/embed/q473RldRfrs?autoplay=1'},
        { src: require('@/assets/images/video/23.png') , videoSrc: 'https://www.youtube.com/embed/fcdBZnoxqdg?autoplay=1'},
      ],
      autoSlideInterval: null,
      activeDescription: null,
      totalSections: 3,
      currentIndex: 0,
      reviews: [
        {
          stars: 5,
          review:
            'جزاك الله خير انا حضرت المحاضرة كامله معاها ماشاء الله أسلوبه متميز يتسم بالهدوء أثناء الشرح',
          by: 'عبد الله عوده',
        },
        {
          stars: 5,
          review:
            'الله يعطيكم العافية بصراحه تمنيت اني دخلت عبد الرحمن من زمان لاني لاحظت تطور كبير و هذا بفضل الله وتوفيقة ثم بفضل جهدكم معانا واهتمامكم بأدق ادق التفاصيل و متابعة الواجابات و حرصكم بتذكيرنا بمواعيد الاندية كانه ابن لكم',
          by: 'ام عبد الرحمن',
        },
        {
          stars: 5,
          review:
            'لا بجد ما شاء الله ربنا يبارك فيها من أجمل واحسن المدرسين اللي في الأكاديمية و غير الضمير الي عندها ربنا يرضيها يارب في حياتها',
          by: 'والدة إياد',
        },
        {
          stars: 5,
          review:
            'السلام عليكم ورحمة الله وبركاته نادي القراءة حقيقي اللي مسؤوله عنه مره ممتازة ما شاء الله و بترد في اسرع وقت وتقوم بتحديد الموعد و ملتزمة جدا جزاها الله خيرا',
          by: 'فهد محمد',
        },
      ], swiper: null, 
    };
  },
  mounted() {
    this.$nextTick(() => {
      const swiperContainer = this.$refs.swiperContainer;
      if (swiperContainer) {
        this.swiper = new Swiper(swiperContainer, {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
        this.startAutoNavigate();
      }
    });

    this.autoSlide();
  },
  beforeUnmount() {
    this.stopAutoSlide();
  },
  methods: {
    startAutoNavigate() {
      setInterval(() => {
        if (this.swiper) {
          this.swiper.slideNext(); 
        }
      }, 3000); 
    },
    toggleDescription(id) {
      this.activeDescription = this.activeDescription === id ? null : id;
    },
    openPopup(videoSrc) {
      this.currentVideoSrc = videoSrc;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
      this.currentVideoSrc = '';
    },
    generateItems() {
    const items = [];
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      items.push({
        src: this.items[this.active].src,
        videoSrc: this.items[this.active].videoSrc, 
        level: 0
      });
    } else {
      for (let i = this.active - 2; i <= this.active + 2; i++) {
        let index = i;
        if (i < 0) index = this.items.length + i;
        if (i >= this.items.length) index = i % this.items.length;

        items.push({
          src: this.items[index].src,
          videoSrc: this.items[index].videoSrc, 
          level: this.active - i
        });
      }
    }

    return items;
  },
    moveLeft() {
      this.active = (this.active - 1 + this.items.length) % this.items.length;
    },
    moveRight() {
      this.active = (this.active + 1) % this.items.length;
    },
    autoSlide() {
      this.autoSlideInterval = setInterval(() => {
        this.moveRight();
      }, 3000);
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval);
    }
  }
}
</script>

<style scoped>
.promise-sec1 {
  color: white;
  text-align: center;
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  margin-top: -85px;
  padding-top: 140px;
  padding-left: 5%;
  padding-right: 5%;
}
.promise-sec1 h1 {
  font-size: 2.5rem;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic';
  line-height: 70px;
}
.promise-sec1 p {
  font-size: 1.375rem;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic';
  margin: 0 auto 2% auto;
  max-width: 800px;
  line-height: 35px;
}
.imagee-containerr {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; 
  padding: 1em 0;
}
.imagee-containerr2{
  display: none;
}
.imagee-containerr img{
  width: 100%;
  height: auto;
}
@media (max-width: 768px) {
  .promise-sec1 {
    height: auto;
    padding-top: 100px;
  }
  .promise-sec1 h1 {
    font-size: 2rem;
    line-height: 40px;
  }
  .promise-sec1 p {
    font-size: 1.125rem;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 23px;
  }
  .imagee-containerr {
    /* flex-direction: column;  */
    display: none;
  }
  .imagee-containerr2{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; 
  flex-direction: column;
  padding: 0;
  }
}
@media (max-width: 480px) {
  .promise-sec1 h1 {
    font-size: 28px;
  }
  .promise-sec1 p {
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 30px;
  }
}

.content-promise {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  padding: 40px;
  box-sizing: border-box;
  font-family: 'DIN Next LT Arabic', sans-serif;
  background-color: #fff;
}
.text-promise {
  flex: 1; 
  padding-left: 20px; 
}
.text-promise h2 {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: #165e84;
}
.text-promise p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #333;
}
.image-promise {
  flex: 1;
}
.image-promise img {
  width: 100%;
  height: auto;
  max-width: 600px; 
  border-radius: 8px; 
}
@media (max-width: 768px) {
  .content-promise{
    flex-direction: column;
    justify-content: center;
  }
  .text-promise{
    padding-left: 0; 
  }
  .text-promise h2{
    font-size: 28px;
    text-align: center;
  }
  .text-promise p{
    font-size: 20px;
    text-align: center;
  }
}

.vision-sec3 {
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}
.vision-sec3 h1 {
  font-size: 2.8em;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  color: #fff;
}
.vision-container {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 45%;
  margin-bottom: 1em;
  border-radius: 2em;
}
.vision-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2em;
}
@media (max-width: 1200px) {
  .vision-sec3 h1 {
    font-size: 2.2em;
  }
  .vision-container {
    width: 90%;
    padding-bottom: 50%;
  }
}
@media (max-width: 768px) {
  .vision-sec3 h1 {
    font-size: 28px;
  }
  .vision-container {
    width: 95%;
    padding-bottom: 56.25%; 
  }
}
@media (max-width: 480px) {
  .vision-sec3 h1 {
    font-size: 1.5em;
  }
  .vision-container {
    width: 100%;
    padding-bottom: 60%;
  }
}

.slider-3d {
  background-image: url('@/assets/images/bg.jpg');
  background-size: cover;
  background-position: center;
  padding: 3% 1%;
}

.carousel-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center; 
  overflow: hidden;
  perspective: 1000px; 
}

.carousel-track {
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  transform-style: preserve-3d; 
  transition: transform 0.8s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-100%);
  }
}

.slide-enter-active, .slide-leave-active {
  animation: slideIn 0.8s ease forwards;
}

.slide-leave-to {
  animation: slideOut 0.8s ease forwards;
}

h1 {
  color: #fff;
  font-size: 48px;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic';
  text-align: center;
  padding: 0 0 2% 0;
}

.slider-nav {
  color: #fff;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  transition: color 0.3s ease, transform 0.3s ease;
  border: none;
}

.slider-nav:hover {
  color: #FFF;
  transform: scale(1.1);
}

.slider-nav:active {
  color: #165e84;
  transform: scale(1);
}

.slider-nav.left {
  left: 5%;
}

.slider-nav.right {
  right: 5%;
}

.item {
  position: relative;
  text-align: center;
  transition: transform 0.8s ease, opacity 0.8s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.level-2 {
  transform: translateX(91px) rotateY(-10deg) scale(0.6);
  opacity: 0.5;
}

.level-1 {
  transform: translateX(-1px) rotateY(-5deg) scale(0.8);
  opacity: 0.7;
}

.level0 {
  transform: translateX(0) rotateY(0deg) scale(1);
  opacity: 1;
}

.level1 {
  transform: translateX(1px) rotateY(5deg) scale(0.8);
  opacity: 0.7;
}

.level2 {
  transform: translateX(-91px) rotateY(10deg) scale(0.6);
  opacity: 0.5;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  width: 26vw;
  height: 95vh;  
  overflow: hidden;
  position: relative;
}

.popup-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes popupIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 4rem;
  cursor: pointer;
  transition: color 0.3s ease; 
}

.close-button:hover {
  color: #d9534f; 
}
@media (max-width: 768px) {
  h1 {
    font-size: 28px;
  }

  .slider-nav.left {
    left: 0;
  }

  .slider-nav.right {
    right: 0;
  }

  .popup-content {
    width: 95%;
    height: auto;
  }

  .popup-video {
    height: calc(100vw * (16 / 9));
    width: 100%;
  }

  .carousel-track {
    justify-content: center;
  }

  .item {
    transform: none !important;
    opacity: 1 !important;
  }

  img {
    width: 80%;
    height: auto;
  }
}

.kids-sec8 {
  padding: 2%;
  background-color: white;
  height: auto; 
}
.kids-sec8-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 1em;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  color: #165e84;
}
.kids-sec8-row h4 {
  font-size: 3vw;
}
.kids-sec8-row span {
  font-size: 3.2vw;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
}
.kids-sec8-row p {
  font-size: 1.3rem;
  color: gray;
  max-width: 75%;
  margin: 0 auto;
}
@media (min-width: 769px) {
  .kids-sec8-row {
    flex-direction: row;
    justify-content: center;
    text-align: right;
  }
  .kids-sec8-row h4 {
    width: 25%;
    margin: 0;
    padding-right: 1em;
    font-size: 28px;
  }
  .kids-sec8-row p {
    width: 70%;
    margin: 0;
    padding-left: 20px;
  }
}
@media (max-width: 769px) {
  .kids-sec8-row {
    flex-direction: column;
    text-align: center;
  }
  .kids-sec8-row h4 {
    width: 100%;
    padding-right: 0;
    font-size: 28px;
  }
  .kids-sec8-row h4 span{
    width: 100%;
    padding-right: 0;
    font-size: 28px;
    font-weight: bold;
  }
  .kids-sec8-row p {
    width: 100%;
    padding-left: 0;
    font-size: 20px;
  }
}
.adult-swiper {
  width: 100%;
  max-width: 100%;
  margin: auto;
  padding: 2em;
  background-color: #fff;
  margin-top: 1em;
}
.swiper-container {
  width: 100%;
  background-image: url('@/assets/images/accre_bg.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 15px; 
  overflow: hidden;
}
.swiper-slide {
  color: #fff;
  text-align: center;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 300px; 
}
.swiper-slide p {
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  padding: 1em;
}
.review-stars {
  color: #ff9800;
  font-size: 2.5rem;
}
.review-by {
  margin-top: 15px;
  font-weight: bold;
  font-size: 1.5rem; 
  color: #fff;
  text-decoration: underline;
  text-decoration-color: #ff9800;
  text-decoration-thickness: 2px;
  text-underline-offset: 10px;
  font-family: 'DIN Next LT Arabic', sans-serif;
}

.promise-sec3-space{
  padding: 2% 5% 2% 5%; 
  background-color: #fff;
}
.promise-sec3 {
  position: relative;
  width: 100%;
  height: 60vh;
  background-image: url('@/assets/images/promise-sec3.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 25px;
  overflow: hidden;
  flex-direction: row; 
}
.promise-sec3-inner {
  width: 100%;
  box-sizing: border-box;
  padding-right: 5%;
}
.text-content-promise {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: right;
}
.text-content-promise h1 {
  font-size: 3em;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  text-align: right;
}
.text-content-promise p {
  font-size: 1.3em;
  max-width: 45%;
  line-height: 1.6;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
}
@media (max-width: 768px) {
  .promise-sec3 {
    flex-direction: column; 
    height: auto; 
  }
  .text-content-promise h1{
    font-size: 28px;
  }
  .text-content-promise p {
    max-width: 100%; 
    font-size: 20px;
  }
.swiper-slide {
  font-size: 1em;
  min-height: 100px; 
}
.swiper-slide p {
  padding: 1em;
}
.review-stars {
  font-size: 2rem;
}
.review-by {
  margin-top: 5px;
  font-weight: bold;
  font-size: 1rem; 
}
}

.vision-sec5 {
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100%;
  position: relative;
  align-items: center;
  overflow: hidden;
  font-family: 'DIN Next LT Arabic', sans-serif;
  padding: 2em 1em;
  direction: ltr;
}
.vision-sec5 h1 {
  color: #165e84;
  font-size: 2.8em;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
  margin: 1%;
}
@media (max-width: 768px) {
  .vision-sec5 h1 {
    font-size: 28px;
  }
}
</style>
