<template>
  <div class="teacher_page">
    <div class="teacher_container">
      <div class="teacher_image">
        <img src="@/assets/images/teachers/7.png" alt="Teacher Walaa">
      </div>
      <div class="teacher_info">
        <h1 class="teacher_greeting">أهلا, انا</h1>
        <h2 class="teacher_name">حلمي عبد العاطي</h2>
        <p class="teacher_description">
مرحبًا بالجميع .. أنا حلمي عبد العاطي.. معلم لغة إنجليزية في أكاديمية مونجلش الدولية.. أيضًا خريج منحة فولبرايت فقد حصلت على منحة معيدي تدريس اللغات الأجنبية و اتيحت لي الفرصة للسفر إلي الولايات المتحدة الأمريكية و قضاء سنة دراسية كمعيد في جامعة "Washington and Lee".. في حصصي الخاصة يمارس الطلاب اللغة الانجليزية بشكل كبير في بيئة صحية خالية من القلق.. بالإضافة لذلك دائما ما يتم ممارسة أنشطة أداء أدوار ومسابقات وألعاب عن بعد والعديد من الطرق المتنوعة للتفاعل وبالتالي لا يشعر أي طالب بالملل خلال الدرس أو الحصة.        </p>
        <div class="teacher_stats">
          <div>
            <span class="stat_number">86</span>
            <span class="stat_label">طالب</span>
          </div>
          <div>
            <span class="stat_number">14,487</span>
            <span class="stat_label">حصة المنتهية</span>
          </div>
        </div>
        <div class="teacher_tags">
          <span>ELM Holder</span>
          <span>Fulbright Alumnus</span>
        </div>
        <button class="subscribe_button" @scroll-to-packages="scrollToPackages" @click="$emit('scroll-to-packages')">اشترك معنا الآن</button>
      </div>
    </div>
  </div>

<PackagesAr/>

<div class="promise-sec3-space">
      <div class="promise-sec3">
        <div class="promise-sec3-inner">
          <div class="text-content-promise">
            <h3>نحرص دائما علي تطوير مهارات معلمينا</h3>
            <h1>للوصول لافضل مستوي ممكن </h1>
            <p>
               لذالك يقام تدريب CPD كل أسبوع لكل المدرسين في BRITISHY
            </p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import PackagesAr from '../../views/ar/PackagesPage.vue';
export default {
  name: 'TeacherPage',
  components: {
      PackagesAr
    },
    methods: {
      scrollToPackages() {
        this.$refs.packagesAr.$el.scrollIntoView({ behavior: 'smooth' });
      }
    }
};
</script>

<style scoped>
.teacher_page {
  background-image: url('@/assets/images/faq_bg.jpg');
  background-size: cover;
  background-position: center;
  height: auto;
  margin-top: -85px;
  padding-top: 105px;
  background-color: white;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  direction: rtl;
}
.teacher_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  flex-wrap: wrap;
}
.teacher_info {
  color: white;
  flex: 1 1 70%; 
}
.teacher_image {
  flex: 1 1 30%;
  margin-bottom: 20px;
}
.teacher_image img {
  width: 90%;
}
.teacher_greeting {
  font-size: 2.5rem;
  line-height: 80%;
}
.teacher_name {
  font-size: 3rem;
  margin: 20px 0 25px 0;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  line-height: 80%;
}
.teacher_description {
  font-size: 1.2rem;
  margin-bottom: 20px;
  line-height: 1.8;
}
.teacher_stats {
  display: flex;
  gap: 40px;
  margin: 20px 0;
}
.stat_number {
  font-size: 2rem;
  display: block;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.stat_label {
  font-size: 1rem;
  color: #fff;
}
.teacher_tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}
.teacher_tags span {
  background: transparent;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  border: 1px solid #fff;
}
.subscribe_button {
  padding: 12px;
  background: linear-gradient(45deg, #fe9b4f, #f47e23);
  color: white;
  border: none;
  border-radius: 25px;
  width: 30%;
  cursor: pointer;
  font-size: 20px;
}
@media (max-width: 1024px) {
  .teacher_container {
    flex-direction: column; 
  }
  .teacher_info, .teacher_image {
    max-width: 100%;
    flex: 1 1 100%;
  }
  .subscribe_button {
    width: 50%; 
  }
}
@media (max-width: 768px) {
  .teacher_greeting {
    font-size: 1.8rem;
    text-align: center;
  }
  .teacher_name {
    font-size: 2.2rem;
    text-align: center;
  }
  .teacher_description {
    text-align: center;
    font-size: 1rem;
  }
  .stat_number {
    font-size: 1.8rem;
  }
  .stat_label {
    font-size: 0.9rem;
  }
  .subscribe_button {
    width: 60%; 
  }
}
@media (max-width: 480px) {
  .teacher_greeting {
    font-size: 1.5rem;
  }
  .teacher_name {
    font-size: 2rem;
  }
  .teacher_description {
    font-size: 0.9rem;
  }
  .stat_number {
    font-size: 1.5rem;
  }
  .stat_label {
    font-size: 0.8rem;
  }
  .subscribe_button {
    width: 80%; 
  }
}

.promise-sec3-space{
  padding: 2% 5% 2% 5%; 
  background-color: #fff;
}
.promise-sec3 {
  position: relative;
  width: 95%;
  height: 65vh;
  background-image: url('@/assets/images/teacherpp.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 25px;
  overflow: hidden;
  flex-direction: row; 
  text-align: center;
  background-color: #fff;
}
.promise-sec3-inner {
  width: 100%;
  box-sizing: border-box;
  padding-right: 5%;
}
.text-content-promise {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: right;
}
.text-content-promise h3 {
  font-size: 2em;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
}
.text-content-promise h1 {
  font-size: 3em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.text-content-promise p {
  font-size: 1.1em;
  max-width: 45%;
  margin-top: 15px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
}
@media (max-width: 768px) {
  .promise-sec3 {
    flex-direction: column; 
    height: auto; 
  }
  .text-content-promise p {
    max-width: 100%; 
  }
}
</style>
