<template>
    <div class="terms-section">
        <h1 class="title">Privacy Policy
        </h1>
        <p>
            Last Updated On 20-Apr-2023 Effective Date 20-Apr-2023

            This Privacy Policy describes the policies of Monglish Academy UK Ltd., 71–75 Shelton Street, Covent Garden,
            UK WC2H 9JQ, United Kingdom of Great Britain and Northern Ireland (the), email: office@monglish.uk, phone:
            +442038850766 on the collection, use and disclosure of your information that we collect when you use our

            website ( https://www.monglish.co.uk ).

            (the “Service”). By accessing or using the Service, you are consenting to the collection, use and disclosure
            of your information in accordance with this Privacy Policy. If you do not consent to the same, please do not
            access or use the Service. We may modify this Privacy Policy at any time without any prior notice to you and
            will post the revised Privacy Policy on the Service. The revised Policy will be effective 180 days from when
            the revised Policy is posted in the Service and your continued access or use of the Service after such time
            will constitute your acceptance of the revised Privacy Policy. We therefore recommend that you periodically
            review this page. Information We Collect:
        </p>

        <h2 class="sub-title">We will collect and process the following personal information about you:

        </h2>
        <ul class="list">
            <li>Name</li>
            <li>Email</li>
            <li>Mobile</li>
            <li>Payment Info.</li>
        </ul>

        <h2 class="sub-title">How we collect your information:</h2>

        <h2 class="sub-title">We collect/receive information about you in the following manner:</h2>
        <ul class="list">
            <li>When a user fills up the registration form or otherwise submits personal information</li>
            <li>Interacts
                with the website</li>
            <li>From
                public sources</li>
        </ul>

        <h2 class="sub-title">How we use your information:</h2>

        <h2 class="sub-title">We will use the information that we collect about you for the following purposes:

        </h2>
        <ul class="list">
            <li>Marketing/ Promotional</li>
            <li>Creating user account</li>
            <li>Testimonials</li>
            <li>Customer feedback collection</li>
            <li>Enforce T&amp;C</li>
            <li>Processing payment</li>
            <li>Support</li>
            <li>Administration info</li>
            <li>Targeted advertising</li>
            <li>Manage customer order</li>
            <li>Manage user account</li>
        </ul>

        <p>
            If we want to use your information for any other purpose, we will ask you for consent and will use your
            information only on receiving your consent and then, only for the purpose(s) for which grant consent unless
            we are required to do otherwise by law. </p>

        <h2 class="sub-title">How we share your information:
        </h2>

        <h2 class="sub-title">We will not transfer your personal information to any third party without seeking your
            consent, except in limited circumstances as described below:

        </h2>
        <ul class="list">
            <li><span data-sheets-root="1" data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Ad service&quot;}"
                    data-sheets-userformat="{&quot;2&quot;:8961,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0,&quot;16&quot;:10}">Ad
                    service</span></li>
            <li><span data-sheets-root="1" data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Analytics&quot;}"
                    data-sheets-userformat="{&quot;2&quot;:8961,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0,&quot;16&quot;:10}">Analytics</span>
            </li>
            <li>Data collection &amp; process</li>
        </ul>
        <p>
            We require such third party’s to use the personal information we transfer to them only for the purpose for
            which it was transferred and not to retain it for longer than is required for fulfilling the said purpose
            to:-
            <br> (1) Comply with applicable law, regulation, court order or other legal process;
            <br>(2) Enforce your agreements with us, including this Privacy Policy;
            <br>(3) Respond to claims that your use of the Service violates any third-party rights. If the Service or
            our company is merged or acquired with another company, your information will be one of the assets that is
            transferred to the new owner.
        </p>

        <h2 class="sub-title">Retention of your information:
        </h2>
        <p>
            We will retain your personal information with us for 90 days to 2 years after user accounts remain idle or
            for as long as we need it to fulfill the purposes for which it was collected as detailed in this Privacy
            Policy.

            We may need to retain certain information for longer periods such as record-keeping / reporting in
            accordance with applicable law or for other legitimate reasons like enforcement of legal rights, fraud
            prevention, etc.

            Residual anonymous information and aggregate information, neither of which identifies you (directly or
            indirectly), may be stored indefinitely. </p>

        <h2 class="sub-title">Your Rights
            :</h2>
        <p>
            Depending on the law that applies, you may have a right to access and rectify or erase your personal data or
            receive a copy of your personal data, restrict or object to the active processing of your data, ask us to
            share (port) your personal information to another entity, withdraw any consent you provided to us to process
            your data, a right to lodge a complaint with a statutory authority and such other rights as may be relevant
            under applicable laws. To exercise these rights, you can write to us at office@monglish.uk. We will respond
            to your request in accordance with applicable law.

            You may opt-out of direct marketing communications or the profiling we carry out for marketing purposes by
            writing to us at office@monglish.uk.

            Do note that if you do not allow us to collect or process the required personal information or withdraw the
            consent to process the same for the required purposes, you may not be able to access or use the services for
            which your information was sought. </p>

        <h2 class="sub-title">Cookies Etc.
        </h2>
        <p>
            To learn more about how we use these and your choices in relation to these tracking technologies, please
            refer to our Cookie Policy here: Cookie Policy

        </p>

        <h2 class="sub-title">Third Party Links & Use Of Your Information:
        </h2>
        <p>
            Our Service may contain links to other websites that are not operated by us.

            This Privacy Policy does not address the privacy policy and other practices of any third parties, including
            any third party operating any website or service that may be accessible via a link on the Service.

            We strongly advise you to review the privacy policy of every site you visit. We have no control over and
            assume no responsibility for the content, privacy policies or practices of any third party sites or
            services. </p>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy',
};
</script>

<style scoped>
.terms-section {
    direction: ltr;
    width: 80%;
    margin: auto;
    padding: 80px !important;
    border-radius: 20px;
    background: white;
    margin-top: -130px;
    margin-bottom: 100px;
}

.title {
    color: #165e84;
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.5em;
    margin-bottom: 10px;
}

.sub-title {
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 25px;
}

.list {
    list-style: unset;
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 1.5em;
    margin-left: 30px;
}

@media only screen and (max-width: 768px) {
    .terms-section {
        padding: 20px !important;
    }

    .title {
        font-size: 28px;
    }
}
</style>