<template>
  <div class="register-container-en">
    <main class="register-main-en">
      <div class="register-card-en">
        <h2>Create a new account</h2>
        <form @submit.prevent="register" class="card-en">
          <div class="form-row-en">
            <div class="form-group-en">
              <label for="email">Email</label>
              <input type="email" id="email" v-model="email" placeholder="example@example.com"/>
              <p v-if="errors.name" class="error-en">{{ errors.name }}</p>
            </div>
            <div class="form-group-en">
              <label for="name">Name</label>
              <input type="text" id="name" v-model="name" placeholder="example"/>
              <p v-if="errors.email" class="error-en">{{ errors.email }}</p>
            </div>
          </div>  
          <div class="form-row-en">
            <div class="form-group-en">
              <label for="confirm-password">Confirm password</label>
              <input type="password" id="confirm-password" v-model="confirmPassword" placeholder="please confirm password">
              <p v-if="errors.conf_password" class="error-en">{{ errors.conf_password }}</p>
            </div>
            <div class="form-group-en">
              <label for="password">Password</label>
              <input type="password" id="password" v-model="password" placeholder="********">
              <p v-if="errors.password" class="error-en">{{ errors.password }}</p>
            </div>
          </div>
          <div class="form-row-en">
            <div class="form-group-en countries">
              <label for="country">Country</label> 
              <select id="country" v-model="country"> 
                <option disabled value="">Choose your country</option> 
                <option disabled value="">Egypt</option> 
                <option disabled value="">Saudi Arabia</option> 
                <option disabled value="">Bahrain</option> 
                <option disabled value="">Qatar</option>
                <option disabled value="">Kuwait</option>
                <option disabled value="">UAE</option>
              </select>
              <p v-if="errors.country" class="error-en">{{ errors.country }}</p>
            </div>
            <div class="form-group-en">
              <label for="phone">Phone number</label>
              <input type="text" id="phone" v-model="phone" placeholder="0096600000"/>
              <p v-if="errors.phone" class="error-en">{{ errors.phone }}</p>
            </div>
          </div>
          <div class="form-group-en terms-en">
            <a href="#">I agree to all terms and conditions</a>
            <input type="checkbox" id="terms" v-model="terms" />
            <p v-if="errors.terms" class="error-en">{{ errors.terms }}</p>
          </div>
          <button type="submit" class="register-button-en">Sign up</button>
        </form>
        <div class="signup-link-en">
          <p>Have an account? <a href="/login">Sign in</a></p>
        </div>
      </div>
      <div class="register-info-container-en">
        <div class="register-info-h2-en">
          <h2>Create a new account</h2>
        </div>
        <div class="register-info-en">
          <h1 class="register-info-h1-desktop-en">Your path to <br><strong>fluency</strong></h1>
          <h1 class="register-info-h1-mobile-en">Your path to fluency</h1>
          <p>Need help? You can contact us during</p>
          <button class="whatsapp-button-en">Chat with us 
            <svg class="whatsapp-svg-en" width="18px" height="18px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier"> 
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 31C23.732 31 30 24.732 30 17C30 9.26801 23.732 3 16 3C8.26801 3 2 9.26801 2 17C2 19.5109 2.661 21.8674 3.81847 23.905L2 31L9.31486 29.3038C11.3014 30.3854 13.5789 31 16 31ZM16 28.8462C22.5425 28.8462 27.8462 23.5425 27.8462 17C27.8462 10.4576 22.5425 5.15385 16 5.15385C9.45755 5.15385 4.15385 10.4576 4.15385 17C4.15385 19.5261 4.9445 21.8675 6.29184 23.7902L5.23077 27.7692L9.27993 26.7569C11.1894 28.0746 13.5046 28.8462 16 28.8462Z" fill="#BFC8D0"></path> 
                <path d="M28 16C28 22.6274 22.6274 28 16 28C13.4722 28 11.1269 27.2184 9.19266 25.8837L5.09091 26.9091L6.16576 22.8784C4.80092 20.9307 4 18.5589 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z" fill="url(#paint0_linear_87_7264)"></path> 
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 18.5109 2.661 20.8674 3.81847 22.905L2 30L9.31486 28.3038C11.3014 29.3854 13.5789 30 16 30ZM16 27.8462C22.5425 27.8462 27.8462 22.5425 27.8462 16C27.8462 9.45755 22.5425 4.15385 16 4.15385C9.45755 4.15385 4.15385 9.45755 4.15385 16C4.15385 18.5261 4.9445 20.8675 6.29184 22.7902L5.23077 26.7692L9.27993 25.7569C11.1894 27.0746 13.5046 27.8462 16 27.8462Z" fill="white"></path> 
                <path d="M12.5 9.49989C12.1672 8.83131 11.6565 8.8905 11.1407 8.8905C10.2188 8.8905 8.78125 9.99478 8.78125 12.05C8.78125 13.7343 9.52345 15.578 12.0244 18.3361C14.438 20.9979 17.6094 22.3748 20.2422 22.3279C22.875 22.2811 23.4167 20.0154 23.4167 19.2503C23.4167 18.9112 23.2062 18.742 23.0613 18.696C22.1641 18.2654 20.5093 17.4631 20.1328 17.3124C19.7563 17.1617 19.5597 17.3656 19.4375 17.4765C19.0961 17.8018 18.4193 18.7608 18.1875 18.9765C17.9558 19.1922 17.6103 19.083 17.4665 19.0015C16.9374 18.7892 15.5029 18.1511 14.3595 17.0426C12.9453 15.6718 12.8623 15.2001 12.5959 14.7803C12.3828 14.4444 12.5392 14.2384 12.6172 14.1483C12.9219 13.7968 13.3426 13.254 13.5313 12.9843C13.7199 12.7145 13.5702 12.305 13.4803 12.05C13.0938 10.953 12.7663 10.0347 12.5 9.49989Z" fill="white"></path> 
                <defs> 
                  <linearGradient id="paint0_linear_87_7264" x1="26.5" y1="7" x2="4" y2="28" gradientUnits="userSpaceOnUse"> 
                    <stop stop-color="#5BD066"></stop> 
                    <stop offset="1" stop-color="#27B43E"></stop> 
                  </linearGradient> 
                </defs> 
              </g>
            </svg>
          </button>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  import { ref } from 'vue';
  import axios from 'axios';
  import { useRouter } from 'vue-router';

  export default {
    setup() {
      const router = useRouter();
      const name = ref('');
      const email = ref('');
      const password = ref('');
      const conf_password = ref('');
      const phone = ref('');
      const country = ref('');
      const terms = ref(false);
      const errors = ref({ name: '', email: '', password: '', conf_password: '', phone: '', country: '', terms: '' });
      const errorMessage = ref('');
      const isSubmitting = ref(false);

      const validate = () => {
        // Clear previous errors
        errors.value = {
          name: '',
          email: '',
          password: '',
          conf_password: '',
          phone: '',
          country: '',
          terms: '',
        };

        // Basic validation checks
        errors.value.name = name.value.trim() === '' ? 'Name is required' : '';
        errors.value.email = email.value.trim() === '' ? 'Email address is required' : '';
        errors.value.password = password.value.trim() === '' ? 'Password is required' : '';
        errors.value.conf_password = conf_password.value.trim() === '' ? 'Confirm password is required' : '';
        errors.value.phone = phone.value.trim() === '' ? 'Phone is required' : '';
        errors.value.country = country.value.trim() === '' ? 'Country is required' : '';
        errors.value.terms = !terms.value ? 'Please choose the terms and conditions' : '';

        // Check if passwords match
        if (password.value !== conf_password.value) {
          errors.value.conf_password = 'Password and confirm password do not match';
        }

        // Return true if no errors
        return !Object.values(errors.value).some(error => error !== '');
      };

      const register = async () => {
        if (!validate()) return;

        isSubmitting.value = true;
        errorMessage.value = '';

        const data = {
          name: name.value,
          email: email.value,
          password: password.value,
          conf_password: conf_password.value,
          country: country.value,
          phone: phone.value,
          terms: terms.value
        };

        try {
          const response = await axios.post('/api/register', data);

          console.log('Register successful:', response.data);

          // Redirect to another page, e.g., login page
          router.push({ name: 'LoginAr' }); // Assuming you have a route named 'Login Page'
        } catch(error){
          errorMessage.value = error.response ? error.response.data.data.error : error;
        }  finally {
          isSubmitting.value = false;
        }
      };
      return { 
        register, 
        name, 
        email, 
        password, 
        conf_password, 
        phone, 
        country, 
        terms,
        errors,
        errorMessage,
        isSubmitting,
      }
    }
  }
</script>

<style scoped>
  /* Add your styles here */
  .error {
    color: red;
  }
</style>

<style scoped src="@/assets/styles/en/register.css"></style>