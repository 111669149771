<template>
  <div class="faq-section-n">
    <h3>Everything You Want to Know</h3>
    <h2>FAQ Section at Your Fingertips!</h2>
    <div class="faq-items-n">
      <div class="faq-items-sub">
      <div class="faq-item-min" v-for="(item, index) in faqItems_1" :key="index">
        <button @click="toggleAnswer(index)" :class="{ active: activeIndex === index }">
          {{ item.question }}
          <span :class="{ activeSpan: activeIndex === index }">
            <span v-if="activeIndex === index">-</span>
            <span v-else>+</span>
          </span>
        </button>
        <transition name="fade">
          <div v-show="activeIndex === index" :class="['answer', { 'answer-active': activeIndex === index }]">
            {{ item.answer }}
          </div>
        </transition>
      </div>
      </div>
      <div class="faq-items-sub">
      <div class="faq-item-min" v-for="(item, index) in faqItems_2" :key="index + 3">
        <button @click="toggleAnswer(index + 3)" :class="{ active: activeIndex === index + 3 }">
          {{ item.question }}
          <span :class="{ activeSpan: activeIndex === index + 3 }">
            <span v-if="activeIndex === index + 3">-</span>
            <span v-else>+</span>
          </span>
        </button>
        <transition name="fade">
          <div v-show="activeIndex === index + 3" :class="['answer', { 'answer-active': activeIndex === index + 3 }]">
            {{ item.answer }}
          </div>
        </transition>
      </div>
      </div>
    </div>
    <MainButton @click="navigateToFAQ" class="my-5">More</MainButton>
  </div>
</template>

<script>
import MainButton from '../Elements/MainButton.vue';

export default {
  components: {
    MainButton
  },
  name: 'ViewThree',
  data() {
    return {
      activeIndex: null,
      faqItems_1: [
        {
          question: 'Shyness and inability to interact with others',
          answer: 'Classes enhance the student\'s self-confidence and ability to interact with others from different nationalities and ages.'
        },
        {
          question: 'Boring curricula and textbooks',
          answer: 'We offer an innovative curriculum that inspires and gives a deep understanding without the need for many books.'
        },
        {
          question: 'My English is very weak, can I understand the teacher?',
          answer: 'You will be able to understand the teacher clearly and you will be placed at the level that is perfectly suited to you.'
        },
      ],
      faqItems_2: [
        {
          question: 'Fatigue from too much homework',
          answer: 'We use smart teaching methods and a variety of homework that students can complete on their own without parental help, such as (electronic question bank – audible and readable audio assignments – audio or video recordings and other methods) reviewed through the Monglish app.'
        },
        {
          question: 'I don\'t like online learning',
          answer: 'Distance learning at Monglish combines flexibility and comfort, using modern technologies to enhance interaction and provide an effective and comprehensive learning experience, saving time and costs while ensuring quality learning.'
        },
        {
          question: 'I want individual lessons',
          answer: 'Monglish offers weekly individual reading skill lessons with a professional teacher. Considering that English is a communication language and is learned through groups with a limited number (6 to 8 individuals).'
        }
      ]
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    navigateToFAQ() {
      this.$router.push('/ar/faq');
    }
  },
};
</script>

<style src="@/assets/styles/ar/ViewThree.css" scoped></style>

