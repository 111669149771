<template>
  <div>
    <ImageVideoPopup />
  </div>
</template>

<script>
import ImageVideoPopup from '@/components/ar/ImageVideoPopup.vue';

export default {
  components: {
    ImageVideoPopup,
  },
};
</script>
