<template>
  <div class="head-container">
    <h1 class="blog-headline">بوابتك لعالم مونجلش</h1>
    <div class="blog-grid">
      <div 
        v-for="(post, index) in blogPosts" 
        :key="post.id" 
        :class="['blog-card', { 'first-card': index === 0, 'regular-card': index !== 0 }]" 
        @click="navigateTo(post.link)"
      >
        <img :src="post.image" alt="Blog Image" class="blog-image" />
        <div class="blog-info">
          <div class="blog-lang">{{ post.language }}</div>
          <div class="blog-title">{{ post.title }}</div>
          <div class="blog-date">{{ post.Date }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlogList',
  data() {
    return {
      blogPosts: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: require('@/assets/images/blogs/ar-1.png'),
          link: '/en/blog/celebrating-childrens-language-progress',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: require('@/assets/images/blogs/ar-2.png'),
          link: '/en/blog/seasons-of-the-year',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 5,
          title: 'كيف اتعلم اللغة الانجليزية',
          image: require('@/assets/images/blogs/ar-3.jpg'),
          link: '/en/blog/activities-for-positive-behavior',
          Date: '29 مايو ,2024',
          language: 'ُقافة اللغة الانجليزية',
        },
        {
          id: 6,
          title: 'انجاز جديد فى مشوار دكتور النجار',
          image: require('@/assets/images/blogs/ar-4.png'),
          link: '/en/blog/benefits-of-bilingual-for-children',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
        {
          id: 3,
          title: 'الاحتفال الشهرى بالموظف المثالى',
          image: require('@/assets/images/blogs/ar-5.png'),
          link: '/en/blog/benefits-of-bilingual-for-children/',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
      ], 
    };   
  },
  methods: {
    navigateTo(link) {
      this.$router.push(link);
    }
  }
};
</script>

<style scoped>
.head-container {
  background-image: url('@/assets/images/blogs/bg.jpg');
  background-size: cover;
  background-position: center;
  height: 60vh;
  margin-top: -85px;
  padding-top: 105px;
  text-align: center;
  direction: rtl;
}

.blog-headline {
  color: white;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  font-size: 42px;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3px;
  margin: 20px auto;
  padding: 10px;
  max-width: 1200px;
}

.blog-card {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  height: 260px;
}

.blog-card.first-card {
  grid-column: span 2;
  height: 260px; 
}

.blog-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-info {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: white;
  width: 100%;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #165E84 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 5px;
}

.blog-lang {
  font-size: 14px;
}

.blog-title {
  font-size: 19px;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}

.blog-date {
  font-size: 14px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .head-container {
    height: auto;
    padding-top: 20px;
    margin-top: 0;
  }

  .blog-headline {
    font-size: 28px;
    padding: 10px;
  }

  .blog-grid {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 5px;
  }

  .blog-card {
    height: 200px;
  }

  .blog-card.first-card {
    grid-column: span 1;
    height: 200px;
  }

  .blog-info {
    padding: 5px;
  }

  .blog-title {
    font-size: 16px;
  }

  .blog-date, .blog-lang {
    font-size: 12px;
  }
}
</style>