<template>
  <section class="home-reasons">
    <div class="content">
      <div class="t1">12 سبب <br>لإختيارك لمونجلش</div>
      <div class="t2">تعلم اللغة الإنجليزية يفتح الأبواب لغرض وظيفية وتواصل دولي فعال.</div>
      <div class="one-line gap-3">
        <MainButton @click="goToPackages">قائمة الأسعار</MainButton>
        <a href="/ar/monglish-reasons/">
          <button class="info-btn one-line">
            المزيد من المعلومات
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M5 12H19M5 12L11 6M5 12L11 18" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </g>
            </svg>
          </button>
        </a>
      </div>
    </div>
    <div class="carousel-container">
      <button @click="next" class="carousel-nav2 nexttttt">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
      </button>
      <Carousel
          ref="carousel"
          :itemsToShow="itemsToShow"
          :wrapAround="true"
          :mouseDrag="true"
          :autoplay="3000"
          :transition="500"
          :gap="20"
      >
        <Slide v-for="(reason, index) in reasons" :key="index">
          <img :src="reason.img" alt="Reason Image" class="reason-image" />
        </Slide>
      </Carousel>
      <button @click="prev" class="carousel-nav2 prevvvvv">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </button>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import MainButton from "@/components/Elements/MainButton.vue";

export default {
  components: {
    Carousel,
    Slide,
    MainButton
  },
  data() {
    return {
      reasons: [
        { img: require('@/assets/images/reason/1.jpg') },
        { img: require('@/assets/images/reason/2.jpg') },
        { img: require('@/assets/images/reason/3.jpg') },
        { img: require('@/assets/images/reason/4.jpg') },
        { img: require('@/assets/images/reason/5.jpg') },
        { img: require('@/assets/images/reason/6.jpg') },
        { img: require('@/assets/images/reason/7.jpg') },
        { img: require('@/assets/images/reason/8.jpg') },
        { img: require('@/assets/images/reason/9.jpg') },
        { img: require('@/assets/images/reason/10.jpg') },
        { img: require('@/assets/images/reason/11.jpg') },
        { img: require('@/assets/images/reason/12.jpg') },
      ]
    };
  },
  computed: {
    itemsToShow() {
      return window.innerWidth <= 700 ? 1 : 3;
    }
  },
  methods: {
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    goToPackages(){
      this.$router.push({ name: 'PackagesAr' });
    }
  }
};
</script>

<style src="@/assets/styles/ar/Reasons.css" scoped></style>