<template>
  <div id="app">
    <Header />
    <JobList />
  </div>
</template>

<script>
import Header from '../../components/en/ViewFour.vue';
import JobList from '../../components/en/ViewFive.vue';

export default {
  components: {
    Header,
    JobList,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');

body {
  margin: 0;
  background-color: #eef2f7;
  direction: ltr;
}
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}
</style>
