<template>
  <div class="faq_page">
    <div class="faq_text">
      <h3>Overall Guide</h3>
      <h1>FAQs</h1>
      <p>Get All The Information You Need</p>
    </div>
  </div>

  <div class="faq-total-items">
    <ThreePart1 />
    <ThreePart2 />
  </div>
</template>

<script>
import ThreePart1 from '../../components/en/ThreePart1.vue';
import ThreePart2 from '../../components/en/ThreePart2.vue';

export default {
  name: 'FAQPage',
  components: {
    ThreePart1,
    ThreePart2
  },
}
</script>

<style src="@/assets/styles/ar/faq.css" scoped></style>