<template>
    <div class="security-policy">
        <h1 class="title">Cookie Policy

        </h1>
        <p> When making transactions, we use a secure server. The Secure Sockets Layer (SSL) protocol encrypts all
            information entered before it is sent to us. Additionally, all sensitive customer data we collect is
            protected by multiple layers of encryption and various security measures to prevent unauthorized access.

            Our payment gateway, Stripe, has achieved Payment Card Industry Data Security Standard (PCI DSS) compliance.
            You can find all of Stripe’s agreements at
            [https://stripe.com/en-gb/legal/ssa](https://stripe.com/en-gb/legal/ssa). </p>
        <h2 class="sub-title">

            Credit Card Details / Information


        </h2>
        <p>All card payments are securely processed through the Stripe payment gateway.

            Monglish Academy does not store any card information on its servers.</p>

        <h2 class="sub-title">Payment Information

        </h2>
        <p>
            When submitting or updating your payment information initially, It is done directly with Stripe as the
            payment processor.

            The payment processor uses and processes your payment information according to their privacy policy.

            Monglish Academy does not store your payment information, except for the postal code and country, which we
            need for invoicing and compliance with government regulations.
        </p>
        <h2 class="sub-title"> End User Payment Information

        </h2>
        <p>
            Your end user payment information may be processed through the Stripe payment processor in accordance with
            Stripe’s terms and policies. We do not collect or store your end user payment information.
        </p>
        <h2 class="sub-title">Your Consent / Acceptance / Accept

        </h2>
        <p>By using our website, you agree to the collection and use of this information. If we decide to change our
            privacy policy, we will post those changes to keep you aware of what information we collect, how we use it,
            and under what circumstances we disclose it.



        </p>
        <h2 class="sub-title">The Payment Gateway Information

        </h2>
        <p>
            We offer discount and card payments for all our users. Card payments are processed directly through the SSL
            256 encryption protocol to ensure the security of your personal data. Once again, none of your card details
            are stored.

        </p>

    </div>
</template>


<script>
export default {
    name: "SecurityPolicy"
};
</script>
<style scoped>
.security-policy {
    direction: ltr;
    width: 80%;
    margin: auto;
    padding: 80px !important;
    border-radius: 20px;
    background: white;
    box-shadow: 0 4 25 0 rgba(0, 0, 0, 0.2) !important;
    margin-top: -130px;
    margin-bottom: 100px;
}

.title {
    color: #165e84;
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.5em;
    margin-bottom: 10px;
}

.sub-title {
    color: #434343;
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.5em;

}

@media only screen and (max-width: 768px) {
    .security-policy {
        padding: 20px !important;
    }

    .title {
        font-size: 28px;
    }

}
</style>
