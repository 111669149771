<template>
    <router-link :to="post.link" class="blog-card">
      <div class="label">Un Categorized</div>
      <img :src="post.image" alt="Blog Image" class="blog-card-image" />
      <h3 class="blog-card-title">{{ post.title }}</h3>
    </router-link>
  </template>
  
  <script>
  export default {
    name: 'BlogCard',
    props: {
      post: {
        type: Object,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .blog-card {
    position: relative;
    display: block;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    color: inherit;
    transition: box-shadow 0.3s ease;
    font-family: 'DIN Next LT Arabic';
  }
  
  .blog-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-card-title {
    margin: 16px;
    font-size: 1rem;
    text-align: left;
  }
  .label {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.875rem;
}
  </style>
  