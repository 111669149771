<template>
  <header>
    <ViewSix />
    <ViewSeven />
  </header>
</template>

<script>
import ViewSix from '../../components/en/ViewSix.vue'; 
import ViewSeven from '../../components/en/ViewSeven.vue'; 

export default {
  name: 'ContactUsPage',
  components: {
    ViewSix,
    ViewSeven
  },
}
</script>

<style scoped>
header{
  background-color: white;
}
</style>