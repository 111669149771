<template>
  <header>
    <!-- Navbar content -->
  </header>
</template>

<script>
export default {
  // Component options
}
</script>

<style scoped>
/* Component-specific styles */
</style>
