<template>
  <div class="packages">
    <div class="packages-title-n">
      <h3>قائمة الأسعار</h3>
      <h1>تعليم متقن، استثمار ذكي</h1>
    </div>
    <!-- Filter Switch -->
    <div class="packages-filter-switch">
      <div class="label-out">
        <span>أطفال</span>
        <span>بالغين</span>
      </div>
      <label class="switch">
        <input type="checkbox" @change="toggleFilter" :checked="selectedFilter === 'adults'" />
        <span class="slider">
          <span class="label label-left">بالغين</span>
          <span class="label label-right">أطفال</span>
        </span>
      </label>
    </div>

    <!-- Package Duration Selection (Only for Kids) -->
    <div class="packages-menu-n" v-if="selectedFilter === 'kids'">
      <ul>
        <li @click="selectMenu('two-months')" :class="{ selected: selectedMenu === 'two-months' }">شهرين</li>
        <li @click="selectMenu('four-months')" :class="{ selected: selectedMenu === 'four-months' }">4 أشهر</li>
        <li @click="selectMenu('six-months')" :class="{ selected: selectedMenu === 'six-months' }">6 أشهر</li>
      </ul>
    </div>

    <!-- Kids Packages -->
    <div v-if="selectedFilter === 'kids'">
      <div class="packages-cards"  v-if="selectedMenu === 'two-months'">
        <!-- Two Months Cards -->
        <div class="package-card">
          <div class="card-header">
             <h3>المعايشة بلس<span> (شهرين) </span></h3>
             <p class="badge"><span>الأفضل <br><bold>قيمة</bold></span></p>
          </div>

          <div class="card-body">
            <p>“المعيشة بلس” هي باقتك المثالية، تقدم أقصى قيمة مقابل السعر بميزات فريدة وخدمة شاملة تضمن راحتك.</p>
            <h2>{{ prices[3]?.price }}</h2>
            
            <h6>واستمتع بالاتي:</h6>
            <ul>
              <li>حصص أســـــــاسية:<span> 16 حصة</span></li>
              <li>نادى القـــــــــــــــــراءة:<span> 8 حصة (مرة أسبوعيا)</span></li>
              <li>نادى الســــــــــــــينما:<span> 8 حصة</span></li>
              <li>نادى المــــــــــــحادثة:<span> (يوميا)</span></li>
              <li>نادى المـــــــناظرات:<span> (مرة شهريا)</span></li>
              <li>نادى المـــــــــواهب:<span> (مرة أسبوعيا)</span></li>
              <li>نادى الرســـــــــــــــــــم:<span> (مرة أسبوعيا)</span></li>
              <li>نادى التربـــــــــــــــوي:<span> 2 محاضرات (لولي الامر)</span></li>
              <li>نادى الصوتــــــــــيات:<span> (بطلب من المدرس)</span></li>
              <li>نادى الكتــــــــــــــــــــابة:<span> (مرة أسبوعيا)</span></li>
              <li>نادى لايف كوتش:<span> 2 جلسات (فردية)</span></li>
              <li>متابعة يومـــــــــــــــية</li>
              <li>تقاريــــــر شهريـــــــــــة</li>
            </ul>
            <button @click="addToCart(prices[3]?.packageId)">شراء</button>
          </div>
        </div>

        <div class="package-card">
          <div class="card-header">
            <h3>المعايشة<span> (شهرين)</span></h3>
             <p class="badge"><span>الأفضل <br><bold>مبيعاً</bold></span></p>
          </div>
          <div class="card-body">
            <p>باقة “المعيشة” توفر العديد من الميزات وهي الخيار المعتاد لعملائنا، تضمن الراحة والخدمات المتميزة بكفاءة.</p>
            <h2>{{ prices[2]?.price }}</h2>
            
            <h6>واستمتع بالاتي:</h6>
            <ul>
              <li>حصص أســـــــاسية:<span> 16 حصة</span></li>
              <li>نادى القـــــــــــــــــراءة:<span> 8 حصة (مرة أسبوعيا)</span></li>
              <li>نادى الســــــــــــــينما:<span> 8 حصة</span></li>
              <li>نادى المــــــــــــحادثة:<span> (يوميا)</span></li>
              <li>نادى المـــــــناظرات:<span> (مرة شهريا)</span></li>
              <li>نادى لايف كوتش:<span> جلسة (فردية)</span></li>
              <li>متابعة يومـــــــــــــــية</li>
              <li>تقاريــــــر شهريـــــــــــة</li>
            </ul>
            <button @click="addToCart(prices[2]?.packageId)">شراء</button>
          </div>
        </div>
        <div class="package-card">
          <div class="card-header">
            <h3>أساسية<span> (شهرين)</span></h3>
          </div>
          <div class="card-body">
            <p>باقة مونجلش الأساسية هي الأقل سعراً، ولكنها تحتوي على بعض ميزات دوراتنا التي توفر تجربة تعليمية مميزة.</p>
            <h2>{{ prices[1]?.price }}</h2>
            
            <h6>واستمتع بالاتي:</h6>
            <ul>
              <li>حصص أســـــــاسية:<span> 16 حصة</span></li>
              <li>نادى القـــــــــــــــــراءة:<span> 2 حصة</span></li>
              <li>نادى المــــــــــــحادثة:<span> (يوميا)</span></li>
              <li>نادى المـــــــناظرات:<span> (مرة شهريا)</span></li>
              <li>متابعة يومـــــــــــــــية</li>
              <li>تقاريــــــر شهريـــــــــــة</li>
            </ul>
            <button @click="addToCart(prices[1]?.packageId)">شراء</button>
          </div>
        </div>
      </div>

      <div class="packages-cards" v-else-if="selectedMenu === 'four-months'">
        <!-- Four Months Cards -->
        <div class="package-card">
          <div class="card-header">
            <h3>المعايشة بلس<span> (4 شهور)</span></h3>
             <p class="badge"><span>الأفضل <br><bold>قيمة</bold></span></p>
          </div>
          <div class="card-body">
            <p>“المعيشة بلس” هي باقتك المثالية، تقدم أقصى قيمة مقابل السعر بميزات فريدة وخدمة شاملة تضمن راحتك.</p>
            <h2>{{ prices[6]?.price }}</h2>
            
            <h6>واستمتع بالاتي:</h6>
            <ul>
              <li>حصص أســـــــاسية:<span> 16 حصة</span></li>
              <li>نادى القـــــــــــــــــراءة:<span> 8 حصة (مرة أسبوعيا)</span></li>
              <li>نادى الســــــــــــــينما:<span> 8 حصة</span></li>
              <li>نادى المــــــــــــحادثة:<span> (يوميا)</span></li>
              <li>نادى المـــــــناظرات:<span> (مرة شهريا)</span></li>
              <li>نادى المـــــــــواهب:<span> (مرة أسبوعيا)</span></li>
              <li>نادى الرســـــــــــــــــــم:<span> (مرة أسبوعيا)</span></li>
              <li>نادى التربـــــــــــــــوي:<span> 2 محاضرات (لولي الامر)</span></li>
              <li>نادى الصوتــــــــــيات:<span> (بطلب من المدرس)</span></li>
              <li>نادى الكتــــــــــــــــــــابة:<span> (مرة أسبوعيا)</span></li>
              <li>نادى لايف كوتش:<span> 2 جلسات (فردية)</span></li>
              <li>متابعة يومـــــــــــــــية</li>
              <li>تقاريــــــر شهريـــــــــــة</li>
            </ul>
            <button @click="addToCart(prices[6]?.packageId)">شراء</button>
          </div>
        </div>

        <div class="package-card">
          <div class="card-header">
            <h3>المعايشة<span> (4 شهور)</span></h3>
             <p class="badge"><span>الأفضل <br><bold>مبيعاً</bold></span></p>
          </div>
          <div class="card-body">
            <p>باقة “المعيشة” توفر العديد من الميزات وهي الخيار المعتاد لعملائنا، تضمن الراحة والخدمات المتميزة بكفاءة.</p>
            <h2>{{ prices[5]?.price }}</h2>
            
            <h6>واستمتع بالاتي:</h6>
            <ul>
              <li>حصص أســـــــاسية:<span> 16 حصة</span></li>
              <li>نادى القـــــــــــــــــراءة:<span> 8 حصة (مرة أسبوعيا)</span></li>
              <li>نادى الســــــــــــــينما:<span> 8 حصة</span></li>
              <li>نادى المــــــــــــحادثة:<span> (يوميا)</span></li>
              <li>نادى المـــــــناظرات:<span> (مرة شهريا)</span></li>
              <li>نادى لايف كوتش:<span> جلسة (فردية)</span></li>
              <li>متابعة يومـــــــــــــــية</li>
              <li>تقاريــــــر شهريـــــــــــة</li>
            </ul>
            <button @click="addToCart(prices[5]?.packageId)">شراء</button>
          </div>
        </div>

        <div class="package-card">
          <div class="card-header">
            <h3>أساسية<span> (4 شهور)</span></h3>
          </div>
          <div class="card-body">
            <p>باقة مونجلش الأساسية هي الأقل سعراً، ولكنها تحتوي على بعض ميزات دوراتنا التي توفر تجربة تعليمية مميزة.</p>
            <h2>{{ prices[4]?.price }}</h2>
            
            <h6>واستمتع بالاتي:</h6>
            <ul>
              <li>حصص أســـــــاسية:<span> 16 حصة</span></li>
              <li>نادى القـــــــــــــــــراءة:<span> 2 حصة</span></li>
              <li>نادى المــــــــــــحادثة:<span> (يوميا)</span></li>
              <li>نادى المـــــــناظرات:<span> (مرة شهريا)</span></li>
              <li>متابعة يومـــــــــــــــية</li>
              <li>تقاريــــــر شهريـــــــــــة</li>
            </ul>
            <button @click="addToCart(prices[4]?.packageId)">شراء</button>
          </div>
        </div>
      </div>

      <div class="packages-cards" v-else-if="selectedMenu === 'six-months'">
        <!-- Six Months Cards -->
        <div class="package-card">
          <div class="card-header">
            <h3>المعايشة بلس<span> (6 اشهر)</span></h3>
             <p class="badge"><span>الأفضل <br><bold>قيمة</bold></span></p>
          </div>
          <div class="card-body">
            <p>“المعيشة بلس” هي باقتك المثالية، تقدم أقصى قيمة مقابل السعر بميزات فريدة وخدمة شاملة تضمن راحتك.</p>
            <h2>{{ prices[8]?.price }}</h2>
            
            <h6>واستمتع بالاتي:</h6>
            <ul>
              <li>حصص أســـــــاسية:<span> 16 حصة</span></li>
              <li>نادى القـــــــــــــــــراءة:<span> 8 حصة (مرة أسبوعيا)</span></li>
              <li>نادى الســــــــــــــينما:<span> 8 حصة</span></li>
              <li>نادى المــــــــــــحادثة:<span> (يوميا)</span></li>
              <li>نادى المـــــــناظرات:<span> (مرة شهريا)</span></li>
              <li>نادى المـــــــــواهب:<span> (مرة أسبوعيا)</span></li>
              <li>نادى الرســـــــــــــــــــم:<span> (مرة أسبوعيا)</span></li>
              <li>نادى التربـــــــــــــــوي:<span> 2 محاضرات (لولي الامر)</span></li>
              <li>نادى الصوتــــــــــيات:<span> (بطلب من المدرس)</span></li>
              <li>نادى الكتــــــــــــــــــــابة:<span> (مرة أسبوعيا)</span></li>
              <li>نادى لايف كوتش:<span> 2 جلسات (فردية)</span></li>
              <li>متابعة يومـــــــــــــــية</li>
              <li>تقاريــــــر شهريـــــــــــة</li>
            </ul>
            <button @click="addToCart(prices[8]?.packageId)">شراء</button>
          </div>
        </div>

        <div class="package-card">
          <div class="card-header">
            <h3>المعايشة<span> (6 اشهر)</span></h3>
             <p class="badge"><span>الأفضل <br><bold>مبيعاً</bold></span></p>
          </div>
          <div class="card-body">
            <p>باقة “المعيشة” توفر العديد من الميزات وهي الخيار المعتاد لعملائنا، تضمن الراحة والخدمات المتميزة بكفاءة.</p>
            <h2>{{ prices[7]?.price }}</h2>
            
            <h6>واستمتع بالاتي:</h6>
            <ul>
              <li>حصص أســـــــاسية:<span> 16 حصة</span></li>
              <li>نادى القـــــــــــــــــراءة:<span> 8 حصة (مرة أسبوعيا)</span></li>
              <li>نادى الســــــــــــــينما:<span> 8 حصة</span></li>
              <li>نادى المــــــــــــحادثة:<span> (يوميا)</span></li>
              <li>نادى المـــــــناظرات:<span> (مرة شهريا)</span></li>
              <li>نادى لايف كوتش:<span> جلسة (فردية)</span></li>
              <li>متابعة يومـــــــــــــــية</li>
              <li>تقاريــــــر شهريـــــــــــة</li>
            </ul>
            <button @click="addToCart(prices[7]?.packageId)">شراء</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Adults Packages (Only 6 Months) -->
    <div class="packages-cards" v-if="selectedFilter === 'adults'">
      <div class="package-card">
        <div class="card-header">
          <h3>المعايشة بلس<span> (6 اشهر)</span></h3>
          <p class="badge"><span>الأفضل <br><bold>قيمة</bold></span></p>
        </div>
        <div class="card-body">
          <p>“المعيشة بلس” هي باقتك المثالية، تقدم أقصى قيمة مقابل السعر بميزات فريدة وخدمة شاملة تضمن راحتك.</p>
          <h2>{{ prices[10]?.price }}</h2>
          
          <h6>واستمتع بالاتي:</h6>
          <ul>
            <li>حصص أســـــــاسية:<span> 48 حصة</span></li>
            <li>نادى القـــــــــــــــــراءة:<span> 24 حصة (مرة أسبوعيا)</span></li>
            <li>نادى الســــــــــــــينما:<span> 48 حصة</span></li>
            <li>نادى المــــــــــــحادثة:<span> (يوميا)</span></li>
            <li>نادى المـــــــناظرات:<span> (مرة شهريا)</span></li>
            <li>نادى المـــــــــواهب:<span> (مرة أسبوعيا)</span></li>
            <li>نادى الرســـــــــــــــــــم:<span> (مرة أسبوعيا)</span></li>
            <li>نادى التربـــــــــــــــوي:<span> 2 محاضرات (لولي الامر)</span></li>
            <li>نادى الصوتــــــــــيات:<span> (بطلب من المدرس)</span></li>
            <li>نادى الكتــــــــــــــــــــابة:<span> (مرة أسبوعيا)</span></li>
            <li>نادى لايف كوتش:<span> 2 جلسات (فردية)</span></li>
            <li>متابعة يومـــــــــــــــية</li>
            <li>تقاريــــــر شهريـــــــــــة</li>
          </ul>
          <button @click="addToCart(prices[10]?.packageId)">شراء</button>
        </div>
      </div>

      <div class="package-card">
        <div class="card-header">
          <h3>المعايشة<span> (6 اشهر)</span></h3>
          <p class="badge"><span>الأفضل <br><bold>مبيعاً</bold></span></p>
        </div>
        <div class="card-body">
          <p>باقة “المعيشة” توفر العديد من الميزات وهي الخيار المعتاد لعملائنا، تضمن الراحة والخدمات المتميزة بكفاءة.</p>
          <h2>{{ prices[9]?.price }}</h2>
          
          <h6>واستمتع بالاتي:</h6>
          <ul>
            <li>حصص أســـــــاسية:<span> 16 حصة</span></li>
            <li>نادى القـــــــــــــــــراءة:<span> 8 حصة (مرة أسبوعيا)</span></li>
            <li>نادى الســــــــــــــينما:<span> 8 حصة</span></li>
            <li>نادى المــــــــــــحادثة:<span> (يوميا)</span></li>
            <li>نادى المـــــــناظرات:<span> (مرة شهريا)</span></li>
            <li>نادى لايف كوتش:<span> جلسة (فردية)</span></li>
            <li>متابعة يومـــــــــــــــية</li>
            <li>تقاريــــــر شهريـــــــــــة</li>
          </ul>
          <button @click="addToCart(prices[9]?.packageId)">شراء</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        selectedFilter: 'kids',
        selectedMenu: 'two-months',
        prices: {},
      };
    },
    methods: {
      toggleFilter(event) {
        this.selectedFilter = event.target.checked ? 'adults' : 'kids';
        if (this.selectedFilter === 'adults') {
          this.selectedMenu = 'six-months';
        } else {
          this.selectedMenu = 'two-months';
        }
      },
      selectMenu(menu) {
        this.selectedMenu = menu;
      },
      fetchPackagePrices() {
        let url = sessionStorage.getItem('userInfo') ? '/api/user/get-course-packages' : '/api/session/get-course-packages';

        // Retrieve and parse userInfo from sessionStorage
        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              // Add the token to the request headers
              headers['Authorization'] = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.get(url, { headers })
        .then(response => {
          const data = response.data;

          this.prices = {};

          if (data.success) {
            const packages = data.data;
            packages.forEach((packageData) => {
              const packageId = packageData.id; // Use package ID from the data
              const packagePrice = packageData.packagePrice;
              const currency = packageData.currency_ar;
              // Set the price in the prices object using the package ID
              this.prices[packageId] = {
                price: `${packagePrice} ${currency}`,
                packageId: packageId
              };
            });
          }
        })
        .catch(error => console.error('Error fetching package details:', error));
      },
      addToCart(packageId) {
        let url = sessionStorage.getItem('userInfo') ? '/api/user/cart' : '/api/session/cart';
        const userInfo = sessionStorage.getItem('userInfo');
        let headers = {};
        if (userInfo) {
          try {
            const parsedUserInfo = JSON.parse(userInfo);
            const token = parsedUserInfo.token;

            if (token) {
              // Add the token to the request headers
              headers['Authorization'] = `Bearer ${token}`;
              headers['Content-type'] = 'application/json';
            }
          } catch (error) {
            console.error('Error parsing userInfo from sessionStorage:', error);
          }
        }

        axios.post(url, {
          course_package_id: packageId,
          quantity: 1 // Default quantity
        }, { headers })
        .then(response => {
          // Handle successful response
          console.log('Item added to cart successfully:', response.data);
          this.$router.push({ name: 'CartAr' }).then(() => {
            // Force a full page reload after navigation
            window.location.reload();
          });
          // Optionally update the UI or show a success message
        })
        .catch(error => {
          // Handle error response
          console.error('Error adding item to cart:', error.response ? error.response.data : error.message);
          // Optionally show an error message to the user
        });
      }
      
    },
    mounted() {
      this.fetchPackagePrices();
    },
    
  };
</script>

<style src="@/assets/styles/ar/packages.css" scoped></style>
