<template>
  <div class="video-section-team-en">
    <h3>Excellence in Every Step</h3>
    <iframe 
      src="https://www.youtube.com/embed/uK94MLdxHZc" 
      frameborder="0" 
      allow="autoplay; fullscreen" 
      allowfullscreen
      class="video-frame-en">
    </iframe>
  </div>

  <div class="gallery-section-en">
      <p>The Mongi-experts</p>
      <p>Enjoy The Experience!</p>
      <div class="gallery-grid-en">
        <div class="left-column-en">
          <img src="@/assets/images/gallery/1.jpg" class="full-height-img-en">
        </div>
        <div class="middle-left-column">
          <img src="@/assets/images/gallery/2.jpg" class="top-img-en">
          <img src="@/assets/images/gallery/3.jpg" class="bottom-img-en">
        </div>
        <div class="middle-right-column-en">
          <img src="@/assets/images/gallery/4.jpg" class="top-img-en">
          <img src="@/assets/images/gallery/5.jpg" class="bottom-img-en">
        </div>
        <div class="right-column-en">
          <img src="@/assets/images/gallery/6.jpg" class="full-height-img-en">
        </div>
      </div>

      <div class="gallery-grid-mobile-en">
        <div class="left-column-en">
          <img src="@/assets/images/gallery/1.jpg" class="full-height-img-en">
        </div>
        <div class="middle-left-column-en">
          <img src="@/assets/images/gallery/2.jpg" class="top-img-en">
          <img src="@/assets/images/gallery/3.jpg" class="bottom-img-en">
        </div>
        <div class="middle-right-column-en">
          <img src="@/assets/images/gallery/4.jpg" class="top-img-en">
          <img src="@/assets/images/gallery/5.jpg" class="bottom-img-en">
        </div>
        <div class="right-column-en">
          <img src="@/assets/images/gallery/6.jpg" class="full-height-img-en">
        </div>
      </div>
  </div>
</template>

<script>
  export default {
    name: 'VideoView',
  };
</script>

<style scoped>
  .video-section-team-en { 
    display: flex;
    flex-direction: column; 
    align-items: center; 
  }
  .video-section-team-en h3 {
    text-align: center;
    font-size: 2.7rem;
    font-family: 'DIN Next LT Arabic', sans-serif;
    font-weight: 500;
    color: #165e84;
    margin-top: -1px;
  }
  .video-frame-en {
    margin-top: -30px;
    width: 80%;
    height: 500px; 
    border-radius: 25px; 
    overflow: hidden;
    margin-bottom: 20px;
  }
  .gallery-section-en {
    background-image: url('@/assets/images/bg.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    padding: 40px;
    font-family: 'DIN Next LT Arabic', sans-serif;
    text-align: center;
  }
  .gallery-section-en p:nth-child(1) {
    font-size: 38px;
    font-weight: 400;
    line-height: 1em;
    margin-bottom: 2%;
  }
  .gallery-section-en p:nth-child(2) {
    font-size: 48px;
    font-weight: 600;
    line-height: 1em;
    margin-top: -30px;
  }
  .gallery-grid-en {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
  }

  .gallery-grid-mobile-en{
    display: none;
  }

  .gallery-section-en img{
    border-radius: 25px; 
  }

  .left-column-en,
  .right-column-en {
    display: flex;
    flex-direction: column;
  }

  .middle-left-column-en,
  .middle-right-column-en {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .full-height-img-en {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  .top-img-en,
  .bottom-img-en {
    object-fit: cover;
    width: 100%;
  }

  .middle-left-column-en .top-img-en {
    height: 65%; /* Adjusted for better fit */
  }

  .middle-left-column-en .bottom-img-en {
    height: 35%;
  }

  .middle-right-column-en .top-img-en {
    height: 65%; /* Adjusted for better fit */
  }

  .middle-right-column-en .bottom-img-en {
    height: 35%;
  }

  .right-column-en .full-height-img-en {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (min-width: 320px) and (max-width: 479px) {
    .gallery-grid-en{
      display: none;
    }
    .gallery-grid-mobile-en {
      display: flex;
      flex-direction: column;
      grid-gap: 20px;
    }

    .gallery-section-en{
      padding-inline: 0;
    }

    .right-column-en .full-height-img-en{
      margin-top: 0%;
    }
  }
</style>