<template>
    <div class="refund-policy">
        <h1 class="title">Terms and Conditions
        </h1>



        <h2 class="sub-title">

            These terms and conditions apply to services provided by Monglish Academy UK Limited, of 71-75 SHELTON
            STREET, COVENT GARDEN LONDON WC2H 9JQ, UK. Company Registration Number: 14083316.

            (“Monglish Academy UK Limited” or “we” or “us”). These terms and conditions apply to the sale of Online
            Courses on the website https://monglish.co.uk. Please read these terms and conditions carefully before
            purchasing an Online Course.

            Website Disclaimer

            For purchases via our website, by ticking the “I have read and agree to the website terms and conditions”
            box you agree to the terms of this agreement which will bind you. If you do not agree to these terms and
            conditions you must cease to continue your purchase.

        </h2>


        <h2 class="list-title">1. Definitions
        </h2>
        <div class="list">
            <p>“Confidential Information” means information provided by one party to the other in written, graphic,
                recorded, machine readable or other form concerning the business, clients, suppliers, finances and other
                areas of the other party’s business or products, including, without limitation, the Course Materials,
                but does not include information in the public domain other than through the default of the party
                disclosing the information, information required to be disclosed by any court or regulatory authority,
                or any information already in the possession or control of the disclosing party.</p>
            <p>“Course Materials” means the information provided by Monglish Academy UK Limited to accompany a course
                provided as part of the Services in electronic form.</p>
            <p>“Fees” means the fees paid by you to Monglish Academy UK Limited for the Services.</p>
            <p>“Intellectual Property Rights” means copyright, rights in or relating to databases, patent rights,
                performers’ rights, designs and registered designs, trademarks, rights in or relating to Confidential
                Information and other intellectual property rights (registered or unregistered) throughout the world.
            </p>
            <p>“Online Course” means the delivery by us of an online course pursuant to which you learn course materials
                remotely.</p>
            <p>“Services” means the provision of the Online Course Course and the Course Materials together with such
                other services as agreed from time to time and purchased by you through the Website or by telephone or
                by WhatsApp or by communication through Social Media.</p>
            <p>“Website” means https://monglish.co.uk/</p>
            <p>“You” means the individual or organisation purchasing the Services.</p>
        </div>
        <h2 class="list-title">2. The Services
        </h2>
        <div class="list">
            <p>2.1. A description of the Services together with the dates on which the Services will begin are available
                on our Website. We will provide the Services with reasonable care and skill in accordance with the
                description set out on the Website.</p>
            <p>2.2. We reserve the right to vary or withdraw any of the Services described on the Website without
                notice.</p>
            <p>2.3 We expect you to confirm that the Services you are purchasing will meet your needs. We do not make
                any guarantee to you that you will obtain a particular result, professional qualification or employment
                opportunity from your purchase and completion of any of the Services.</p>
        </div>
        <h2 class="list-title">3. Ordering Services & Delivery Policies
        </h2>
        <div class="list">
            <p>Purchasing Services via the Website</p>
            <p>3.1. In order to purchase our courses on-line you must register for an account with us via the Website.
                If you already have an account with us you can log into your account using your user name and password.
            </p>
            <p>3.2. You may purchase by other means of direct communication by telephone or by WhatsApp or by
                communication through Social Media, and after completing payment via the direct payment link provided.
            </p>
            <p>3.3. When you place an order for a Service via the Website, you are offering to purchase the Services on
                these terms and conditions. Monglish Academy UK Limited reserves the right to cancel or decline your
                order or any part of your order at any time until it has been confirmed in accordance with clause 3.5
                below.</p>
            <p>3.4. Following receipt by us of your order for Services via the Website we will contact you by email or
                phone or WhatsApp, confirming receipt of your order, and delivering the service by means of links to
                enter Zoom lessons, and a class DOJO account to access the materials and resources. Delivery is 100%
                online as the service of online courses are 100% online ESL classes. Delivery is initiated after 24-48
                or receiving the payment confirmation, or at a later date if the client uses the deferral option (pay
                now, start later option).</p>
            <p>3.5. A legally binding agreement between us and you shall come into existence when we have :</p>
            <p>(a) accepted your offer to purchase Services from us by confirming the purchase; and</p>
            <p>(b) received payment of the relevant Fees from you in accordance with clause 5 below.</p>
            <p>3.6. Where your order consists of multiple Online Courses, each individual course will be treated by us
                as a separate offer to purchase. Acceptance of your offer to buy one or more courses will not be
                acceptance by us of your offer to purchase any other courses which make up your order.</p>
        </div>
        <h2 class="list-title">4. Cancellation and Refund Policy
        </h2>
        <div class="list">
            <p>4.1. Subject to clause 4.2 below, where we have accepted / confirmed the Services being purchased by you
                and formed a legally binding agreement with you in accordance with clause 3.5 above, then you are
                permitted, according to our published Refund and deferral policies, accessible via this link, starting
                on the day after the date we have concluded our agreement in accordance with clause 3.4, to cancel your
                purchase of the Services.</p>
            <p>4.2. If you have purchased an Online Course and have already accessed, all or part of the Online Course
                and/or started to use that Online Course then you shall have no right to cancel your order.</p>
            <p>4.3. Notwithstanding clause 4.1 there is no other right to cancel or vary your purchase of Services and
                any other cancellation and / or variation of course dates will be at the entire discretion of Monglish
                Academy UK Limited.</p>
        </div>
        <h2 class="list-title">
            5. Fees

        </h2>
        <div class="list">
            <p>5.1. The Fees for the Services shall be as set out on the Website or as told to you over the telephone at
                the time you placed an order for them.</p>
            <p>5.2. Fees for the Service selected by you on the Website shall be debited from your credit / debit card
                via Stripe UK at the time of purchase. Fees must be paid in full prior to access of any Online Course.
            </p>
            <p>5.3. You shall be responsible for all costs you incur in connection with your participation on any Online
                Course.</p>
        </div>
        <h2 class="list-title">
            6. Liability

        </h2>
        <div class="list">
            <p>6.1. No part of the provision of the Services shall be deemed to be, nor is it intended to be, nor should
                it be taken to be, the provision of investment advice.</p>
            <p>6.2. Although Monglish Academy UK Limited aims to provide the Services to the highest standards of the
                industry, neither it, nor its course writers accept any liability for (i) any inaccuracy or misleading
                information provided in the programmes or Course Materials and any reliance by the Client on any such
                information, (ii) any loss or corruption of data, (iii) any loss of profit, revenue or goodwill, or (iv)
                any indirect, special or consequential loss arising from any breach of the terms of this Agreement.</p>
            <p>6.3. Except to the extent that they are expressly set out in these terms and conditions, no conditions,
                warranties or other terms shall apply to the Services. Subject to clause 6.5 no implied conditions,
                warranties or other terms apply (including any implied terms as to satisfactory quality, fitness for
                purpose or conformance with description).</p>
            <p>6.4. Subject to clause 6.5 below, Monglish Academy UK Limited’s total liability arising from or in
                connection with these terms and conditions and in relation to anything which we may have done or not
                done in connection with these terms and conditions and the delivery of the Service (and whether the
                liability arises because of breach of contract, negligence or for any other reason) shall be limited to
                the Fees received by us in connection with the relevant Online Course in relation to which a dispute has
                arisen.</p>
            <p>6.5. Nothing in this Agreement shall exclude or limit Monglish Academy UK Limited’s liability for (i)
                death or personal injury caused by negligence, (ii) fraudulent misrepresentation or (iii) any other
                matter which under English law may not be limited or excluded.</p>
            <p>6.6. No claim may be brought more than six months after the last date on which the Services concerned
                have finished or ceased to be provided by us.</p>
        </div>

        <h2 class="list-title">
            7. Intellectual Property
        </h2>
        <div class="list">
            <p>7.1. All Intellectual Property Rights in the Course Materials and Online Courses are, and remain, the
                intellectual property of Monglish Academy UK Limited, whether adapted, written for or customised for the
                Client or not.</p>
            <p>7.2. You are not authorised to:-</p>
            <p>(i) Copy, modify, reproduce, re-publish, sub-licence, sell, upload, broadcast, post, transmit or
                distribute any of the Course Materials without prior written permission;</p>
            <p>(ii) Record on video or audio tape, relay by videophone or other means the Online Course given</p>
            <p>(iii) Use the Course Materials in the provision of any other course or training whether given by us or
                any third party trainer;</p>
            <p>(iv) Remove any copyright or other notice of Monglish Academy UK Limited on the Course Materials;</p>
            <p>(v) Modify, adapt, merge, translate, disassemble, decompile, reverse engineer (save to the extent
                permitted by law) any software forming part of the Online Courses.</p>
            <p>Breach by you of this clause 7.2 shall allow us to immediately terminate these terms and conditions with
                you and cease to provide you with any Services, including but not limited to access to the Online
                Courses.</p>
            <p>7.3. In consideration of the Fees paid by you, we grant to you a limited, non-transferable, non-exclusive
                licence to use the Course Materials in respect of the Online Course for the sole purpose of completing
                the Online Course within the specified course access period.</p>
        </div>
        <h2 class="list-title">
            8. Confidentiality
        </h2>
        <div class="list">
            <p>8.1. Each party shall keep the other party’s Confidential Information strictly confidential and not use
                it otherwise than for the purposes of these terms and conditions, and shall return it on demand and not
                retain copies of it.</p>
            <p>8.2. Either party may disclose Confidential Information to its legal and other advisors for the purposes
                of obtaining advice from them.</p>
            <p>8.3. This clause shall continue notwithstanding termination of these terms and conditions.</p>
        </div>
        <h2 class="list-title">
            9. Termination
        </h2>

        <div class="list">
            <p>9.1. We shall be entitled to terminate these terms and conditions and cease to provide you with any
                Services with immediate effect in the event that you: fail to pay when due your Fees;</p>
            <p>9.2. On termination clause 6 (liability), 7 (intellectual property rights), 8 (confidentiality) and 10
                (restrictions) shall continue notwithstanding such termination.</p>
        </div>
        <h2 class="list-title">
            10. Assignment
        </h2>
        <div class="list">
            <p>Any Services provided by us under these terms and conditions are personal to you and cannot be
                transferred or assigned to any other person.</p>
            <p>We shall be entitled to assign these terms and conditions to any other company without prior notice to
                you.</p>
        </div>
        <h2 class="list-title">
            11. Entire Agreement
        </h2>
        <div class="list">
            <p>These terms and conditions, together with the Website Disclaimer and Course Specific Terms and Conditions
                are the entire agreement between the parties and supersede any prior agreements and arrangements,
                whether written or oral. You confirm that you have not relied on any representations in entering into
                these and any other terms and conditions with us. Nothing in this clause or terms and conditions shall
                limit liability for any fraudulent misrepresentation.</p>
        </div>
        <h2 class="list-title">
            12. Force Majeure
        </h2>
        <div class="list">
            <p>Monglish Academy UK Limited shall not be liable to you for any breach of its obligations or termination
                under these terms and conditions arising from causes beyond its reasonable control, including, but not
                limited to, fires, floods, earthquakes, volcanoes and other Acts of God, terrorism, strikes, delay
                caused by transport disputes, Government edict or regulation.</p>
        </div>
        <h2 class="list-title">
            13. Assignment
        </h2>
        <div class="list">
            <p>We may assign, transfer, sub-contract any of our rights or obligations to any third party at our
                discretion.</p>
        </div>
        <h2 class="list-title">
            14. Data Protection

        </h2>
        <div class="list">
            <p>14.1 The nature of the Services provided by us means that we will obtain, use and disclose (together
                “Use”) certain information about you (“Data”). This statement sets out the principles governing our Use
                of Data. By purchasing the Services you agree to this Use.</p>
            <p>14.2 When you register with us you will need to provide certain Data such as your contact details and
                demographic information. We will store this Data and may use it to contact you, provide you with details
                of the Services you have purchased and otherwise as required during the normal provision of the course.
            </p>
            <p>14.3 We may also use the above Data, and similar Data you provide us in response to surveys, to aggregate
                user profiles and provide you with communications. We will not pass any personal data onto anyone
                outside of Monglish Academy UK Limited, 14.3 To enable us to monitor and improve our Services, we gather
                certain aggregated information about you, including details of your operating system, browser version,
                domain name and IP address, the URL you came from and go to and the parts of the Website you visit.</p>
            <p>14.4. We use information such as your User ID, session identifiers and password to enable us to identify
                whether you are using our services, assist with the provision of services and to ensure that you have
                access to relevant products. We will only read cookies from your cookie file placed there through your
                web browser’s interaction with the Website.</p>
            <p>14.5. Our products may link to third party websites and we are not responsible for their data policies or
                procedures or their content.</p>
            <p>14.6. Monglish Academy UK Limited endeavour to take all reasonable steps to protect your personal Data
                including the use of encryption technology, but cannot guarantee the security of any Data you disclose.
                You accept the inherent security implications of being and transacting on-line over the internet and
                will not hold us responsible for any breach of security.</p>
            <p>14.7. Monglish Academy UK Limited may supplement the information that you provide with information we
                receive from third parties, such as exam registration bodies or your employer.&nbsp;</p>
            <p>14.8. View our privacy policy at Privacy Policy</p>
        </div>
        <h2 class="list-title">
            15. Law and Jurisdiction
        </h2>
        <div class="list">
            <p>This Agreement is subject to English law and the parties submit to the exclusive jurisdiction of the
                English courts in connection with any dispute hereunder.</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TermsConditions'
    
};
</script>
<style scoped>
.refund-policy {
    direction: ltr;
    width: 80%;
    margin: auto;
    padding: 80px !important;
    border-radius: 20px;
    background: white;
    box-shadow: 0 4 25 0 rgba(0, 0, 0, 0.2) !important;
    margin-top: -130px;
    margin-bottom: 100px;
}

.title {
    color: #165e84;
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-size: 40px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.5em;
    margin-bottom: 10px;
}

.sub-title {
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 25px;
}

.list {
    list-style: unset;
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 1.5em;
    margin-left: 30px;
}

.list-title {
    color: #434343;
    font-family: "DIN Next LT Arabic", Sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-transform: none;
    line-height: 1.5em;
}

@media only screen and (max-width: 768px) {
    .refund-policy {
        padding: 20px !important;
    }

    .title {
        font-size: 28px;
    }
}
</style>