<template>
    <div class="security-policy">
        <h1 class="title">أمن و حماية معلومات العملاء
        </h1>
        <h2 class="sub-title">كيف تحمي أكاديمية Monglish Academy معلومات العملاء؟
        </h2>
        <p>عند القيام بالطلبات، نستخدم خادم آمن. يقوم برنامج الخادم الآمن (SSL) بتشفير جميع المعلومات التي تدخلها قبل إرسالها إلينا. علاوة على ذلك، يتم حماية جميع بيانات العملاء الحساسة التي نجمعها بعدة طبقات من التشفير والعديد من طبقات الأمان لمنع الوصول غير المصرح به. لقد حصلت Stripe (بوابة الدفع الخاصة بنا) على معيار أمان بيانات صناعة بطاقات الدفع (PCI DSS). تتوفر جميع اتفاقيات Stripe على https://stripe.com/en-gb/legal/ssa</p>
   
   <h2 class="sub-title">تفاصيل البطاقة
</h2>
<p>باستخدام موقعنا الإلكتروني، فإنك توافق على جمع واستخدام هذه المعلومات. إذا قررنا تغيير سياسة الخصوصية الخاصة بنا، سننشر هذه التغييرات حتى تكون دائمًا على علم بالمعلومات التي نجمعها، وكيف نستخدمها، وفي ظل أي ظروف نكشف عنها.
</p>
<h2 class="sub-title">معلومات الدفع
</h2>
<p>عند تقديم أو تحديث معلومات الدفع الخاصة بك في البداية، يتم ذلك مباشرةً مع Stripe كمعالج دفع. يستخدم ويعالج معالج الدفع معلومات الدفع الخاصة بك وفقًا لسياسة الخصوصية. لا يخزن Monglish academy معلومات الدفع الخاصة بك، باستثناء الرمز البريدي والبلد، والتي نحتاج إليها للفاتورة والامتثال للوائح الحكومية.

</p>
<h2 class="sub-title">معلومات دفع المستخدم النهائي
</h2>
<p>قد تتم معالجة معلومات دفع المستخدمين النهائيين لديك عبر معالج دفع Stripe وفقًا لشروط وسياسات Stripe. نحن لا نجمع أو نخزن معلومات الدفع النهائية الخاصة بك .

</p>
<h2 class="sub-title">موافقتك
</h2>
<p>باستخدام موقعنا الإلكتروني، فإنك توافق على جمع واستخدام هذه المعلومات. إذا قررنا تغيير سياسة الخصوصية الخاصة بنا، سننشر هذه التغييرات حتى تكون دائمًا على علم بالمعلومات التي نجمعها، وكيف نستخدمها، وفي ظل أي ظروف نكشف عنها.


</p>
<h2 class="sub-title">معلومات بوابة الدفع
</h2>
<p>نقدم مدفوعات الخصم والبطاقة لجميع مستخدمينا. تتم عمليات دفع البطاقات مباشرةً من خلال برنامج تشفير SSL  256  لضمان الحفاظ على سلامة بياناتك الشخصية. مرة أخرى، لا يتم تخزين أي من تفاصيل بطاقتك.
</p>

  </div>
</template>


<script>
export default {
  name: "SecurityPolicy"
};
</script>
<style scoped>
.security-policy {
  width: 80%;
  margin: auto;
  padding:  80px !important;
border-radius: 20px;
  background:white;
  box-shadow: 0 4 25 0 rgba(0, 0, 0, 0.2) !important ;
  margin-top: -130px;
  margin-bottom: 100px;  }
.title {
  color:#165e84;
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.5em;
  margin-bottom: 10px;
}
.sub-title {
  color: #434343;
  font-family: "DIN Next LT Arabic", Sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.5em;

}

@media only screen and (max-width: 768px){
  .security-policy{
      padding: 20px !important;
  }
  .title{
      font-size: 28px;
  }

}

</style>
