<template>
  <footer class="monglish-footer">
    <!-- Footer Top -->
    <div class="footer-top">
      <div class="footer_logo">
        <router-link to="/">
          <img src="@/assets/images/logo.png" alt="Monglish Logo" class="logo-footer-main" />
        </router-link>
      </div>
      
      <div class="social-links">
        <a href="#"><i class="fab fa-linkedin"></i></a>
        <a href="#"><i class="fab fa-telegram"></i></a>
        <a href="#"><i class="fab fa-snapchat"></i></a>
        <a href="#"><i class="fab fa-youtube"></i></a>
        <a href="#"><i class="fab fa-twitter"></i></a>
        <a href="#"><i class="fab fa-tiktok"></i></a>
        <a href="#"><i class="fab fa-instagram"></i></a>
        <a href="#"><i class="fab fa-facebook"></i></a>
      </div>
    </div>

    <!-- Footer Middle -->
    <hr />

    <!-- Footer Middle -->
    <div class="footer-middle mt-10">
      <div class="footer-description">
        <p>Monglish Academy is accredited in England and Wales (Number 14083316) and is legally based in London. It recognizes English language certificates from the University of Cambridge and includes trainers with TKT/CELTA/DELTA certifications.</p>
        <a class="link_to_text" href="https://www.cambridgeenglish.org/why-choose-us/global-recognition/?location=egypt&city=select%20all&roinstitutetype=&page=k-o&product=all">For more information, click here</a>
      </div>

      <div class="footer-links">
        <h3>Links</h3>
        <p><a href="#" class="link_to_text">Affiliate Marketing</a></p>
        <p><a href="/en/terms-and-conditions/security" class="link_to_text">Security and Protection</a></p>
        <p><a href="/en/terms-and-conditions/refund" class="link_to_text">Refund and Cancellation</a></p>
        <p><a href="/en/terms-and-conditions/terms" class="link_to_text">Terms and Conditions</a></p>
        <p><a href="/en/terms-and-conditions/privacy" class="link_to_text">Privacy Policy</a></p>
      </div>

      <div class="footer-social">
        <h3>Other social links</h3>
        <p><a href="#" class="link_to_text"><i class="fab fa-facebook"></i> Training and Development</a></p>
        <p><a href="#" class="link_to_text"><i class="fab fa-facebook"></i> Community</a></p>
        <p><a href="#" class="link_to_text"><i class="fab fa-instagram"></i> monglish_for_training</a></p>
        <p><a href="#" class="link_to_text"><i class="fab fa-telegram"></i> Monglish Academy group</a></p>
      </div>

      <div class="footer-contact">
        <h3>Contact us through</h3>
        <p class="phone-info">
          <i class="fas fa-phone"></i> +201000743039
        </p>
        <p>
          <a href="https://wa.me/+201000743039" target="_blank" class="link_to_text">
            <i class="fab fa-whatsapp"></i>WhatsApp
          </a>
        </p>
        <p>
          <a href="https://www.messenger.com" target="_blank" class="link_to_text">
            <i class="fab fa-facebook-messenger"></i>Messenger
          </a>
        </p>
      </div>
    </div>

    <!-- Footer Bottom -->
  <div class="footer-bottom">
    <div class="footer-subscription footer-subscription-en">
      <h3>Subscribe to receive our exclusive offers</h3>
      <div class="sub-input">
        <input type="email" placeholder="Email">
        <button>Subscribe</button>
      </div>
      <img src="@/assets/images/pay.png" class="footer-payment" alt="Visa">
    </div>

    <div class="footer-certifications">
      <h3>Quality Certifications</h3>
          <div class="certificates">
            <img src="@/assets/images/par2b.png" alt="AAA" />
            <img src="@/assets/images/par2b-1-1.png" alt="AAA" />
          </div>
          <div class="certificates">
            <img src="@/assets/images/Rectangle-6117.png" alt="ARQOA" />
            <img src="@/assets/images/accreditations/Logos.png" alt="AAA"/>
          </div>
        </div>

    <div class="footer-certifications">
      <h3>Certificates</h3>
          <div class="certificates">
            <img src="@/assets/images/Rectangle-6123.png" alt="ARQOA" />
            <img src="@/assets/images/Rectangle-29917.png" alt="AAA" />
          </div>
        </div>

    <div class="footer-certifications">
      <h3>Founding Member of</h3>
          <div class="certificates">
            <img src="@/assets/images/Rectangle-6116.png" alt="ARQOA" />
            <img src="@/assets/images/Rectangle-6122.png" alt="AAA" />
          </div>
        </div>
  </div>

    <!-- Footer Bottom -->
    <div class="footer-bottom2">
    <h2 class="center-text">Easy access to us everywhere</h2>
  <div class="footer-locations">
    <div class="location-grid">
      <div class="location">
        <h3>Egypt Alexandria Office</h3>
        <p>+201010178521</p>
        <p>alex.office@monglish.email</p>
      </div>
      <div class="location">
        <h3>UK London Office</h3>
        <p>+442038850766</p>
        <p>office@monglish.uk</p>
      </div>
      <div class="location">
        <h3>UAE Dubai Office</h3>
        <p>+97142285285</p>
        <p>dubai.office@monglish.email</p>
      </div>
      <div class="location">
        <h3>Saudi Arabia Riyadh Office</h3>
        <p>+9668008500770</p>
        <p>riyadh.office@monglish.email</p>
      </div>
      <div class="location">
        <h3>USA New York Office</h3>
        <p>+19178190745</p>
        <p>office@monglish.uk</p>
      </div>
    </div>
  </div>
</div>
  </footer>
  <div class="footer-box">
    © 2019-2024 Monglish Academy, All rights reserved. "Monglish" and "Living Curriculum" are trademarks of our globally registered and copyrighted methodology. For more details, visit the registration link.
</div>
</template>

<script>
export default {
  name: 'MonglishFooter'
}
</script>
<style scoped>
.monglish-footer {
  background-color: #165e84;
  padding-block: 20px;
  padding-inline: 5%;
  font-family: 'DIN Next LT Arabic-n', sans-serif;
  direction: ltr;
  color: #FFFFFF80;
}
.center-text{
  text-align: center;
  color: white;
}
</style>
<style src="@/assets/styles/MonglishFooter.css" scoped></style>