<template>
  <div class="home-sec7">
    <h1>EVER GROWING SUCCESS</h1>
    <div class="video-slider">
      <button class="slider-nav left" @click="slide('left')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </button>
      <div class="video-wrapper" ref="videoWrapper">
        <iframe v-for="(video, index) in displayedVideos" :key="index" :src="video.src" frameborder="0" allow="autoplay; fullscreen" allowfullscreen class="video-frame-home">
        </iframe>
      </div>
      <button class="slider-nav right" @click="slide('right')">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>

    <div class="inline-container">
      <h2 class="font-text">EXPANDING</h2>
      <div class="text-container-n">
        <svg class="oval-svg-en" viewBox="0 0 800 100">
          <path class="oval-en" d="M325,18C228.7-8.3,118.5,8.3,78,21C22.4,38.4,4.6,54.6,5.6,77.6c1.4,32.4,52.2,54,142.6,63.7 c66.2,7.1,212.2,7.5,273.5-8.3c64.4-16.6,104.3-57.6,33.8-98.2C386.7-4.9,179.4-1.4,126.3,20.7"></path>
        </svg>
        <span class="text">SINCE 2019!</span>
      </div>
    </div>

    <p class="text-white">At Monglish we’re on the path to become the best digital academy, one that provides a professional experience in teaching English, to those learners aspiring to enhance their linguistic potential. <br>Our desire to expand globally is being realized through innovating teaching methods, based on international standards, and applying them by a team comprised of native English speakers and Arabs.</p>
    <router-link to="/en/vision-mission" class="mt-5">
      <MainButton>Discover the vision and values</MainButton>
    </router-link>
  </div>
</template>

<script>
import MainButton from '../Elements/MainButton.vue';

export default {
  components: {
    MainButton
  },
  data() {
    return {
      currentIndex: 0,
      videos: [
        {
          src: 'https://www.youtube.com/embed/mUYPiG5Npn4',
          loaded: false
        },
        {
          src: 'https://www.youtube.com/embed/lsoYaL9Y0S0',
          loaded: false
        }
      ],
      isTransitioning: false
    };
  },
  computed: {
    displayedVideos() {
      const totalVideos = this.videos.length;
      return [
        this.videos[(this.currentIndex - 1 + totalVideos) % totalVideos],
        this.videos[this.currentIndex],
        this.videos[(this.currentIndex + 1) % totalVideos]
      ];
    }
  },
  methods: {
    slide(direction) {
      if (this.isTransitioning) return;
      this.isTransitioning = true;

      const wrapper = this.$refs.videoWrapper;
      const slideWidth = wrapper.clientWidth;
      const totalVideos = this.videos.length;

      if (direction === 'right') {
        this.currentIndex = (this.currentIndex + 1) % totalVideos;
        wrapper.style.transform = `translateX(-${slideWidth}px)`;
      } else {
        this.currentIndex = (this.currentIndex - 1 + totalVideos) % totalVideos;
        wrapper.style.transform = `translateX(${slideWidth}px)`;
      }

      setTimeout(() => {
        wrapper.style.transition = 'none';
        wrapper.style.transform = 'translateX(0)';
        setTimeout(() => {
          wrapper.style.transition = 'transform 0.5s ease-in-out';
          this.isTransitioning = false;
        }, 50);
      }, 500);
    }
  },
  mounted() {
    this.$nextTick(() => {
      const wrapper = this.$refs.videoWrapper;
      wrapper.style.width = '100%';
      wrapper.style.display = 'flex';
      wrapper.style.transition = 'transform 0.5s ease-in-out';
    });
  }
}
</script>

<style src="@/assets/styles/ar/Video5.css" scoped></style>
<style src="@/assets/styles/ar/VideoSliderOne.css" scoped></style>
