<template>
  <div class="faq-section-n">
    <h4>مشاكل الطلبة</h4>
    <div class="faq-items-n">
      <div class="faq-items-sub">
        <div class="faq-item-min" v-for="(item, index) in faqItems_1" :key="index">
          <button @click="toggleAnswer(index)" :class="{ active: activeIndex === index }">
            {{ item.question }}
            <span :class="{ activeSpan: activeIndex === index }">
            <span v-if="activeIndex === index">-</span>
            <span v-else>+</span>
          </span>
          </button>
          <transition name="fade">
            <div v-show="activeIndex === index" :class="['answer', { 'answer-active': activeIndex === index }]">
              {{ item.answer }}
            </div>
          </transition>
        </div>
      </div>
      <div class="faq-items-sub">
        <div class="faq-item-min" v-for="(item, index) in faqItems_2" :key="index + 3">
          <button @click="toggleAnswer(index + 6)" :class="{ active: activeIndex === index + 6 }">
            {{ item.question }}
            <span :class="{ activeSpan: activeIndex === index + 6 }">
            <span v-if="activeIndex === index + 6">-</span>
            <span v-else>+</span>
          </span>
          </button>
          <transition name="fade">
            <div v-show="activeIndex === index + 6" :class="['answer', { 'answer-active': activeIndex === index + 6 }]">
              {{ item.answer }}
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ViewThreePartOne',
  data() {
    return {
      activeIndex: null,
      faqItems_1: [
        {
          question: 'الخجل وعدم القدرة على التفاعل مع الآخرين',
          answer: 'تعزز الحصص ثقة الطالب بنفسه والقدرة على التفاعل مع الآخرين من جنسيات وأعمار مختلفة.'
        },
        {
          question: 'مناهج وكتب تعليمية مملة',
          answer: 'نقدم منهجًا مبتكرًا يُلهمهم ويمنحهم الفهم العميق دون الحاجة للكثير من الكتب.'
        },
        {
          question: 'كيف يمكنني الاشتراك ؟',
          answer: 'الخطوة الأولى التواصل مع فريق مونجلش لعمل مقابلة تحديد المستوى. الخطوة الثانية دفع الرسوم الدراسية واختيار المواعيد. الخطوة الثالثة بدء الدراسة بعد 7-14 يوم عمل.'
        },
        {
          question: 'لغتي الإنجليزية ضعيفة جدا, هل يمكنني استيعاب المعلم؟',
          answer: 'ستتمكن من فهم المعلم بوضوح وسيتم إلحاقك بالمستوى المناسب لك تماما.'
        },
        {
          question: 'لدي شكوى بخصوص الدراسة ؟',
          answer: 'تحرص أكاديمية مونجلش الدولية على تحقيق الرضاء الكامل للمتدربين أثناء فترة الدراسة: لذا نبادر باستطلاعات الرأي الأسبوعية والشهرية لتقديم الدعم الفوري في حالة وجود شكوى من خلال فريق خدمة عملاء متميز على مدار الساعة. '
        },
        {
          question: 'عدم القدرة على التفاعل مع الآخرين',
          answer: 'تساعد المتدرب على اكتساب الثقة بالنفس والمواجهة والتحدث مع الآخرين بطلاقة عبر نادي المناظرات مع مختلف الفصول فى أكاديمية مونجلش'
        }
      ],
      faqItems_2: [
        {
          question: 'إرهاق من كثرة الواجبات الدراسية',
          answer: 'نستخدم أساليب تعليم ذكية وتتنوع الواجبات التي يمكن للطالب أن يؤديها بنفسه دون مساعده الوالدين مثل (بنك أسئلة إلكترونية – وجبات صوتية مسموعة ومقروءة – تسجيلات صوتية أو فيديو وأساليب أخرى) يتم مراجعه الواجبات عبر تطبيق مونجلش الإلكتروني.'
        },
        {
          question: 'لا أحبذ الدراسة عن بعد',
          answer: 'التعليم عن بعد في مونجلش يجمع بين المرونة والراحة، مع استخدام التقنيات الحديثة لتعزيز التفاعل وتوفير تجربة تعليمية فعالة وشاملة، مما يوفر الوقت والتكاليف مع ضمان جودة التعلم.'
        },
        {
          question: 'أريد حصصاً فردية',
          answer: 'تقدم مونجلش حصصا فردية أسبوعية لتعلم مهارة القراءة مع معلم محترف. مع الأخذ في الاعتبار أن اللغة الإنجليزية لغة تواصل ويتم تعلمها من خلال مجموعات حيث تتميز بعدد محدود (من 6 إلى 8 أفراد).'
        },
        {
          question: 'هل يمكنني استرداد رسوم الاشتراك ؟',
          answer: 'يحق للمتدرب استرداد الرسوم الدراسية بالكامل قبل بدء الدراسة ب 48 ساعة.'
        },
        {
          question: 'القلق من ضعف كفاءة المعلمين',
          answer: 'تحرص أكاديمية مونجلش على اختيار المعلمين بعناية فائقة وتضم نخبة من أكفأ المعلمين والمعتمدين من جامعات دولية مرموقة مثل Cambridge  Oxford.'
        },
      ]
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>

<style src="@/assets/styles/ar/ViewThree.css" scoped></style>
<style scoped>

</style>