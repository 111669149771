<template>
  <BlogHead :title="title" :img="img" :date="date"/>
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
            <div v-for="post in blogPosts" :key="post.id" class="blog-post">
              <h3 class="blog-post-title" v-html="post.title"></h3>
              <div class="blog-post-body" v-html="post.content"></div>
              <div v-if="post.img">
                <img :src="post.img" alt='blogone' class='blog-post-img'/>
              </div>
            </div>
        </div>
      </div>
      <div class="cards-column">
        <BlogCard
          v-for="blog in blogCards"
          :key="blog.id"
          :post="blog"
        />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard
        v-for="blog in blogCards"
        :key="blog.id"
        :post="blog"
      />
    </div>
  </div>
</template>
  
  <script>
    import BlogHead from '@/components/ar/BlogHead.vue';
    import BlogCard from '@/components/en/BlogCard.vue';

  export default {
    name: "BlogThree",
    components: {
      BlogHead,
      BlogCard
    },
    data() {
      return {
        title:"Seasons of The Year",
        date: "02/28/2024",
        img: require('@/assets/images/blogs/blog3.png'),
        blogPosts: [
          {
            id: 1,
            title: "",
            content: "<p>Hello, adventurous language learners! Are you ready for a journey around the world? No, we won’t be boarding a plane or hopping on a ship. It’s a very special journey—a journey of exploration through the four seasons in English!</p><p>Together, we’ll learn the names of the seasons in English and discover the beauty of each season in an entertaining and enjoyable way! We’ll also learn how to describe the weather in each season, so get ready for bags filled with love and enjoyment!</p>",
          },
          {
            id: 2,
            title: "1. Spring Smiles: Spring is here!",
            img: require('@/assets/images/blogs/blog3-1.jpg'),
            content: "<p>Spring is the season of new beginnings! The warm sun shines, and colorful flowers sparkle. Gentle breezes blow, and birds sing happily. Everything around us seems full of life, and we wear light clothes to enjoy the beautiful weather. It’s as if nature is saying hello to life anew!</p><h4>Useful English words about spring:</h4><p>Spring, Flower, Butterfly, Bird, Sunny, Rain, Windy, Warm, Cool</p><p><strong>Activity: </strong>Head out to the garden with your family and observe the beautiful changes happening in spring! Look at different types of flowers and notice how lovely they are!</p>",
          },
          {
          id: 3,
          title: "2. Hot Summer: Summer fun!",
          content: "<p>Summer is the season of play! Summer comes with hot sun rays and a sparkling sea. We go swimming, build sandcastles, and enjoy delicious ice cream. At night, we sit under the twinkling stars and listen to stories and adventures of friends and family, spending enjoyable time together.</p><h4>Useful English words about summer:</h4><p>Summer, Beach, Sun, Hot, Ice cream, Vacation</p><p><strong>Activity: </strong>Create a beautiful painting about summer! Use bright colors to express the warmth of the sun, the coolness of the sea, and the fun of summer games.</p>",
          },
          {
            id: 4,
            title: "3. Dancing Autumn: Autumn leaves falling!",
            img: require('@/assets/images/blogs/blog3-2.jpg'),
            content: "<p>Autumn is the season of changing leaves! Trees become colored with golden, orange, and brown leaves. We walk on falling leaves and enjoy their wonderful colors and delicious fruits that ripen in this season. The weather is pleasant, and the days are shorter, but they are full of magic and vitality.</p><h4>Useful English words about autumn:</h4><p>Autumn, Leaf, Fall, Red, Orange, Yellow, Windy, Crisp</p><p><strong>Activity: </strong>Gather colorful leaves and create beautiful artistic formations! Use your imagination to turn them into animals, flowers, or anything you like!</p>",
          },
          {
            id: 5,
            title: "4. White Winter: Winter wonderland!",

            content: "<p>Winter is the season of snow! The land becomes white as if covered with a sea of cotton. We play in the snow, build snowmen, and read books by the warm fireplace. We wear warm clothes, drink hot chocolate, and enjoy the cozy winter atmosphere.</p><h4>Useful English words about winter:</h4><p>Winter, Snow, Cold, Snowman, Hot chocolate, Mittens</p><p><strong>Activity: </strong>Bake a Christmas tree-shaped cake or a snowman! Enjoy its delicious taste and share it with friends and family.</p><p>Our exploratory journey has come to an end! We learned a lot of new words and discovered the four seasons in English in a fun way! Remember that learning English can be very enjoyable and beneficial. Keep practicing the language and learning new information every day!</p><p><strong>I hope you enjoyed this little journey through the seasons in English! Feel free to share this article with your friends. Until next time!</strong></p>",
          },

        ],
        blogCards: [
        {
            id: 1,
            title: 'Learning English in Early Ages',
            image: require('@/assets/images/blogs/blogone.jpg'),
            link: '/en/blog/learning-english-in-early-ages',
          },
          {
            id: 2,
            title:"Celebrating Children’s Language Progress.",
            image: require('@/assets/images/blogs/blog-2.png'),
            link: '/en/blog/celebrating-childrens-language-progress',
          },
          {
          id: 3,
          title: 'Seasons of The Year',
          image: require('@/assets/images/blogs/blog3.png'),
          link: '/en/blog/seasons-of-the-year',
        },
        {
          id: 4,
          title: 'Parents and Children Healthy Relationships',
          image: require('@/assets/images/blogs/blog4.png'),
          link: '/en/blog/parents-and-children-healthy-relationships',
        },
          {
            id: 5,
            title: 'Activities For Positive Behavior',
            image: require('@/assets/images/blogs/blog5.png'),
            link: '/en/blog/activities-for-positive-behavior',
          },
          {
            id: 6,
            title: 'Benefits Of Bilingual For Children',
            image: require('@/assets/images/blogs/blog6.jpg'),
            link: '/en/blog/benefits-of-bilingual-for-children',
          },
        ],
      };
    },

  };
  </script>
  
<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; 
  padding: 30px; 
}
.main-content {
  display: flex;
  flex-direction: row; 
  gap: 50px; 
  direction: ltr;
  padding: 5% 15% 0 15%;
}
.content-column {
  padding: 30px;
  border-radius: 12px; 
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.blog-cards-row {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%; 
  box-sizing: border-box; 
}
h1 {
  color: #808080; 
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px; 
  padding-left: 16%;
}
.blog-view {
  margin-left: 2.5%;
  text-align: left;
  color: #535353; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-view h2{
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
 }
.blog-view p {
  margin-bottom: 18px; 
  font-size: 18px;
}
.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}
.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}
.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px; 
  margin: 20px 0;
}
.strong-paragraph {
  font-weight: 700;
  text-align: left;
  color: #1a1a1a;
  font-size: 20px; 
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
  .content-column{
    padding: 20px; 
  }
  .cards-column {
   display: none;
  }
  h1 {
    font-size: 28px;
  }
  .blog-view h3 {
    font-size: 24px;
  }
  .blog-view h4 {
    font-size: 20px;
  }
}
</style>
