<template>
  <section class="content-slider-container">
    <div class="immerse-row">
      <h3>خبراء تعليم <br><span>اللغة الانجليزية</span></h3>
      <p>
         خلف كل نجاح يقف فريقنا الاكاديمي المعزز بأعلى معايير الجودة والإحترافية. أكثر من 300 شريك من نخبه المعلمين المتخصصين فى توفير تجربه تعليميه لا مثيل لها فى مجال تعليم اللغة الإنجليزية.  نحن هنا لنصنع معًا مستقبلاً مشرقًا بالمعرفة.
      </p>
    </div>
    
    <div class="carousel-container">
      <button @click="prevSlide" class="carousel-nav left">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
        </svg>
      </button>
      <Carousel
        ref="carousel"
        :itemsToShow="itemsToShow"
        :itemsToScroll="itemsToScroll"
        :wrapAround="true"
        :mouseDrag="true"
        :autoplay="3000"
        :transition="500"
        :gap="20"
        @slideEnd="handleSlideEnd"
        class="image-gallery"
      >
        <Slide v-for="(image, index) in images" :key="index">
          <div class="image-item card">
            <img :src="image.src" :alt="image.name" class="image-thumbnail" />
            <div class="card-content">
           <div class="icon-text-container" >
  <h4>
    <svg fill="#fff" height="55px" width="55px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 85" xml:space="preserve" stroke="#fff">
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" style="stop-color:#F47D21;stop-opacity:1" />
          <stop offset="100%" style="stop-color:#FF9D52;stop-opacity:1" />
        </linearGradient>
      </defs>
      <circle cx="32" cy="32" r="30" stroke="#fff" stroke-width="2" fill="url(#grad1)" />
      <g id="Play">
        <path d="M46.0136986,31.1054993L25.1973,20.6973c-0.3096008-0.1532993-0.6777992-0.1387005-0.9727001,0.0438995 C23.9297009,20.9237995,23.75,21.2451,23.75,21.5918007v20.8163986c0,0.3467026,0.1797009,0.6679993,0.4745998,0.8506012 C24.3848,43.3583984,24.5674,43.4081993,24.75,43.4081993c0.1532993,0,0.3057003-0.035099,0.4473-0.1054001l20.8163986-10.4081993 c0.3388023-0.1699982,0.5527-0.5157013,0.5527-0.8945999C46.5663986,31.6210995,46.3525009,31.2754002,46.0136986,31.1054993z M25.75,40.7901001v-17.580101L43.330101,32L25.75,40.7901001z"></path>
        <path d="M32,0C14.3268995,0,0,14.3268995,0,32s14.3268995,32,32,32s32-14.3269005,32-32S49.6730995,0,32,0z M32,62 C15.4579,62,2,48.542099,2,32C2,15.4580002,15.4579,2,32,2c16.5419998,0,30,13.4580002,30,30C62,48.542099,48.5419998,62,32,62z"></path>
      </g>
    </svg>
    {{ image.name }}
  </h4>
</div>
              <p>{{ image.desc }}</p>
              <p class="navigate-link" @click="navigateTo(image.link)">
                المزيد من المعلومات
                <svg fill="#fff" height="16px" width="16px" viewBox="0 0 330 330" xml:space="preserve">
                  <path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path>
                </svg>
              </p>
            </div>
             <div class="hover-svg-container">
                <svg class="hover-svg1" width="222" height="245" viewBox="0 0 222 245" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path  d="M170.617 245H0.704102V239.877H67.3402L77.7946 229.422H164.713L182.854 211.281H197.086L215.943 192.424V43.8531H221.066V194.543L170.617 245ZM184.977 216.404L166.836 234.546H79.9177L74.5866 239.877H168.49L191.959 216.404H184.974H184.977Z" fill="#e1e1e1bf"/>
                   <path d="M221.07 40.0078H215.946V31.0392H221.07V40.0078Z" fill="#e1e1e1bf"/>
                   <path d="M221.07 27.1943H215.946V0.288572H221.07V27.1943Z" fill="#e1e1e1bf"/>
                </svg>
                <svg class="hover-svg2" width="250" height="150" viewBox="0 0 250 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                   <path d="M51.3823 0H221.295V5.12337H154.659L144.204 15.5778H57.2862L39.1449 33.719H24.9133L6.05603 52.5763V201.147H0.932663V50.4568L51.3823 0ZM37.0218 28.5956L55.1631 10.4544H142.081L147.412 5.12337H53.509L30.0403 28.5956H37.0254H37.0218Z" fill="#e1e1e1bf"/>
                   <path d="M0.929364 204.992H6.05273V213.961H0.929364V204.992Z" fill="#e1e1e1bf"/>
                   <path d="M0.929364 217.806H6.05273V244.711H0.929364V217.806Z" fill="#e1e1e1bf"/>
                </svg>
            </div>
          </div>
        </Slide>
      </Carousel>
      <button @click="nextSlide" class="carousel-nav right">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 19.5L15.75 12l-7.5-7.5" />
        </svg>
      </button>
    </div>
  </section>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
      itemsToShow: 5, 
      itemsToScroll: 1,
      images: [
        {
          id: 1,
          src: require('@/assets/images/teachers/1.png'),
          name: "نائلة",
          desc : "أهلًا بكم.. أنا نائلة.. مدربة معتمدة  لتدريس اللغة الإنجليزية كلغة أجنبية من الولايات المتحدة الأمريكية...",
          link: '/ar/teachers/one',
        },
        {
          id: 2,
          src: require('@/assets/images/teachers/2.png'),
          name: "فاطمة حسن",
          desc: "مرحبًا بالجميع.. اسمي فاطمة حسن .. مدربة معتمدة في تدريس اللغة الإنجليزية كلغة أجنبية في أكاديمية...",
          link: '/ar/teachers/two',
        },
        {
          id: 3,
          src: require('@/assets/images/teachers/3.png'),
          name: "هبة خليفة",
          desc: "مرحبًأ .. اسمي هبة خليفة.. معلمة لغة إنجليزية في أكاديمية مونجلش الدولية.. أعمل كمعلمة للغة...",
          link: '/ar/teachers/three',
        },
        {
          id: 4,
          src: require('@/assets/images/teachers/4.png'),
          name: "خلود",
          desc: "مرحبًا.. أنا خلود.. مدربة لتعليم اللغة الإنجليزية في أكاديمية مونجلش الدولية.. تخرجت من كلية الآداب...",
          link: '/ar/teachers/four',
        },
        {
          id: 5,
          src: require('@/assets/images/teachers/5.png'),  
          name: "مي الطويل",
          desc: "مرحبًا.. أسمي مي الطويل.. مدربة معتمدة في تدريس اللغة الإنجليزية كلغة أجنبية.. خبرة أكثر من 10 سنوات...",
          link: '/ar/teachers/five',
        },
        {
          id: 6,
          src: require('@/assets/images/teachers/6.png'),
          name: "كايتلين",
          desc: "مرحبا اسمي كاتلين.. أنا مُعملة هنا في أكاديمية مونجلش الدولية ولدي أكثر من 10 سنوات من الخبرة...",
          link: '/ar/teachers/six',
        },
        {
          id: 7,
          src: require('@/assets/images/teachers/7.png'),
          name: "حلمي عبد العاطي",
          desc: "مرحبًا بالجميع .. أنا حلمي عبد العاطي.. معلم لغة إنجليزية في أكاديمية مونجلش الدولية.. أيضًا خريج...",
          link: '/ar/teachers/seven',
        },
        {
          id: 8,
          src: require('@/assets/images/teachers/8.png'),
          name: "منى",
          desc: "أهلاً بكم جميعًا، أنا أستاذة منى، مدرسة لغة إنجليزية في أكاديمية مونجلش الدولية. حصلت على درجة...",
          link: '/ar/teachers/eight',
        },
        {
          id: 9,
          src: require('@/assets/images/teachers/9.png'),
          name: "ولاء فايق",
          desc: "أهلًا بكم.. أنا ولاء فايق.. مدربة في  أكاديمية مونجلش الدولية لتعليم اللغة الإنجليزية.. خبرة...",
          link: '/ar/teachers/nine',
        }
      ]
    };
  },
  mounted() {
    this.updateItemsToShow();
    window.addEventListener('resize', this.updateItemsToShow);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateItemsToShow);
  },
  computed: {
    visibleImages() {
      return this.images.slice(this.currentSlide, this.currentSlide + this.itemsToShow);
    }
  },
  methods: {
    prevSlide() {
      this.$refs.carousel.prev();
    },
    nextSlide() {
      this.$refs.carousel.next();
    },
    navigateTo(link) {
      this.$router.push(link);
    },
    setCurrentSlide(index) {
      this.currentSlide = index * this.imagesPerView;
    },
    updateItemsToShow() {
      const width = window.innerWidth;
      if (width >= 1440) {
        this.itemsToShow = 5;
      } else if (width >= 786) {
        this.itemsToShow = 3;
      } else {
        this.itemsToShow = 1;
      }
    }
  }
};
</script>

<style scoped>
.content-slider-container {
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
  direction: rtl;
}
.immerse-sec2 {
  padding: 1em 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.immerse-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2em;
  margin: 1em 0;
  font-family: 'DIN Next LT Arabic', sans-serif;
}
.immerse-row h3 {
  color: #165e84;
  font-size: 2.8rem;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
}
.immerse-row h3 span{
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.immerse-row p {
  font-size: 1.375rem;
  color: gray;
  font-weight: 500;
  max-width: 70%;
  margin: 0 auto;
}
@media (min-width: 769px) {
  .immerse-row {
    flex-direction: row;
    justify-content: center;
    text-align: right;
  }
  .immerse-row h3 {
    width: 20%;
    margin: 0;
    padding-right: 50px;
  }
  .immerse-row p {
    width: 80%;
    margin: 0;
    padding-left: 25px;
  }
}
.carousel-nav:hover {
  color: #2178a6;
}
.carousel-nav:active {
  color: #fff;
}
@media (max-width: 769px) {
  .carousel-nav.left {
    left: 0;
  }
  .carousel-nav.right {
    right: 0;
  }
}
.carousel-container {
  position: relative;
}
.carousel-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
  color: #165e84;
}
.carousel-nav.left {
  left: 10px;
}
.carousel-nav.right {
  right: 10px;
}
.icon-text-container {
  display: flex;
  align-items: center; 
  direction: rtl;
}
.icon-text-container h4 {
  display: flex;
  align-items: center; 
  margin: 0;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
  text-align: right; 
  font-size: 32px;
  margin-bottom: 10px;
}
.icon-text-container svg {
  margin-left: 5px;
  margin-bottom: -15px;
}
.image-gallery {
  width: 95%;
  margin: 0 auto;
}
.image-item {
  width: 400px;
  text-align: center;
  position: relative;
  overflow: hidden;
  margin: 0 10px;
  transition: transform 0.3s ease;
}
.image-thumbnail {
  width: 100%;
  height: 500px;
  border-radius: 25px;
  object-fit: cover;
}
.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;
  direction: rtl;
}
.image-item:hover .card-content {
  opacity: 1;
  background: linear-gradient(180deg, rgba(45, 45, 45, 0) 0%, #171717 100%);
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
.card-content p {
  font-size: 16px;
  color: #fff;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
}
.navigate-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  margin-bottom: 15px;
  font-weight: bold;
  z-index: 100;
}
.navigate-link svg {
  margin-right: 8px;
  transition: transform 0.3s ease;
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center; 
}
.image-item {
  position: relative;
  overflow: hidden; 
}
.hover-svg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; 
  transition: opacity 0.3s ease;
}
.image-item:hover .hover-svg-container {
  opacity: 1; 
}
.hover-svg2 {
  position: absolute;
  left: 3%;
  right: 50%;
  top: 2%;
  bottom: 50%;
}
.hover-svg1{
position: absolute;
  right: 3%;
  top: 48%;
}
</style>