<template>
  <div class="our-team-container">
    <div class="our-team">
      <div class="padding-full">
        <div class="ourfounder-img-mobile">
          <img src="@/assets/images/founder.png" alt="founder" class="founder-team">
        </div>
        <div class="padding-h3-title">
          <h3 class="padding-text-full">خبراء أكاديمية</h3>
        </div>
        <div class="padding-h2-title">
          <h1>مونجلش الدولية</h1>
        </div>
        <div class="margin-text-p">
          <p class="margin-text-up">
            تتميز أكاديمية مونجلش الدولية بفريق عالمي متخصص يضم أكثر من 300 شريك نجاح، يقدمون تجربة تعليمية فريدة في تعليم اللغة الإنجليزية بأعلى مستويات الجودة والاحترافية، معتمدين على منهج مبتكر يحاكي تجربة العيش بين الناطقين الأصليين للغة. نحن ملتزمون بتقديم تجربة تعليمية متميزة تعزز من نجاح وتميز المتعلمين.
          </p>
        </div>
        <div class="joinus-button">
          <button>
            انضم لفريق مونجلش
            <svg aria-hidden="true" width="1em" height="1em" class="joinus-svg e-font-icon-svg e-fas-long-arrow-alt-left" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
              <path fill="#fff" d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"></path>
            </svg>
          </button>
        </div>
        <div class="ourteam-counter">
          <OurTeamCounter />
        </div>
        <div class="ourfounder-img">
          <img src="@/assets/images/founder.png" alt="founder" class="founder-team">
        </div>
      </div>
    </div>
  
    <div class="team-row">
      <h3>كوادر إدارية
        <br>
        <span>تصنع النجاح في كل خطوة</span>
      </h3>
      <p>
        العمود الفقري لنجاحنا؛ إذ يعملون بجد وتفانٍ في كل خطوة لضمان توفير تجربة تعليمية استثنائية وتحقيق الإنجازات في كل مرحلة من مراحل العملية التعليمية.
      </p>
    </div>
  
    <div class="team-managers">
      <div v-for="manager in teamManagers" :key="manager.id" class="manager-card">
        <div class="manager-info">
          <h4>{{ manager.name }}</h4>
          <p>{{ manager.title }}</p>
        </div>
        <img :src="manager.image" :alt="manager.name">
      </div>
    </div>

    <div class="team-slider">
      <h1>شركاء النجاح</h1>
      <div class="slider-container">
        <button @click="nextSlide" class="slider-button nextt">❯</button>
        <div class="slides">
          <img :src="images[currentIndex]" alt="Team Image" />
        </div>
        <button @click="prevSlide" class="slider-button prevv">❮</button>
      </div>
    </div>
  
    <VideoView />
     
    <div class="last-section-team">
       <div class="ourteam-sec6"> 
      <img src="@/assets/images/ourteam-6.jpg" alt="ourteam-6" class="ourteam-6">
    </div>
    <div class="ourteam-text"> 
      <p>تبحث عن فرصة وظيفية؟</p>
      <p>وترغب في الانضمام إلى أسرة مونجلش</p>
      <button>
        انضم لفريق مونجلش
        <svg aria-hidden="true" width="1em" height="1em" class="joinus-svg e-font-icon-svg e-fas-long-arrow-alt-left" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
          <path fill="#fff" d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z"></path>
        </svg>
      </button>
    </div>
    </div>
  </div>
</template>

<script>
import OurTeamCounter from '../../components/ar/TeamCounter.vue'; 
import VideoView from '../../components/ar/VideoView.vue'; 

export default {
  name: 'OurTeam',
  components: {
    OurTeamCounter,
    VideoView
  },
  data() {
    return {
      teamManagers: [
        {
          id: 1,
          name: 'د. محمد النجار',
          title: 'نائب رئيس مجلس الإدارة',
          image: require('@/assets/images/team/1.jpg')
        },
        {
          id: 2,
          name: 'د. حاتم حسنين',
          title: 'المدير الأكاديمي',
          image: require('@/assets/images/team/2.jpg')
        },
        {
          id: 3,
          name: 'أ. هبة حسن',
          title: 'مساعدة رئيس مجلس الإدارة للمبيعات والتسويق',
          image: require('@/assets/images/team/3.jpg')
        },
        {
          id: 4,
          name: 'م. محمد سالم',
          title: 'المدير التنفيذي للشئون التكنولوجية',
          image: require('@/assets/images/team/4.jpg')
        },
        {
          id: 5,
          name: 'ا. صفي الرحمن أباظه',
          title: 'نائب مدير التسويق',
          image: require('@/assets/images/team/5.jpg')
        },
        {
          id: 6,
          name: 'ا. مايسة الشيخ',
          title: 'مدير قسم الموارد البشرية',
          image: require('@/assets/images/team/6.jpg')
        },
        {
          id: 8,
          name: 'ا. كريم بسيوني',
          title: 'نائب مدير خدمة العملاء',
          image: require('@/assets/images/team/8.jpg')
        },
        {
          id: 12,
          name: 'د. نصر كركور',
          title: 'مساعد رئيس مجلس الإدارة مدير فرع الإسكندرية',
          image: require('@/assets/images/team/12.jpg')
        },
        {
          id: 11,
          name: 'ا. سامية أنور',
          title: 'مدير المراحل العليا',
          image: require('@/assets/images/team/11.jpg')
        },
        {
          id: 10,
          name: 'د. سها عصام',
          title: 'مدير المراحل التأسيسية',
          image: require('@/assets/images/team/10.jpg')
        },
        {
          id: 9,
          name: 'ا. وليد رسلان',
          title: 'مدير الحسابات',
          image: require('@/assets/images/team/9.jpg')
        },
      ],
      images: [
        require('@/assets/images/slider/ar/1.png'),
        require('@/assets/images/slider/ar/2.png'),
        require('@/assets/images/slider/ar/3.png'),
        require('@/assets/images/slider/ar/4.png'),
        require('@/assets/images/slider/ar/5.png'),
        require('@/assets/images/slider/ar/6.png'),
        require('@/assets/images/slider/ar/8.png'),
        require('@/assets/images/slider/ar/9.png'),
        require('@/assets/images/slider/ar/10.png'),
        require('@/assets/images/slider/ar/11.png'),
        require('@/assets/images/slider/ar/12.png'),
        require('@/assets/images/slider/ar/13.png'),
        require('@/assets/images/slider/ar/14.png'),
        require('@/assets/images/slider/ar/15.png'),
        require('@/assets/images/slider/ar/16.png')
      ],
      currentIndex: 0
    };
  },
  methods: {
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    }
  }
};
</script>

<style src="@/assets/styles/ar/OurTeam.css"></style>