<template>
  <BlogHead :title="title" :img="img" :date="date" :category="category" />
  <div class="blog">
    <div class="main-content">
      <div class="content-column">
        <div class="blog-view">
          <p>تسعد شركة Edulixa 360، الشركة الأم لأكاديمية مونجلش الدولية، بحضور الحدث البارز لغرفة التجارة السويسرية، حفل العشاء، الذي شرفه ضيوف مرموقون مثل: سعادة الدكتور إيفون باومان، سفيرة سويسرا في مصر. وسعادة الدكتور محمود عصمت، وزير قطاع الأعمال العام. وسعادة المهندس طارق الملا، وزير البترول والثروة المعدنية. والدكتور محمود محيي الدين، المدير التنفيذي لصندوق النقد الدولي.<br></p>

          <p>شكلت الأمسية نجاحاً باهراً تخللتها لحظات ممتعة وتبادل قيّم للخبرات. لقد استمتع الحضور بتجارب فريدة من نوعها وناقشوا فرص التعاون والشراكات المستقبلية بين مصر وسويسرا في مجالات التعليم والتكنولوجيا والاقتصاد.<br></p>

          <p>نحن في Edulixa 360 نفخر بكوننا جزءًا من هذا الحدث المميز ونتطلع إلى تعزيز علاقاتنا مع شركائنا الدوليين والمساهمة في تطوير قطاع التعليم والتدريب في مصر والعالم.</p>

          <p>نتوجه بالشكر إلى غرفة التجارة السويسرية على تنظيم هذا الحدث الرائع وإتاحة الفرصة لنا للتواصل مع نخبة من القادة والخبراء في مجالات متعددة.<br></p>

          <div class="image-row">
            <img src="@/assets/images/blogs/blog9-1.jpg" class="small-photo-in-row" alt="Image 1">
            <img src="@/assets/images/blogs/blog9-2.jpg" class="small-photo-in-row" alt="Image 2">
            <img src="@/assets/images/blogs/blog9-3.jpg" class="small-photo-in-row" alt="Image 3">
            <img src="@/assets/images/blogs/blog9-4.jpg" class="small-photo-in-row" alt="Image 4">
            <img src="@/assets/images/blogs/blog9-5.jpg" class="small-photo-in-row" alt="Image 5">
            <img src="@/assets/images/blogs/blog9-6.jpg" class="small-photo-in-row" alt="Image 6">
          </div>
        </div>

      </div>
      <div class="cards-column">
        <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
      </div>
    </div>
    <div class="blog-cards-row">
      <BlogCard v-for="blog in blogCards" :key="blog.id" :post="blog" />
    </div>
  </div>
</template>

<script>
import BlogHead from '@/components/ar/BlogHead.vue';
import BlogCard from '@/components/en/BlogCard.vue';

export default {
  name: "BlogFour",
  components: {
    BlogHead,
    BlogCard
  },
  data() {
    return {
      title: "الحدث البارز لغرفة التجارة السويسرية بمشاركة ايديوليكسا 360",
      date: "05/27/2024",
      img: require('@/assets/images/blogs/ar-8.jpg'),
      category: "فعاليات",
      blogCards: [
        {
          id: 2,
          title: "الإعلان عن إنطلاق مجموعة إيديولكسا 360",
          image: require('@/assets/images/blogs/ar-1.png'),
          link: '/ar/blog/edulexa-360-group',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 4,
          title: 'لقاء د. محمد شلبي برنامج بنت البلد',
          image: require('@/assets/images/blogs/ar-2.png'),
          link: '/ar/blog/dr-shalaby-on-bentelbalad-program',
          Date: '9 يونيو ,2024',
          language: 'فعاليات',
        },
        {
          id: 5,
          title: 'كيف اتعلم اللغة الانجليزية',
          image: require('@/assets/images/blogs/ar-3.jpg'),
          link: 'ar/blog/learning-english-in-early-ages/',
          Date: '29 مايو ,2024',
          language: 'ُقافة اللغة الانجليزية',
        },
        {
          id: 6,
          title: 'انجاز جديد فى مشوار دكتور النجار',
          image: require('@/assets/images/blogs/ar-4.png'),
          link: '/ar/blog/new-achievement-in-dr-najjars-career',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
        {
          id: 3,
          title: 'الاحتفال الشهرى بالموظف المثالى',
          image: require('@/assets/images/blogs/ar-5.png'),
          link: '/ar/blog/monthly-celebration-of-the-ideal-employee',
          Date: '28 مايو ,2024',
          language: 'فعاليات',
        },
      ],
    };
  },

};
</script>

<style scoped>
.blog {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; 
  padding: 30px; 
}
.main-content {
  display: flex;
  flex-direction: row; 
  gap: 50px; 
  direction: rtl;
  padding: 5% 15% 0 15%;
}
.content-column {
  padding: 30px;
  border-radius: 12px; 
}

.cards-column {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}
.blog-cards-row {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  padding: 0 15%; 
  box-sizing: border-box; 
}
h1 {
  color: #808080; 
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  text-align: right;
  margin-bottom: 20px; 
  padding-left: 16%;
}
.blog-view {
  margin-left: 2.5%;
  text-align: right;
  color: #535353; 
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  line-height: 1.8;
}
.blog-view h3 {
  font-size: 30px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 12px;
}
.blog-view h4 {
  font-size: 26px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.blog-view h2{
  font-size: 40px;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
 }
.blog-view p {
  margin-bottom: 18px; 
  font-size: 18px;
}
.blog-view ul {
  margin-left: 20px;
  list-style-type: disc;
  padding-left: 20px;
}
.blog-view ul li {
  margin-bottom: 12px;
  line-height: 1.6;
  color: #444444;
}
.blog-post-img {
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  border-radius: 12px; 
  margin: 20px 0;
}
.strong-paragraph {
  font-weight: 700;
  text-align: right;
  color: #1a1a1a;
  font-size: 20px; 
}
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
    gap: 20px;
    padding: 0;
  }
  .content-column{
    padding: 20px; 
  }
  .cards-column {
   display: none;
  }
  h1 {
    font-size: 28px;
  }
  .blog-view h3 {
    font-size: 24px;
  }
  .blog-view h4 {
    font-size: 20px;
  }
}
.image-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4%;
}
.small-photo-in-row {
    width: 22%;
    height: 200px;
    object-fit: cover; 
    margin-bottom: 4%; 
}
</style>