<template>
  <div class="study-sec1-en">
    <div class="study-sec1-content">
      <h3>الدراسة في الخارج</h3>
      <h1>تجربة تعليمية استثنائية في انتظاركم قريبًا!</h1>
      <h4>بالتعاون مع كبرى الجامعات العالمية</h4>
      <p>
        نعمل على تطوير برنامج متميز للدراسة في الخارج بالشراكة مع أبرز الجامعات العالمية في العديد من الدول، لنقدم لكم فرصة تعليمية فريدة
      </p>
    </div>
  </div>

  <ViewThree />
</template>

<script>
import ViewThree from '../../components/ar/ViewThree.vue'; 

export default {
  name: 'StudyAbroadPage',
  components: {
    ViewThree,
  },
}
</script>

<style scoped>
.study-sec1-en {
  background-image: url('@/assets/images/study_bg.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  height: 100vh;
  margin-top: -85px;
  padding: 40px;
  font-family: 'DIN Next LT Arabic';
}
.study-sec1-content {
  text-align: right;
  direction: rtl;
  max-width: 40%;
}
.study-sec1-en h3 {
  font-size: 2rem;
  padding: 8em 0 0 0;
}
.study-sec1-en h4 {
  font-size: 2.2rem;
}
.study-sec1-en h1 {
  font-size: 4.2rem;
  font-weight: 500;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}
.study-sec1-en p {
  padding-left: 10%;
  font-size: 1.3rem;
}
@media screen and (max-width: 1200px) {
  .study-sec1-en h1 {
    font-size: 3.5rem;
  }
  .study-sec1-content {
    max-width: 50%;
  }
}
@media screen and (max-width: 992px) {
  .study-sec1-en h1 {
    font-size: 3rem;
  }
  .study-sec1-en p {
    font-size: 1.2rem;
  }
  .study-sec1-content {
    max-width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .study-sec1-en h1 {
    font-size: 2.5rem;
  }
  .study-sec1-en h3, .study-sec1-en h4 {
    font-size: 1.8rem;
  }
  .study-sec1-content {
    margin-top: -5em;
  }
    .study-sec1-en{
    padding: 0;
  }
}
@media screen and (max-width: 576px) {
  .study-sec1-en h1 {
    font-size: 2rem;
  }
  .study-sec1-en h3, .study-sec1-en h4 {
    font-size: 1.6rem;
  }
  .study-sec1-content {
    max-width: 90%;
    padding: 10px;
  }
  .study-sec1-en p {
    font-size: 1.2rem;
    padding-left: 10%;
  }
}
@media screen and (max-width: 400px) {
  .study-sec1-en h1 {
    font-size: 1.8rem;
  }
  .study-sec1-en h3, .study-sec1-en h4 {
    font-size: 1.2rem;
  }
  .study-sec1-en p {
    font-size: 0.9rem;
  }
}
</style>
