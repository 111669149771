<template>
  <div class="gallery-title">
     <h3>Exhilarating journey</h3>
     <h1>Through the lens of creativity</h1>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.gallery-title {
  background-image: url('@/assets/images/bg_gallery.jpg');
  background-size: cover;
  background-position: center;
  height: 80vh;
  margin-top: -85px;
  padding-top: 140px;
  color: white;
  text-align: center;
}

.gallery-title h1 {
  font-size: 3.8em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}

.gallery-title h3 {
  margin-top: 2em;
  font-size: 2.8em;
  font-family: 'DIN Next LT Arabic';
  font-weight: 500;
}

@media (max-width: 1200px) {
  .gallery-title h1 {
    font-size: 3em;
  }
  
  .gallery-title h3 {
    font-size: 2.2em;
  }
}

@media (max-width: 768px) {
  .gallery-title {
    padding-top: 100px;
    height: 50vh;
  }

  .gallery-title h1 {
    font-size: 2.5em;
  }
  
  .gallery-title h3 {
    margin-top: 0;
    font-size: 2em;
  }
}

@media (max-width: 480px) {
  .gallery-title {
    padding-top: 80px;
    height: 50vh;
  }

  .gallery-title h1 {
    font-size: 2em;
  }

  .gallery-title h3 {
    margin-top: 0;
    font-size: 1.5em;
  }
}
</style>